<template>
    <div class="be-wrapper">
        <NavBar></NavBar>
        <SideBar></SideBar>
        <!--<div class="be-content">
        <div class="main-content container-fluid">
            <router-view v-if="loaded"/>
        </div>
    </div>-->
        <div class="app-content content">
            <div class="content-overlay"></div>
            <div class="header-navbar-shadow"></div>
            <div class="content-wrapper">
                <div class="content-body">
                    <section>
                        <router-view v-if="loaded" />
                    </section>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
/* eslint-disable */

import NavBar from "@/components/NavBar";
import SideBar from "@/components/SideBar";

export default {
  name: "home",
  components: {
    NavBar,
    SideBar
  },
  created() {
    if (this.$auth.loggedIn()) {
      this.getUser();
    } else {
      this.$store.commit("clearCurrentUser");
    }
  },
  updated() {
    const user = this.$store.state.currentUser;
    if (
        user.parent &&
        !user.parent.hasPreference &&
        user.parent.accountType === "Distributor" &&
        user.role === "Administrator"
    ) {
      this.$router.push("/set-preference");
    }
  },
  data() {
    return {
      loaded: false
    };
  },
  methods: {
    getUser() {
      this.$http
        .get("/Users/getCurrent")
        .then(function(res) {
          this.$store.commit("setCurrentUser", res.body);
          this.loaded = true;
        })
        .catch(function() {
          this.loaded = false;
        });
    }
  }
};
</script>