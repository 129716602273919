<template>
    <div class="row">
        <div class="col-lg-12">
            <div :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]">
                <div class="card-header">
                    <button class="btn btn-success btn-rounded btn-space"
                            @click="viewFilter">
                        <span class="feather icon-filter text-white"></span> Filter
                    </button>
                    <button class="btn btn-primary btn-rounded btn-space float-right"
                            @click="viewCenterOptions"
                            v-if="hasPermission('GeoFencing_Create')">
                        <span class="icon icon-left mdi mdi-plus text-white"></span> Add New Geo-Fence
                    </button>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                        <div class="row be-datatable-body">
                            <div class="col-sm-12 table-responsive">
                                <table class="
                    table table-fw-widget
                    dataTable
                    no-footer
                    table-striped table-hover-animation
                  ">
                                    <thead>
                                        <tr>
                                            <th style="width: 15%">User Name</th>
                                            <th style="width: 20%">Mid Point</th>
                                            <th style="width: 10%">Radius</th>
                                            <th style="width: 15%">Geo-fenced Area</th>
                                            <th style="width: 10%">Status</th>
                                            <th style="width: 20%">Created</th>
                                            <th style="width: 10%"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="l in GeoFences" :key="l.id">
                                            <td>
                                                <span>{{ l.user.displayName }}</span>
                                            </td>
                                            <td>
                                                {{ l.address }}<br />
                                                {{ l.latitude }},{{ l.longitude }}
                                            </td>
                                            <td>
                                                {{ l.radius }} km
                                            </td>
                                            <td>
                                                {{ formatNumber(getArea(l.radius)) }} km<sup>2</sup>
                                            </td>
                                            <td>
                                                <span :class="[
                            'badge',
                            ' badge-pill',
                            { 'badge-success': l.active },
                            { 'badge-danger': !l.active },
                          ]">
                                                    {{ l.active ? "Active" : "Inactive" }}
                                                </span>
                                            </td>
                                            <td>{{ formatDate(l.created) }}</td>
                                            <td>
                                                <button class="btn btn-outline-primary btn-sm dropdown-toggle"
                                                        type="button"
                                                        id="dropdownMenu2"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false">
                                                    Actions
                                                </button>
                                                <div class="dropdown-menu px-1"
                                                     aria-labelledby="dropdownMenu2">
                                                    <a v-if="hasPermission('GeoFencing_Update')" class="dropdown-item" href="#" @click.prevent="viewEdit(l)">
                                                        Edit
                                                    </a>
                                                    <div class="dropdown-divider"></div>
                                                    <a v-if="hasPermission('GeoFencing_Delete')" class="dropdown-item" href="#" @click.prevent="deleteGeoFence(l)" style="color:red">
                                                        Delete
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-if="!GeoFences.length">
                                            <td colspan="7">
                                                <div class="text-center" style="padding-top: 50px">
                                                    <span style="font-size: 4.615rem"
                                                          class="mdi mdi-crosshairs-gps"></span>
                                                </div>
                                                <h3 class="text-center" style="padding-bottom: 100px">
                                                    You don't have any geo-fence areas.
                                                </h3>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row be-datatable-footer">
                            <div class="col-sm-5">
                                <div class="dataTables_info">
                                    {{ count }} Geo-Fence{{ count != 1 ? "s" : "" }}
                                </div>
                            </div>
                            <div class="col-sm-7">
                                <div class="dataTables_paginate paging_simple_numbers">
                                    <ul class="pagination">
                                        <li :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasPrev },
                      ]">
                                            <a href="#" class="page-link" @click.prevent="prev">Previous</a>
                                        </li>
                                        <li :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasNext },
                      ]">
                                            <a href="#" class="page-link" @click.prevent="next">Next</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="be-spinner">
                    <svg width="40px" height="40px" viewBox="0 0 66 66">
                        <circle class="circle"
                                fill="none"
                                stroke-width="4"
                                stroke-linecap="round"
                                cx="33"
                                cy="33"
                                r="30"></circle>
                    </svg>
                </div>
            </div>
        </div>

        <div class="modal fade colored-header colored-header-primary"
             id="mod-center-options"
             role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="text-center">
                            <h3 class="p-1">Confirmation</h3>
                            <p>
                                How would like to determine the geo-fence center?
                            </p>
                        </div>
                        <div class="form-group row">
                            <div class="col-12 col-sm-12 col-lg-12">
                                <select class="form-control"
                                        placeholder="Select an option"
                                        v-model="geoFenceCenterOption">
                                    <option value="Address">By Physical Address</option>
                                    <option value="Cordinates">By GPS Cordinates</option>
                                </select>
                                <ul v-if="v3.geoFenceCenterOption" class="parsley-errors-list filled">
                                    <li class="parsley-required">Required.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button style="width: 100px"
                                class="btn btn-secondary"
                                type="button"
                                data-dismiss="modal">
                            Cancel
                        </button>
                        <button style="width: 140px"
                                :disabled="isBusyModal"
                                class="btn btn-warning"
                                type="button"
                                @click.prevent="viewDetermineCenter">
                            <span>Proceed</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade colored-header colored-header-primary"
             id="mod-validate-address"
             role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header modal-header-colored">
                        <h4 class="modal-title p-1"><b>Search By Address</b></h4>
                        <button class="close md-close"
                                type="button"
                                data-dismiss="modal"
                                aria-hidden="true">
                            <span class="mdi mdi-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">

                        <div class="form-group row">
                            <div class="col-12 col-sm-12 col-lg-12">
                                <input class="form-control"
                                       type="text"
                                       placeholder="Address"
                                       v-model="address" />
                                <ul v-if="v4.address" class="parsley-errors-list filled">
                                    <li class="parsley-required">Required.</li>
                                </ul>
                                <br />
                                <button :disabled="isBusyModal" type="button" class="btn btn-info" @click.prevent="findCordinates">
                                    <span v-if="isBusyModal">Busy...</span>
                                    <span v-else>Determine Lat/Lng</span>
                                </button>
                            </div>
                        </div>
                        <div class="form-group row" v-if="isCenterValidated">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Latitude</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <input class="form-control"
                                       type="text"
                                       disabled
                                       v-model="latitude" />
                            </div>
                        </div>
                        <div class="form-group row" v-if="isCenterValidated">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Longitude</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <input class="form-control"
                                       type="text"
                                       disabled
                                       v-model="longitude" />
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer" v-if="isCenterValidated">
                        <button style="width: 100px"
                                class="btn btn-secondary"
                                type="button"
                                data-dismiss="modal">
                            Cancel
                        </button>
                        <button style="width: 180px"
                                :disabled="isBusyModal"
                                class="btn btn-primary"
                                type="button"
                                @click.prevent="viewAdd">
                            <span v-if="isBusyModal">Busy...</span>
                            <span v-else>Proceed</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade colored-header colored-header-primary"
             id="mod-validate-cordinates"
             role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header modal-header-colored">
                        <h4 class="modal-title p-1"><b>Search By GPS Cordinates</b></h4>
                        <button class="close md-close"
                                type="button"
                                data-dismiss="modal"
                                aria-hidden="true">
                            <span class="mdi mdi-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">

                        <div class="form-group row">
                            <div class="col-12">
                                <span class="alert alert-info"><i class="fa fa-info-circle"></i> Values must be decimals</span>
                                <br />&nbsp;
                            </div>
                            <div class="col-6 col-sm-6 col-lg-6">
                                <input class="form-control"
                                       type="number"
                                       placeholder="Latitude"
                                       v-model="latitude" />
                                <ul v-if="v5.latitude" class="parsley-errors-list filled">
                                    <li class="parsley-required">Required.</li>
                                </ul>
                            </div>
                            <div class="col-6 col-sm-6 col-lg-6">
                                <input class="form-control"
                                       type="number"
                                       placeholder="Longitude"
                                       v-model="longitude" />
                                <ul v-if="v5.longitude" class="parsley-errors-list filled">
                                    <li class="parsley-required">Required.</li>
                                </ul>
                            </div>
                            <div class="col-12">
                                <br />
                                <button :disabled="isBusyModal" type="button" class="btn btn-info pull-right" @click.prevent="findAddress">
                                    <span v-if="isBusyModal">Busy...</span>
                                    <span v-else>Determine Address</span>
                                </button>
                            </div>
                        </div>
                        <div class="form-group row" v-if="isCenterValidated">
                            <div class="col-12 col-sm-12 col-lg-12">
                                <textarea class="form-control"
                                          type="text"
                                          disabled
                                          v-model="address" />
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer" v-if="isCenterValidated">
                        <button style="width: 100px"
                                class="btn btn-secondary"
                                type="button"
                                data-dismiss="modal">
                            Cancel
                        </button>
                        <button style="width: 180px"
                                :disabled="isBusyModal"
                                class="btn btn-primary"
                                type="button"
                                @click.prevent="viewAdd">
                            <span v-if="isBusyModal">Busy...</span>
                            <span v-else>Proceed</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade colored-header colored-header-primary"
             id="mod-add"
             role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header modal-header-colored">
                        <h4 class="modal-title p-1"><b>Add new geo-fence</b></h4>
                        <button class="close md-close"
                                type="button"
                                data-dismiss="modal"
                                aria-hidden="true">
                            <span class="mdi mdi-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">

                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Center Address</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <input class="form-control"
                                       type="text"
                                       readonly
                                       v-model="address" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Latitude</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <input class="form-control"
                                       type="text"
                                       readonly
                                       v-model="latitude" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Longitude</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <input class="form-control"
                                       type="text"
                                       readonly
                                       v-model="longitude" />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Radius (in KM)</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <input class="form-control"
                                       type="number"
                                       v-model="createGeoFence.radius" />
                                <ul v-if="v1.radius" class="parsley-errors-list filled">
                                    <li class="parsley-required">Required.</li>
                                </ul>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">User</label>
                            <div class="col-12 col-sm-8 col-lg-8">

                                <multiselect v-model="createGeoFence.userId"
                                             label="text"
                                             track-by="text"
                                             :preserve-search="true"
                                             :options="Users"></multiselect>
                                <ul v-if="v1.userId" class="parsley-errors-list filled">
                                    <li class="parsley-required">Required.</li>
                                </ul>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Exceptions</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <multiselect v-model="createGeoFence.userExceptions"
                                             :multiple="true"
                                             label="text"
                                             track-by="text"
                                             :preserve-search="true"
                                             :options="Customers"></multiselect>
                                <ul v-if="v1.userExceptions" class="parsley-errors-list filled">
                                    <li class="parsley-required">Required.</li>
                                </ul>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Active</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <label class="custom-control custom-checkbox custom-control-inline">
                                    <input class="custom-control-input"
                                           type="checkbox"
                                           v-model="createGeoFence.active" />
                                    <span class="custom-control-label">This Geo-Fence is active</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button style="width: 100px"
                                class="btn btn-secondary"
                                type="button"
                                data-dismiss="modal">
                            Cancel
                        </button>
                        <button style="width: 180px"
                                :disabled="isBusyModal"
                                class="btn btn-primary"
                                type="button"
                                @click.prevent="create">
                            <span v-if="isBusyModal">Busy...</span>
                            <span v-else>Add Geo-Fence</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade colored-header colored-header-primary"
             id="mod-edit"
             role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header modal-header-colored">
                        <h4 class="modal-title p-1"><b>Edit Geo-Fence</b></h4>
                        <button class="close md-close"
                                type="button"
                                data-dismiss="modal"
                                aria-hidden="true">
                            <span class="mdi mdi-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">

                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Center Address</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <input class="form-control"
                                       type="text"
                                       readonly
                                       v-model="address" />
                              
                                <a class="pull-right" href="javasript:void"
                                @click.prevent="editAddress"><i class="fa fa-edit"></i> Edit</a>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Latitude</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <input class="form-control"
                                       type="text"
                                       readonly
                                       v-model="latitude" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Longitude</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <input class="form-control"
                                       type="text"
                                       readonly
                                       v-model="longitude" />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Radius (in KM)</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <input class="form-control"
                                       type="number"
                                       v-model="updateGeoFence.radius" />
                                <ul v-if="v2.radius" class="parsley-errors-list filled">
                                    <li class="parsley-required">Required.</li>
                                </ul>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">User</label>
                            <div class="col-12 col-sm-8 col-lg-8">

                                <multiselect v-model="updateGeoFence.userId"
                                             label="text"
                                             track-by="text"
                                             :preserve-search="true"
                                             :options="Users"></multiselect>
                                <ul v-if="v2.userId" class="parsley-errors-list filled">
                                    <li class="parsley-required">Required.</li>
                                </ul>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Exceptions</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <multiselect v-model="updateGeoFence.userExceptions"
                                             :multiple="true"
                                             label="text"
                                             track-by="text"
                                             :preserve-search="true"
                                             :options="Customers"></multiselect>
                                <ul v-if="v2.userExceptions" class="parsley-errors-list filled">
                                    <li class="parsley-required">Required.</li>
                                </ul>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Active</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <label class="custom-control custom-checkbox custom-control-inline">
                                    <input class="custom-control-input"
                                           type="checkbox"
                                           v-model="updateGeoFence.active" />
                                    <span class="custom-control-label">This Geo-Fence is active</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button style="width: 100px"
                                class="btn btn-secondary"
                                type="button"
                                data-dismiss="modal">
                            Cancel
                        </button>
                        <button style="width: 180px"
                                :disabled="isBusyModal"
                                class="btn btn-primary"
                                type="button"
                                @click.prevent="update">
                            <span v-if="isBusyModal">Busy...</span>
                            <span v-else>Update Geo-Fence</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
       

        <div class="modal fade colored-header colored-header-primary"
             id="mod-filter"
             role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header modal-header-colored">
                        <h4 class="modal-title p-1"><b>Filter</b></h4>
                        <button class="close md-close"
                                type="button"
                                data-dismiss="modal"
                                aria-hidden="true">
                            <span class="mdi mdi-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">User</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <multiselect v-model="filter.userId"
                                             label="text"
                                             track-by="text"
                                             :preserve-search="true"
                                             :options="Users"></multiselect>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Status</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <select class="form-control"
                                        placeholder="Select an option"
                                        v-model="filter.status">
                                    <option value="0">All Status</option>
                                    <option value="True">Active</option>
                                    <option value="False">Disabled</option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Other filters</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <input class="form-control"
                                       type="text"
                                       placeholder="Filter by name..."
                                       v-model="filter.qry" />
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button style="width: 100px"
                                class="btn btn-secondary md-close"
                                type="button"
                                data-dismiss="modal">
                            Cancel
                        </button>
                        <button style="width: 100px"
                                class="btn btn-secondary md-close"
                                type="button"
                                @click="reset">
                            Reset
                        </button>
                        <button style="width: 100px"
                                class="btn btn-primary md-close"
                                type="button"
                                @click="getGeoFences">
                            Search
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    /* eslint-disable */

    import moment from "moment";
    import { helper } from "@/helper";
    import Select2 from "@/components/Select2";
    import SystemPermissions from "@/permissions";
    import Multiselect from "vue-multiselect";
    import Vue from "vue";

    export default {
        mixins: [SystemPermissions.Mixin],
        name: "settingsGeoFences",

        components: {
            Select2, Multiselect
        },

        created() {
            if (!this.hasPermission("GeoFencing_View")) {
                this.$router.push("/restricted");
            }
            this.getGeoFences();
            this.getUsers();
            this.getCustomers();
        },

        watch: {},

        data() {
            return {
                filter: {
                    status: "0",
                    userId: "",
                    qry: ""
                },
                isBusy: false,
                isBusyModal: false,
                geoFenceCenterOption: "",
                isCenterValidated: false,
                isUpdate: false,
                latitude: "",
                longitude: "",
                address: "",
                createGeoFence: {
                    userId: "",
                    radius: "",
                    active: false,
                    userExceptions: "",
                },
                updateGeoFence: {
                    userId: "",
                    radius: "",
                    id: "",
                    active: true,
                    userExceptions: "",
                },

                editGeoFence: {
                    userId: "",
                    radius: "",
                    id: "",
                    active: true,
                    userExceptions: "",
                },

                v1: {
                    userId: false,
                    radius: false,
                    userExceptions: false,
                },

                v2: {
                    userId: false,
                    radius: false,
                    userExceptions: false,
                },
                v3: {
                    geoFenceCenterOption: false
                },
                v4: {
                    address: false
                },
                v5: {
                    latitude: false,
                    longitude: false
                },

                GeoFences: [],
                Users: [],
                Customers: [],
                count: 0,
                page: 0,
                pageSize: 10,
                hasNext: false,
                hasPrev: false,
            };
        },

        computed: {
            user: function () {
                return this.$store.state.currentUser;
            },
        },

        methods: {
            reset() {
                this.filter = {
                    status: "0",
                    userId: "",
                    qry: "",
                };
            },
            cancel() {
                const currentGeoFence = this.GeoFences.find(
                    (l) => l.id === this.editGeoFence.id
                );
                currentGeoFence.radius = this.editGeoFence.radius;
                currentGeoFence.userId = this.editGeoFence.userId;
                currentGeoFence.userExceptions = this.editGeoFence.userExceptions;
                currentGeoFence.active = this.editGeoFence.active;
                currentGeoFence.id = this.editGeoFence.id;
                currentGeoFence.address = this.editGeoFence.address;
                currentGeoFence.latitude = this.editGeoFence.latitude;
                currentGeoFence.longitude = this.editGeoFence.longitude;
                $("#mod-edit").modal("hide");
            },
            viewExport() {
                //   $("#mod-export").modal("show");
            },
            viewCenterOptions() {
                this.geoFenceCenterOption = "";
                this.latitude = "";
                this.longitude = "";
                this.address = "";
                this.isCenterValidated = false;
                this.createGeoFence = {
                    userId: "",
                    radius: "",
                    active: false,
                    userExceptions: ""
                }
                this.v1 = {
                    userId: false,
                    radius: false,
                    latitude: false,
                    longitude: false,
                    userExceptions: false,
                };
                this.v3 = {
                    geoFenceCenterOption: false
                };
              
                $("#mod-center-options").modal("show");
            },
            viewDetermineCenter() {
                if (!this.geoFenceCenterOption || this.geoFenceCenterOption == '') {
                    this.v3.geoFenceCenterOption = true;
                    return;
                }
                else if (this.geoFenceCenterOption == 'Address') {
                    $("#mod-validate-address").modal("show");
                }
                else if (this.geoFenceCenterOption == 'Cordinates') {
                    $("#mod-validate-cordinates").modal("show");
                }
                this.isCenterValidated = false;
            },
            viewAdd() {

                if (this.isUpdate) {
                    $("#mod-edit").modal("show");
                }
                else {
                    $("#mod-add").modal("show");
                }
                this.isUpdate = false;
                $("#mod-center-options").modal("hide");
                $("#mod-validate-address").modal("hide");
                $("#mod-validate-cordinates").modal("hide");
            },
            editAddress() {
                this.isUpdate = true;
                $("#mod-edit").modal("hide");
                this.viewCenterOptions();
            },
            viewEdit(c) {
                var editValues = {
                    radius: c.radius,
                    userId: c.userId,
                    active: c.active,
                    id: c.id,
                    userExceptions: c.userExceptions,
                    address: c.address,
                    latitude: c.latitude,
                    longitude: c.longitude,
                };
                this.editGeoFence = editValues;
                this.updateGeoFence.radius = c.radius;
                this.updateGeoFence.id = c.id;
                this.address = c.address;
                this.latitude = c.latitude;
                this.longitude = c.longitude;
          
                const exceptions = c.userExceptions.map((val) => {
                    return { id: val.user.id, text: val.user.displayName };
                });
                this.updateGeoFence.userExceptions = exceptions;

                const user = {
                    id: c.user.id,
                    text: c.user.displayName
                };
                this.updateGeoFence.userId = user;
                $("#mod-edit").modal("show");
            },
            viewFilter() {
                $("#mod-filter").modal("show");
            },
            findCordinates() {

                var isValid = true;
                this.v4.address = false;
              
                if (!this.address || this.address == '') {
                    this.v4.address = true;
                    isValid = false;
                }

                if (isValid) {
                    this.isBusyModal = true;
                    this.latitude = '';
                    this.longitude = '';
                    Vue.http
                        .get(`https://maps.google.com/maps/api/geocode/json?address=${this.address}&key=AIzaSyDpq2Uio9XMcZ8UuNsUPz1JsVHzLAiocRw`)
                        .then(response => {
                            if (response.data.results && response.data.results.length > 0) {
                                this.latitude = response.data.results[0].geometry.location.lat;
                                this.longitude = response.data.results[0].geometry.location.lng;
                                this.isCenterValidated = true;
                            }
                            else {

                            }
                        })
                        .catch(error => {
                            //console.log(error)
                        })
                        .finally(() => this.isBusyModal = false)
                }
            },
            findAddress() {
                var isValid = true;
                this.v5.latitude = false;
                this.v5.longitude = false;
                if (!this.latitude || this.latitude == '') {
                    this.v5.latitude = true;
                    isValid = false;
                }
                if (!this.longitude || this.longitude == '') {
                    this.v5.longitude = true;
                    isValid = false;
                }

                if (isValid) {
                    this.isBusyModal = true;
                    this.address = '';
                    Vue.http
                        .get(`https://maps.google.com/maps/api/geocode/json?latlng=${this.latitude},${this.longitude}&key=AIzaSyDpq2Uio9XMcZ8UuNsUPz1JsVHzLAiocRw`)
                        .then(response => {
                            if (response.data.results && response.data.results.length > 0) {
                                this.address = response.data.results[0].formatted_address;
                                this.isCenterValidated = true;
                            }
                            else {

                            }
                        })
                        .catch(error => {
                            //console.log(error)
                        })
                        .finally(() => this.isBusyModal = false)
                }
            },
            async deleteGeoFence(c) {
                if (confirm("Are you sure you want to delete this record?")) {
                    this.isBusyModal = true;
                    this.isBusy = true;

                    await this.$http
                        .post("/GeoFence/delete/" + c.id, null)
                        .then(function (res) {
                            this.isBusyModal = false;
                        })
                        .catch(function () { });

                    this.getGeoFences();

                    this.isBusy = false;
                }
            },
            async create() {
                this.isBusyModal = true;
                this.isBusy = true;

                if (this.validateCreate()) {
                    var exceptions = [];

                    if (this.createGeoFence.userExceptions && this.createGeoFence.userExceptions.length > 0) {
                        exceptions = this.createGeoFence.userExceptions.map((val) => {
                            return val.id;
                        });
                    }

                    var data = {
                        userId: this.createGeoFence.userId.id,
                        address: this.address,
                        latitude: this.latitude,
                        longitude: this.longitude,
                        radius: this.createGeoFence.radius,
                        userExceptions: exceptions,
                        active: this.createGeoFence.active,
                        parentId: this.user.parentId,
                    };
                    $("#mod-add").modal("hide");

                    await this.$http
                        .post("/GeoFence/create", data)
                        .then(function (res) {
                            this.isBusyModal = false;
                        })
                        .catch(function () { });

                    this.getGeoFences();
                }

                this.isBusy = false;
                this.isBusyModal = false;
            },
            validateCreate() {
                this.v1 = {
                    userId: false,
                    radius: false,
                    userExceptions: false,
                };
                var isValid = true;

                if (!this.createGeoFence.userId) {
                    this.v1.userId = true;
                    isValid = false;
                }

                if (!this.createGeoFence.radius) {
                    this.v1.radius = true;
                    isValid = false;
                }

                return isValid;
            },
            async update() {
                this.isBusyModal = true;
                this.isBusy = true;

                if (this.validateUpdate()) {
                    var exceptions = [];

                    if (this.updateGeoFence.userExceptions && this.updateGeoFence.userExceptions.length > 0) {
                        exceptions = this.updateGeoFence.userExceptions.map((val) => {
                            return val.id;
                        });
                    }
                
                    var data = {
                        userId: this.updateGeoFence.userId.id,
                        address: this.address,
                        latitude: this.latitude,
                        longitude: this.longitude,
                        radius: this.updateGeoFence.radius,
                        userExceptions: exceptions,
                        active: this.updateGeoFence.active,
                        parentId: this.user.parentId,
                    };
                    $("#mod-edit").modal("hide");

                    await this.$http
                        .put("/GeoFence/update/" + this.updateGeoFence.id, data)
                        .then(function (res) {
                            this.isBusyModal = false;
                        })
                        .catch(function () { });

                    this.getGeoFences();
                }

                this.isBusy = false;
                this.isBusyModal = false;
            },

            validateUpdate() {
                this.v2 = {
                    userId: false,
                    radius: false,
                    userExceptions: false,
                };
                var isValid = true;

                if (!this.updateGeoFence.userId) {
                    this.v2.userId = true;
                    isValid = false;
                }

                if (!this.updateGeoFence.radius) {
                    this.v2.radius = true;
                    isValid = false;
                }

                return isValid;
            },

            async getGeoFences() {
                this.isBusy = true;

                await this.$http
                    .get(
                        "/GeoFence/getList?page=" +
                        this.page +
                        "&pageSize=" +
                        this.pageSize +
                        (this.filter.status != "0" ? "&active=" + this.filter.status : "") +
                        (this.filter.userId && this.filter.userId != ""
                            ? "&userId=" + this.filter.userId.id
                            : "") +
                        (this.filter.qry != "" ? "&query=" + this.filter.qry : "") +
                        (this.user.parentId ? "&parentId=" + this.user.parentId : "") 
                    )
                    .then(function (res) {
                        this.GeoFences = res.body.data;
                        this.page = res.body.page;
                        this.pageSize = res.body.pageSize;
                        this.count = res.body.count;
                        this.hasPrev = res.body.hasPrev;
                        this.hasNext = res.body.hasNext;
                        this.isBusy = false;
                    })
                    .catch(function () { });

                $("#mod-filter").modal("hide");
                this.isBusy = false;
            },
            getUsers() {
                var a = true;

                this.$http
                    .get(
                        "/Users/getItemListOfUsers/" + this.user.parentId
                    )
                    .then(function (res) {
                        this.Users = res.body;
                    })
                    .catch(function () { });
            },
            getCustomers() {
                var a = true;

                this.$http
                    .get(
                        "/Users/getItemListOfCustomers/" + this.user.parentId
                    )
                    .then(function (res) {
                        this.Customers = res.body;
                    })
                    .catch(function () { });
            },
            formatDate(date) {
                return moment(date).format("lll");
            },
            formatMoney(val) {
                return helper.formatMoney(val);
            },
            formatNumber(val) {
                return helper.formatNumber(val);
            },
            getArea(radius) {
                return Math.round((3.142) * radius * radius)
            },
            prev() {
                this.page -= 1;
                this.getGeoFences();
            },
            next() {
                this.page += 1;
                this.getGeoFences();
            },
        },
    };
</script>