<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          'card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <button
            class="btn btn-success btn-rounded btn-space"
            @click="viewFilter"
          >
            <span class="feather icon-filter text-white"></span> Filter
          </button>

          <div class="btn-group">
            <button
              class="btn btn-warning btn-rounded btn-space float-right"
              @click="viewAdd"
              v-if="
                user.role == 'Administrator' && hasPermission('User_Create')
              "
            >
              <span class="icon icon-left mdi mdi-plus text-white"></span>
              Invite user
            </button>

            <button
              class="btn btn-primary btn-rounded btn-space float-right"
              @click="viewAddManual"
              v-if="
                user.role == 'Administrator' && hasPermission('User_Create')
              "
            >
              <span class="icon icon-left mdi mdi-plus text-white"></span>
              Create user manually
            </button>
          </div>
        </div>
        <div class="card-body">
          <div
            class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
          >
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table class="table table-fw-widget dataTable no-footer">
                  <thead>
                    <tr>
                      <th style="width: 15%">User</th>
                      <th style="width: 10%">Status</th>
                      <th style="width: 10%">Role</th>
                      <th style="width: 10%">Branch</th>
                      <th style="width: 10%">Staff Id</th>
                      <th style="width: 10%">Created</th>
                      <th style="width: 20%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="u in users" :key="u.id">
                      <td>
                        <b>
                          <span v-if="u.displayName">
                            {{ u.displayName }}
                            <br />
                          </span>
                        </b>
                        {{ u.emailAddress ? u.emailAddress : u.phoneNumber }}
                        <br />
                        <span
                          style="
                            color: gray;
                            font-size: 11px;
                            font-weight: bold;
                          "
                          >{{ u.username }}</span
                        >
                      </td>
                      <td>
                        <span
                          :class="[
                            'badge',
                            ' badge-pill',
                            { 'badge-primary': u.status == 'Pending' },
                            { 'badge-success': u.status == 'Active' },
                            { 'badge-danger': u.status == 'Disabled' },
                          ]"
                          >{{
                            u.status == "Pending" ? "Invitation sent" : u.status
                          }}</span
                        >
                      </td>
                      <td>{{ u.role }}</td>
                      <td>{{ u.branch ? u.branch.name : "N/A" }}</td>
                      <td>{{ u.staffId ? u.staffId : "N/A" }}</td>
                      <td>{{ formatDate(u.created) }}</td>
                      <td>
                        <div class="dropdown">
                          <button
                            class="
                              btn btn-outline-primary btn-sm
                              dropdown-toggle
                            "
                            type="button"
                            id="dropdownMenu2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Actions
                          </button>
                          <div
                            class="dropdown-menu px-1"
                            aria-labelledby="dropdownMenu2"
                          >
                            <button
                              v-if="
                                u.status != 'Inactive' &&
                                hasPermission('User_Edit_Branch')
                              "
                              class="
                                btn
                                btn-outline-info
                                btn-sm
                                btn-rounded
                                btn-space
                                mt-1
                              "
                              @click.prevent="viewEditBranch(u)"
                            >
                              Edit Branch</button
                            >&nbsp;
                            <button
                              v-if="
                                u.id != user.id &&
                                u.status != 'Inactive' &&
                                hasPermission('User_Edit_Role')
                              "
                              class="
                                btn
                                btn-outline-info
                                btn-sm
                                btn-rounded
                                btn-space
                                mt-1
                              "
                              @click.prevent="viewEdit(u)"
                            >
                              Edit Role</button
                            >&nbsp;
                            <button
                              v-if="
                                u.status != 'Inactive' &&
                                hasPermission('User_Update')
                              "
                              class="
                                btn
                                btn-outline-info
                                btn-sm
                                btn-rounded
                                btn-space
                                mt-1
                              "
                              @click.prevent="viewEditName(u)"
                            >
                              Edit Name
                            </button>
                            <div>
                              <button
                                v-if="
                                  u.status != 'Inactive' &&
                                  hasPermission('User_Update')
                                "
                                class="
                                  btn
                                  btn-outline-info
                                  btn-sm
                                  btn-rounded
                                  btn-space
                                  mt-1
                                "
                                @click.prevent="viewEditPhone(u)"
                              >
                                Edit Phone</button
                              >&nbsp;
                              <button
                                v-if="
                                  u.status != 'Inactive' &&
                                  hasPermission('User_Update')
                                "
                                class="
                                  btn
                                  btn-outline-info
                                  btn-sm
                                  btn-rounded
                                  btn-space
                                  mt-1
                                "
                                @click.prevent="viewEditEmail(u)"
                              >
                                Edit Email</button
                              >&nbsp;
                              <button
                                v-if="
                                  u.id != user.id &&
                                  hasPermission('User_Enable_Disable')
                                "
                                class="
                                  btn
                                  btn-outline-danger
                                  btn-sm
                                  btn-rounded
                                  btn-space
                                  mt-1
                                "
                                @click.prevent="viewRemove(u)"
                              >
                                {{
                                  u.status == "Inactive" ? "Enable" : "Disable"
                                }}
                              </button>
                            </div>
                            <div>
                              <button
                                class="
                                  btn
                                  btn-outline-primary
                                  btn-sm
                                  btn-rounded
                                  btn-space
                                  mt-1
                                "
                                @click.prevent="viewResend(u)"
                                v-if="
                                  u.id != user.id &&
                                  u.status == 'Pending' &&
                                  hasPermission('User_Send_Invite')
                                "
                              >
                                Resend Invite
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="!users.length">
                      <td colspan="8">
                        <div class="text-center">No matching records</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">
                  {{ count }} user{{ count != 1 ? "s" : "" }}
                </div>
              </div>
              <div class="col-sm-7">
                <div class="dataTables_paginate paging_simple_numbers">
                  <ul class="pagination">
                    <li
                      :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasPrev },
                      ]"
                    >
                      <a href="#" class="page-link" @click.prevent="prev"
                        >Previous</a
                      >
                    </li>
                    <li
                      :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasNext },
                      ]"
                    >
                      <a href="#" class="page-link" @click.prevent="next"
                        >Next</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-add"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Invite new user</b></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Send invite via</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="invitedUser.type"
                >
                  <option value="email">Email</option>
                </select>
                <ul v-if="v1.type" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row" v-if="invitedUser.type == 'email'">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Email address</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="[
                    'form-control',
                    { 'is-invalid': v1.emailAddress },
                    { 'is-invalid': v1.emailAddressValid },
                  ]"
                  type="email"
                  placeholder="Email address of user"
                  autocomplete="off"
                  v-model="invitedUser.emailAddress"
                />
                <ul v-if="v1.emailAddress" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
                <ul
                  v-if="v1.emailAddressValid"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Enter a valid email.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row" v-if="invitedUser.type == 'sms'">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Phone Number</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="[
                    'form-control',
                    { 'is-invalid': v1.phoneNumber },
                    { 'is-invalid': v1.phoneNumberValid },
                  ]"
                  type="text"
                  placeholder="Phone number of user"
                  autocomplete="off"
                  v-model="invitedUser.phoneNumber"
                />
                <ul v-if="v1.phoneNumber" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
                <ul
                  v-if="v1.phoneNumberValid"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Enter a valid phone number.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row" v-if="invitedUser.type == 'sms'">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Name</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v1.name }]"
                  type="text"
                  placeholder="Name of user"
                  v-model="invitedUser.name"
                />
                <ul v-if="v1.name" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Role</label
              >

              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="invitedUser.role"
                >
                  <option v-for="r in roles" :key="r.id" :value="r.text">
                    {{ r.text }}
                  </option>
                </select>
                <ul v-if="v1.role" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Staff Id</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Staff Id of user"
                  v-model="invitedUser.staffId"
                />
              </div>
            </div>

            <div class="form-group row" v-if="invitedUser.role == 'Sales'">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Sales Access Id</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v1.salesAccessId }]"
                  type="text"
                  placeholder="Sales Access Id of user"
                  v-model="invitedUser.salesAccessId"
                />
                <ul v-if="v1.salesAccessId" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>

            <div class="form-group row" v-if="user.type != 'Manufacturer'">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Branch</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="invitedUser.branch"
                >
                  <option v-for="br in Branches" :key="br.id" :value="br.id">
                    {{ br.name }}
                  </option>
                </select>
                <ul v-if="v1.branch" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="invite"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Invite</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <create-user @onCreateUserComplete="handleCreateUserComplete" />

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-edit"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1">
              <b>Edit role for user:</b>
              {{
                editingUser.displayName
                  ? editingUser.displayName
                  : editingUser.emailAddress
                  ? editingUser.emailAddress
                  : editingUser.phoneNumber
              }}
            </h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Role</label
              >

              <div
                v-if="user.type == 'Distributor' || user.type == 'Manufacturer'"
                class="col-12 col-sm-8 col-lg-8"
              >
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="editingUser.role"
                >
                  <option v-for="r in roles" :key="r.id" :value="r.text">
                    {{ r.text }}
                  </option>
                </select>
                <ul v-if="v2.role" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
              <div v-else class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="editingUser.role"
                >
                  <option value="Administrator">Administrator</option>
                </select>
                <ul v-if="v2.role" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="updateRole"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-edit-branch"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1">
              <b>Edit branch for user:</b>
              {{
                editingUser.displayName
                  ? editingUser.displayName
                  : editingUser.emailAddress
                  ? editingUser.emailAddress
                  : editingUser.phoneNumber
              }}
            </h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Branch</label
              >
              <div class="col-12 col-sm-8 col-lg-6">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="editingUser.branchId"
                >
                  <option v-for="br in Branches" :key="br.id" :value="br.id">
                    {{ br.name }}
                  </option>
                </select>
                <ul v-if="v3.branch" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="updateBranch"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-edit-name"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1">
              <b>Edit name for user:</b>
              {{
                editingUser.displayName
                  ? editingUser.displayName
                  : editingUser.emailAddress
                  ? editingUser.emailAddress
                  : editingUser.phoneNumber
              }}
            </h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Name</label
              >
              <div class="col-12 col-sm-8 col-lg-6">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Name..."
                  v-model="editingUser.displayName"
                />
                <ul v-if="v5.name" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="updateName"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-edit-email"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1">
              <b>Edit email for user:</b>
              {{
                editingUser.displayName
                  ? editingUser.displayName
                  : editingUser.emailAddress
                  ? editingUser.emailAddress
                  : editingUser.phoneNumber
              }}
            </h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Email Address</label
              >
              <div class="col-12 col-sm-8 col-lg-6">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Email Address..."
                  v-model="editingUser.emailAddress"
                />
                <ul v-if="v6.email" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="updateEmail"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-edit-phone"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1">
              <b>Edit phone number for user:</b>
              {{
                editingUser.displayName
                  ? editingUser.displayName
                  : editingUser.emailAddress
                  ? editingUser.emailAddress
                  : editingUser.phoneNumber
              }}
            </h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Phone Number</label
              >
              <div class="col-12 col-sm-8 col-lg-6">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Phone Number..."
                  v-model="editingUser.phoneNumber"
                />
                <ul v-if="v7.phone" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="updatePhone"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-delete"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-center">
              <h3 class="p-1"><b>Confirmation</b></h3>
              <p>
                Are you sure you want to
                {{ modalUser.status == "Inactive" ? "enable" : "disable" }} this
                user:<br />
                <strong>{{
                  modalUser.displayName
                    ? modalUser.displayName
                    : modalUser.emailAddress
                }}</strong
                >?
              </p>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 130px"
              :disabled="isBusyModal"
              class="btn btn-warning"
              type="button"
              @click.prevent="updateStatus"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Yes, proceed</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-filter"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Filter</b></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Status</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="filter.status"
                >
                  <option value="0">All Users</option>
                  <option value="active">Active</option>
                  <option value="inactive">InActive</option>
                  <option value="pending">Others</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Roles</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="filter.role"
                >
                  <option value="0">All Roles</option>
                  <option v-for="r in roles" :key="r.id" :value="r.text">
                    {{ r.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Other filters</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Filter by name, email or phone number..."
                  v-model="filter.qry"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              @click="reset"
            >
              Reset
            </button>
            <button
              :disabled="isBusyModal"
              style="width: 100px"
              class="btn btn-primary md-close"
              type="button"
              @click="getUsers"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-resend"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-center">
              <h3>Resend Invite</h3>
              <p>
                You are about to resend an invite to this customer:
                <strong>{{
                  modalUser.displayName || modalUser.emailAddress
                }}</strong
                >?
              </p>
            </div>
            <div v-if="enableInviteOption" class="mt-2 form-group row">
              <label
                class="col-12 col-sm-3 col-form-label text-sm-right"
              ></label>
              <div class="col-12 col-sm-8 col-lg-8">
                <div
                  class="custom-control custom-radio"
                  :class="[{ 'is-invalid': v8.selectedOption }]"
                  v-for="option in inviteOptions"
                  :key="option.value"
                >
                  <input
                    type="radio"
                    :id="option.value"
                    name="customRadio"
                    class="custom-control-input"
                    :value="option.value"
                    v-model="selectedOption"
                  />
                  <label class="custom-control-label" :for="option.value">{{
                    option.name
                  }}</label>
                </div>
                <ul v-if="v8.selectedOption" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="attemptResendInvite"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Yes, proceed</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import { helper } from "@/helper";
import SystemPermissions from "@/permissions";
import Select2 from "@/components/Select2";
import CreateUser from "../components/User/CreateUser.vue";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "settingsTeam",
  components: {
    Select2,
    CreateUser,
  },

  created() {
    if (!this.hasPermission("User_View")) {
      this.$router.push("/restricted");
    }
    this.getUsers();
    this.getBranches();
    this.getRoles();
  },

  data() {
    return {
      filter: {
        status: "0",
        role: "0",
        qry: "",
      },
      isBusy: false,
      isBusyModal: false,
      invitedUser: {
        type: null,
        phoneNumber: null,
        emailAddress: null,
        name: null,
        role: null,
        branch: null,
        staffId: null,
        salesAccessId: null,
      },

      createUser: {
        username: null,
        password: null,
        phoneNumber: null,
        emailAddress: null,
        name: null,
        role: null,
        branch: null,
        staffId: null,
        salesAccessId: null,
      },

      editingUser: {
        emailAddress: null,
        phoneNumber: null,
        displayName: null,
        role: null,
        branchId: null,
      },

      modalUser: {
        emailAddress: null,
        displayName: null,
      },

      Branches: [],
      v1: {
        type: false,
        phoneNumber: false,
        phoneNumberValid: false,
        emailAddress: false,
        emailAddressValid: false,
        name: false,
        role: false,
        branch: false,
        salesAccessId: false,
      },

      v2: {
        role: false,
      },

      v3: {
        branch: false,
      },

      v5: {
        name: false,
      },
      v6: {
        email: false,
      },
      v7: {
        phone: false,
      },
      users: [],
      roles: [],
      count: 0,
      page: 0,
      pageSize: 10,
      hasNext: false,
      hasPrev: false,

      v8: {
        selectedOption: false,
      },

      selectedOption: "",
    };
  },

  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },

    enableInviteOption: function () {
      return this.modalUser.emailAddress && this.modalUser.phoneNumber;
    },
  },

  methods: {
    reset() {
      this.filter = {
        status: "0",
        role: "0",
        qry: "",
      };
    },

    viewAdd() {
      this.invitedUser = {
        emailAddress: null,
        role: null,
        branch: null,
        staffId: null,
        salesAccessId: null,
      };
      this.v1 = {
        emailAddress: false,
        emailAddressValid: false,
        role: false,
        branchId: false,
        salesAccessId: false,
      };
      $("#mod-add").modal("show");
    },

    viewAddManual() {
      $("#mod-add-manual").modal("show");
    },

    viewEdit(u) {
      this.editingUser = u;
      $("#mod-edit").modal("show");
    },

    viewEditBranch(u) {
      this.editingUser = u;
      $("#mod-edit-branch").modal("show");
    },

    viewEditName(u) {
      this.editingUser = u;
      $("#mod-edit-name").modal("show");
    },

    viewEditPhone(u) {
      this.editingUser = u;
      $("#mod-edit-phone").modal("show");
    },

    viewEditEmail(u) {
      this.editingUser = u;
      $("#mod-edit-email").modal("show");
    },

    viewRemove(u) {
      this.modalUser = u;
      $("#mod-delete").modal("show");
    },

    viewResend(u) {
      this.modalUser = u;
      $("#mod-resend").modal("show");
    },

    viewFilter() {
      $("#mod-filter").modal("show");
    },

    handleCreateUserComplete() {
      $("#mod-add-manual").modal("hide");
      this.getUsers();
    },

    async invite() {
      if (this.validateInvite()) {
        this.isBusyModal = true;
        this.isBusy = true;

        let data = {
          phoneNumber: this.invitedUser.phoneNumber,
          emailAddress: this.invitedUser.emailAddress,
          identifierType: this.invitedUser.type,
          userType: this.user.type,
          name: this.invitedUser.name,
          role: this.invitedUser.role,
          parentId: this.user.parentId,
          deliveryAddress: "N/A",
          branchId: this.invitedUser.branch,
          staffId: this.invitedUser.staffId,
          salesAccessId: this.invitedUser.salesAccessId,
        };

        $("#mod-add").modal("hide");

        await this.$http
          .post("/Users/invite", data)
          .then(function (res) {
            this.isBusyModal = false;
            this.getUsers();
          })
          .catch(function () {});

        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    async attemptResendInvite() {
      if (this.enableInviteOption && this.validateResendInvite())
        this.resendInvite();

      if (!this.enableInviteOption) this.resendInvite();
    },

    async resendInvite() {
      this.isBusyModal = true;
      $("#mod-resend").modal("hide");

      await this.$http
        .get(
          "/Users/resendInvite/" +
            this.modalUser.id +
            "?type=" +
            this.selectedOption
        )
        .then(function (res) {
          this.isBusyModal = false;
          if (res.ok && res.body) {
            this.$toast.success("Invite sent successfully.", {
              icon: false,
              rtl: false,
            });
          }
        })
        .catch(function () {
          this.isBusyModal = false;
        });
    },

    validateResendInvite() {
      this.v8 = {
        selectedOption: false,
      };

      let isValid = true;

      if (!this.selectedOption) {
        this.v8.selectedOption = true;
        isValid = false;
      }

      return isValid;
    },

    validateInvite() {
      this.v1 = {
        type: false,
        phoneNumber: false,
        phoneNumberValid: false,
        emailAddress: false,
        emailAddressValid: false,
        name: false,
        role: false,
        branch: false,
        salesAccessId: false,
      };
      let isValid = true;

      if (!this.invitedUser.type) {
        this.v1.type = true;
        isValid = false;
      }

      if (this.invitedUser.type == "sms" && !this.invitedUser.phoneNumber) {
        this.v1.phoneNumber = true;
        isValid = false;
      }

      if (
        this.invitedUser.type == "sms" &&
        this.invitedUser.phoneNumber &&
        !helper.validatePhone(this.invitedUser.phoneNumber)
      ) {
        this.v1.phoneNumberValid = true;
        isValid = false;
      }

      if (this.invitedUser.type == "email" && !this.invitedUser.emailAddress) {
        this.v1.emailAddress = true;
        isValid = false;
      }

      if (
        this.invitedUser.type == "email" &&
        this.invitedUser.emailAddress &&
        !helper.validateEmail(this.invitedUser.emailAddress)
      ) {
        this.v1.emailAddressValid = true;
        isValid = false;
      }

      if (this.invitedUser.type == "sms" && !this.invitedUser.name) {
        this.v1.name = true;
        isValid = false;
      }

      if (!this.invitedUser.role) {
        this.v1.role = true;
        isValid = false;
      }

      if (this.user.type != "Manufacturer" && !this.invitedUser.branch) {
        this.v1.branch = true;
        isValid = false;
      }

      if (this.invitedUser.role == "Sales" && !this.invitedUser.salesAccessId) {
        this.v1.salesAccessId = true;
        isValid = false;
      }

      return isValid;
    },

    updateRole() {
      this.isBusyModal = true;
      this.isBusy = true;

      let data = {
        id: this.editingUser.id,
        role: this.editingUser.role,
      };

      if (this.validateUpdateRole()) {
        $("#mod-edit").modal("hide");

        this.$http
          .put("/Users/updateRole/" + data.id, data)
          .then(function (res) {
            this.isBusyModal = false;
            this.getUsers();
          })
          .catch(function () {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      } else {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    validateUpdateRole() {
      this.v2 = {
        role: false,
      };
      let isValid = true;

      if (!this.editingUser.role) {
        this.v2.role = true;
        isValid = false;
      }
      return isValid;
    },

    updateBranch() {
      this.isBusyModal = true;
      this.isBusy = true;

      let data = {
        id: this.editingUser.id,
        branchId: this.editingUser.branchId,
      };

      if (this.validateUpdateBranch()) {
        $("#mod-edit-branch").modal("hide");

        this.$http
          .put("/Users/updateBranch/" + data.id, data)
          .then(function (res) {
            this.isBusyModal = false;
            this.getUsers();
          })
          .catch(function () {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      } else {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    validateUpdateBranch() {
      this.v3 = {
        branch: false,
      };
      let isValid = true;

      if (!this.editingUser.branchId) {
        this.v3.branch = true;
        isValid = false;
      }
      return isValid;
    },

    updateName() {
      this.isBusyModal = true;
      this.isBusy = true;

      let data = {
        id: this.editingUser.id,
        displayName: this.editingUser.displayName,
      };

      if (this.validateUpdateName()) {
        $("#mod-edit-name").modal("hide");

        this.$http
          .put("/Users/updateName/" + data.id, data)
          .then(function (res) {
            this.isBusyModal = false;
            this.getUsers();
          })
          .catch(function () {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      } else {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    validateUpdateName() {
      this.v5 = {
        name: false,
      };
      let isValid = true;

      if (!this.editingUser.displayName) {
        this.v5.name = true;
        isValid = false;
      }
      return isValid;
    },

    updateEmail() {
      this.isBusyModal = true;
      this.isBusy = true;

      let data = {
        id: this.editingUser.id,
        emailAddress: this.editingUser.emailAddress,
      };

      if (this.validateUpdateEmail()) {
        $("#mod-edit-email").modal("hide");

        this.$http
          .put("/Users/updateEmail/" + data.id, data)
          .then(function (res) {
            this.isBusyModal = false;
            this.getUsers();
          })
          .catch(function () {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      } else {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    validateUpdateEmail() {
      this.v6 = {
        email: false,
      };
      let isValid = true;

      if (!this.editingUser.emailAddress) {
        this.v6.email = true;
        isValid = false;
      }
      return isValid;
    },

    updatePhone() {
      this.isBusyModal = true;
      this.isBusy = true;

      let data = {
        id: this.editingUser.id,
        phoneNumber: this.editingUser.phoneNumber,
      };

      if (this.validateUpdatePhone()) {
        $("#mod-edit-phone").modal("hide");

        this.$http
          .put("/Users/updatePhoneNumber/" + data.id, data)
          .then(function (res) {
            this.isBusyModal = false;
            this.getUsers();
          })
          .catch(function () {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      } else {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    validateUpdatePhone() {
      this.v7 = {
        phone: false,
      };
      let isValid = true;

      if (!this.editingUser.phoneNumber) {
        this.v7.phone = true;
        isValid = false;
      }
      return isValid;
    },

    updateStatus() {
      this.isBusyModal = true;
      let data = {
        id: this.modalUser.id,
        status:
          this.modalUser.status == "Inactive"
            ? this.modalUser.username
              ? "Active"
              : "Pending"
            : "Inactive",
      };

      $("#mod-delete").modal("hide");

      this.$http
        .put("/Users/updateStatus/" + data.id, data)
        .then(function (res) {
          this.isBusyModal = false;
          this.getUsers();
        })
        .catch(function () {
          this.isBusyModal = false;
        });
    },

    async getUsers() {
      this.isBusy = true;
      this.isBusyModal = true;

      await this.$http
        .get(
          "/Users/getList?page=" +
            this.page +
            "&pageSize=" +
            this.pageSize +
            (this.filter.status != "0" ? "&status=" + this.filter.status : "") +
            (this.filter.role != "0" ? "&role=" + this.filter.role : "") +
            (this.filter.qry != "" ? "&query=" + this.filter.qry : "") +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "")
        )
        .then(function (res) {
          this.users = res.body.data;
          this.page = res.body.page;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
        })
        .catch(function () {});

      $("#mod-filter").modal("hide");

      this.isBusy = false;
      this.isBusyModal = false;
    },

    getBranches() {
      var a = true;

      this.$http
        .get(
          "/Branches/getListNoPaging?active=" +
            a +
            "&parentId=" +
            this.user.parentId
        )
        .then(function (res) {
          this.Branches = res.body;
        })
        .catch(function () {});
    },

    getRoles() {
      this.$http
        .get(
          "/roles/getRoleListNoPaging?parentId=" +
            this.user.parentId +
            "&roleType=" +
            this.user.type
        )
        .then(function (res) {
          this.roles = res.body;
        })
        .catch(function () {});
    },

    formatDate(date) {
      return moment(date).format("lll");
    },

    prev() {
      this.page -= 1;
      this.getUsers();
    },

    next() {
      this.page += 1;
      this.getUsers();
    },
  },
};
</script>