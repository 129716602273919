<template>
    <div class="row">
        <div class="col-lg-12">
            <div :class="['card', 'card-border-color', 'card-border-color-primary', 'card-table', 'be-loading', {'be-loading-active':isBusy}]">
                <div class="card-header">
                    <!-- <button class="btn btn-secondary btn-rounded btn-space" @click="viewFilter">
                                  <span class="icon icon-left mdi mdi-tune text-primary"></span> Filter
                    </button>-->
                    <span>&nbsp;</span>
                    <button class="btn btn-primary btn-rounded btn-space float-right"
                            @click="viewAdd"
                            v-if="hasPermission('Discount_Create')">
                        <span class="icon icon-left mdi mdi-plus text-white"></span> New discount
                    </button>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                        <div class="row be-datatable-body">
                            <div class="col-sm-12 table-responsive">
                                <table class="table table-fw-widget dataTable no-footer table-striped table-hover-animation">
                                    <thead>
                                        <tr>
                                            <th style="width:30%;">Name</th>
                                            <th style="width:10%;">Status</th>
                                            <th style="width:25%;">Parent</th>
                                            <th style="width:15%;">Created</th>
                                            <th style="width:20%;"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="d in Discounts" :key="d.id">
                                            <td>{{ d.name }}</td>
                                            <td>
                                                <span :class="['badge', ' badge-pill', { 'badge-success':d.active }, { 'badge-danger':!d.active }]">
                                                    {{
 d.active? 'Active' :
                          'Inactive'
                                                    }}
                                                </span>
                                            </td>
                                            <td>{{ d.parent? d.parent.name : 'System' }}</td>
                                            <td>{{ formatDate(d.created) }}</td>
                                            <td>
                                                <div class="text-right">
                                                    <button v-if="hasPermission('Discount_Update')"
                                                            class="btn btn-success btn-sm btn-rounded btn-space"
                                                            @click.prevent="viewEdit(d)">
                                                        <i class="feather icon-edit"></i>&nbsp;Edit
                                                    </button>&nbsp;
                                                    <button v-if="hasPermission('Discount_View_Members')" class="btn btn-primary btn-sm btn-rounded btn-space"
                                                            @click.prevent="viewMembers(d)">
                                                        <i class="feather icon-users"></i>&nbsp;{{ d.memberCount }} member{{
                            d.memberCount != 1 ? "s" : ""
                                                        }}
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-if="!Discounts.length">
                                            <td colspan="5">
                                                <div class="text-center">No matching records</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row be-datatable-footer">
                            <div class="col-sm-5">
                                <div class="dataTables_info">{{ count }} disount{{ count !=1? 's' : '' }}</div>
                            </div>
                            <div class="col-sm-7">
                                <div class="dataTables_paginate paging_simple_numbers">
                                    <ul class="pagination">
                                        <li :class="['paginate_button', 'page-item', 'previous', {'disabled':!hasPrev}]">
                                            <a href="#" class="page-link" @click.prevent="prev">Previous</a>
                                        </li>
                                        <li :class="['paginate_button', 'page-item', 'previous', {'disabled':!hasNext}]">
                                            <a href="#" class="page-link" @click.prevent="next">Next</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="be-spinner">
                    <svg width="40px" height="40px" viewBox="0 0 66 66">
                        <circle class="circle"
                                fill="none"
                                stroke-width="4"
                                stroke-linecap="round"
                                cx="33"
                                cy="33"
                                r="30"></circle>
                    </svg>
                </div>
            </div>
        </div>

        <div class="modal fade colored-header colored-header-primary" id="mod-add" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header modal-header-colored">
                        <h4 class="modal-title p-1"><b>Create new discount</b></h4>
                        <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
                            <span class="mdi mdi-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Name</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <input :class="['form-control', { 'is-invalid':v1.name }]"
                                       type="text"
                                       v-model="createDiscount.name">
                                <ul v-if="v1.name" class="parsley-errors-list filled">
                                    <li class="parsley-required">Required.</li>
                                </ul>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Value</label>
                            <div class="col-12 col-sm-3 col-lg-3">
                                <select :class="['form-control', { 'is-invalid':v1.type }]"
                                        v-model="createDiscount.type">
                                    <option v-for="type in types" :key="type" :value="type">{{ type }}</option>
                                </select>
                                <ul v-if="v1.type" class="parsley-errors-list filled">
                                    <li class="parsley-required">Required.</li>
                                </ul>
                            </div>
                            <div class="col-12 col-sm-5 col-lg-5">
                                <input :class="['form-control', { 'is-invalid':v1.value }]"
                                       type="number"
                                       v-model="createDiscount.value">
                                <ul v-if="v1.value" class="parsley-errors-list filled">
                                    <li class="parsley-required">Enter a number > 0.</li>
                                </ul>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Active</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <label class="custom-control custom-checkbox custom-control-inline">
                                    <input class="custom-control-input"
                                           type="checkbox"
                                           v-model="createDiscount.active">
                                    <span class="custom-control-label">This discount is active</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button style="width: 100px;"
                                class="btn btn-secondary"
                                type="button"
                                data-dismiss="modal">
                            Cancel
                        </button>
                        <button style="width: 130px;"
                                :disabled="isBusyModal"
                                class="btn btn-primary"
                                type="button"
                                @click.prevent="create">
                            <span v-if="isBusyModal">Busy...</span>
                            <span v-else>Add discount</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade colored-header colored-header-primary" id="mod-edit" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header modal-header-colored">
                        <h4 class="modal-title p-1"><b>Edit discount</b></h4>
                        <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
                            <span class="mdi mdi-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Name</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <input :class="['form-control', { 'is-invalid':v2.name }]"
                                       type="text"
                                       v-model="updateDiscount.name">
                                <ul v-if="v2.name" class="parsley-errors-list filled">
                                    <li class="parsley-required">Required.</li>
                                </ul>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Value</label>
                            <div class="col-12 col-sm-3 col-lg-3">
                                <select :class="['form-control', { 'is-invalid':v2.type }]"
                                        v-model="updateDiscount.type">
                                    <option v-for="type in types" :key="type" :value="type">{{ type }}</option>
                                </select>
                                <ul v-if="v2.type" class="parsley-errors-list filled">
                                    <li class="parsley-required">Required.</li>
                                </ul>
                            </div>
                            <div class="col-12 col-sm-5 col-lg-5">
                                <input :class="['form-control', { 'is-invalid':v2.value }]"
                                       type="number"
                                       v-model="updateDiscount.value">
                                <ul v-if="v2.value" class="parsley-errors-list filled">
                                    <li class="parsley-required">Enter a number > 0.</li>
                                </ul>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Active</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <label class="custom-control custom-checkbox custom-control-inline">
                                    <input class="custom-control-input"
                                           type="checkbox"
                                           v-model="updateDiscount.active">
                                    <span class="custom-control-label">This discount is active</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button style="width: 100px;"
                                class="btn btn-secondary"
                                type="button"
                                data-dismiss="modal">
                            Cancel
                        </button>
                        <button style="width: 100px;"
                                :disabled="isBusyModal"
                                class="btn btn-primary"
                                type="button"
                                @click.prevent="update">
                            <span v-if="isBusyModal">Busy...</span>
                            <span v-else>Update</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade colored-header colored-header-primary" id="mod-filter" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header modal-header-colored">
                        <h3 class="modal-title">Filter</h3>
                        <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
                            <span class="mdi mdi-close"></span>
                        </button>
                    </div>
                    <div class="modal-body"></div>
                    <div class="modal-footer">
                        <button style="width: 100px;"
                                class="btn btn-secondary md-close"
                                type="button"
                                data-dismiss="modal">
                            Cancel
                        </button>
                        <button style="width: 100px;" class="btn btn-secondary md-close" type="button">Reset</button>
                        <button style="width: 100px;" class="btn btn-primary md-close" type="button">Search</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    /* eslint-disable */

    import moment from "moment";
    import { helper } from "@/helper";
    import SystemPermissions from '@/permissions'

    export default {
        mixins: [SystemPermissions.Mixin],
        name: "settingsDiscounts",
        created() {
            if (!this.hasPermission('Discount_View')) {
                this.$router.push("/restricted");
            }
            this.getDiscounts();
            this.getTypes();
        },
        data() {
            return {
                isBusy: false,
                isBusyModal: false,
                createDiscount: {
                    name: null,
                    type: null,
                    value: null,
                    active: false,
                    parentId: null
                },
                updateDiscount: {
                    name: null,
                    type: null,
                    value: null,
                    active: false,
                    id: null
                },
                v1: {
                    name: false,
                    type: false,
                    value: false
                },
                v2: {
                    name: false,
                    type: false,
                    value: false
                },
                Discounts: [],
                types: [],
                count: 0,
                page: 0,
                pageSize: 10,
                hasNext: false,
                hasPrev: false
            };
        },
        computed: {
            user: function () {
                return this.$store.state.currentUser;
            }
        },
        methods: {
            viewAdd() {
                this.createDiscount = {
                    name: null,
                    type: "Percent",
                    value: null,
                    active: false,
                    parentId: null
                };
                this.v1 = {
                    name: false,
                    type: false,
                    value: false
                };

                $("#mod-add").modal("show");
            },
            viewEdit(d) {
                this.updateDiscount = d;
                $("#mod-edit").modal("show");
            },
            viewFilter() {
                $("#mod-filter").modal("show");
            },
            viewMembers(d) {
                this.$router.push("/settings/discounts/" + d.id + "/members");
            },
            create() {
                this.isBusyModal = true;
                this.isBusy = true;

                let data = {
                    name: this.createDiscount.name,
                    type: this.createDiscount.type,
                    value: this.createDiscount.value,
                    active: this.createDiscount.active,
                    parentId: this.user.parentId
                };

                if (this.validateCreate()) {
                    $("#mod-add").modal("hide");

                    this.$http
                        .post("/Discounts/create", data)
                        .then(function (res) {
                            this.isBusyModal = false;
                            this.getDiscounts();
                        })
                        .catch(function () {
                            this.isBusyModal = false;
                        });
                } else {
                    this.isBusy = false;
                    this.isBusyModal = false;
                }
            },
            validateCreate() {
                this.v1 = {
                    name: false,
                    type: false,
                    value: false
                };
                let isValid = true;

                if (!this.createDiscount.name) {
                    this.v1.name = true;
                    isValid = false;
                }

                if (!this.createDiscount.type) {
                    this.v1.type = true;
                    isValid = false;
                }

                if (Number(this.createDiscount.value) <= 0) {
                    this.v1.value = true;
                    isValid = false;
                }

                return isValid;
            },
            update() {
                this.isBusyModal = true;
                this.isBusy = true;

                let data = {
                    name: this.updateDiscount.name,
                    type: this.updateDiscount.type,
                    value: this.updateDiscount.value,
                    active: this.updateDiscount.active,
                    id: this.updateDiscount.id
                };

                if (this.validateUpdate()) {
                    $("#mod-edit").modal("hide");

                    this.$http
                        .put("/Discounts/update/" + data.id, data)
                        .then(function (res) {
                            this.isBusyModal = false;
                            this.getDiscounts();
                        })
                        .catch(function () {
                            this.isBusy = false;
                            this.isBusyModal = false;
                        });
                } else {
                    this.isBusy = false;
                    this.isBusyModal = false;
                }
            },
            validateUpdate() {
                this.v2 = {
                    name: false,
                    type: false,
                    value: false
                };
                let isValid = true;

                if (!this.updateDiscount.name) {
                    this.v2.name = true;
                    isValid = false;
                }

                if (!this.updateDiscount.type) {
                    this.v2.type = true;
                    isValid = false;
                }

                if (Number(this.updateDiscount.value) <= 0) {
                    this.v2.value = true;
                    isValid = false;
                }

                return isValid;
            },
            getDiscounts() {
                this.isBusy = true;

                this.$http
                    .get(
                        "/Discounts/getList?page=" +
                        this.page +
                        "&pageSize=" +
                        this.pageSize +
                        (this.user.parentId ? "&parentId=" + this.user.parentId : "")
                    )
                    .then(function (res) {
                        this.Discounts = res.body.data;
                        this.page = res.body.page;
                        this.pageSize = res.body.pageSize;
                        this.count = res.body.count;
                        this.hasPrev = res.body.hasPrev;
                        this.hasNext = res.body.hasNext;
                        this.isBusy = false;
                    })
                    .catch(function () {
                        this.isBusy = false;
                    });
            },
            getTypes() {
                this.types = ["Percent", "Amount"];
            },
            formatDate(date) {
                return moment(date).format("lll");
            },
            prev() {
                this.page -= 1;
                this.getDiscounts();
            },
            next() {
                this.page += 1;
                this.getDiscounts();
            }
        }
    };
</script>