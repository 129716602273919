<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-border-color card-border-color-primary">
          <div class="card-body">
            <form>
              <h4><strong>Personal Information</strong></h4>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right"
                  >Username</label
                >
                <div class="col-12 col-sm-8 col-lg-6">
                  <input
                    class="form-control"
                    type="text"
                    v-model="user.username"
                    readonly
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right"
                  >Name</label
                >
                <div class="col-12 col-sm-8 col-lg-6">
                  <input
                    :class="[
                      'form-control',
                      { 'is-invalid': userValidation.displayName },
                    ]"
                    type="text"
                    v-model="user.displayName"
                  />
                  <ul
                    v-if="userValidation.displayName"
                    class="parsley-errors-list filled"
                  >
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right"
                  >Email Address</label
                >
                <div class="col-12 col-sm-8 col-lg-6">
                  <input
                    :class="[
                      'form-control',
                      { 'is-invalid': userValidation.emailAddress },
                      { 'is-invalid': userValidation.emailAddressValid },
                    ]"
                    type="email"
                    v-model="user.emailAddress"
                  />
                  <ul
                    v-if="userValidation.emailAddress"
                    class="parsley-errors-list filled"
                  >
                    <li class="parsley-required">Required.</li>
                  </ul>
                  <ul
                    v-if="userValidation.emailAddressValid"
                    class="parsley-errors-list filled"
                  >
                    <li class="parsley-required">
                      Enter a valid email address.
                    </li>
                  </ul>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right"
                  >Pnone Number</label
                >
                <div class="col-12 col-sm-8 col-lg-6">
                  <input
                    :class="[
                      'form-control',
                      { 'is-invalid': userValidation.phoneNumber },
                      { 'is-invalid': userValidation.phoneNumberValid },
                    ]"
                    type="text"
                    v-model="user.phoneNumber"
                  />
                  <ul
                    v-if="userValidation.phoneNumber"
                    class="parsley-errors-list filled"
                  >
                    <li class="parsley-required">Required.</li>
                  </ul>
                  <ul
                    v-if="userValidation.phoneNumberValid"
                    class="parsley-errors-list filled"
                  >
                    <li class="parsley-required">
                      Enter a valid phone number.
                    </li>
                  </ul>
                </div>
              </div>
              <div class="form-group row" v-if="user.role == 'Customer'">
                <label class="col-12 col-sm-3 col-form-label text-sm-right"
                  >Delivery Address</label
                >
                <div class="col-12 col-sm-8 col-lg-6">
                  <textarea
                    :class="[
                      'form-control',
                      { 'is-invalid': userValidation.deliveryAddress },
                    ]"
                    v-model="user.deliveryAddress"
                  ></textarea>
                  <ul
                    v-if="userValidation.deliveryAddress"
                    class="parsley-errors-list filled"
                  >
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group row">
                <label
                  class="col-12 col-sm-3 col-form-label text-sm-right"
                ></label>
                <div class="col-12 col-sm-8 col-lg-6">
                  <button
                    :disabled="isBusyProfile"
                    style="width: 160px"
                    class="btn btn-primary"
                    @click.prevent="updateProfile"
                  >
                    <span v-if="isBusyProfile">Busy...</span>
                    <span v-else>Save changes</span>
                  </button>
                </div>
              </div>

              <h4><strong>Password</strong></h4>

              <p>
                A strong password contains a mix of numbers, letters, and
                symbols. It is hard to guess, does not resemble a real word, and
                is only used for this account.
              </p>

              <div class="form-group row">
                <div class="col-12 col-sm-8 col-lg-6">
                  <button
                    style="width: 190px"
                    class="btn btn-space btn-primary"
                    @click.prevent="showUpdatePassword"
                  >
                    <span>Update Password</span>
                  </button>
                </div>
              </div>

              <h4 v-if="user.role == 'Customer' || user.role == 'Customer'">
                <strong>USSD Access</strong>
              </h4>

              <p v-if="user.role == 'Customer' || user.role == 'Customer'">
                Enables you access some features via our USSD short code.
              </p>

              <div
                v-if="user.role == 'Customer' || user.role == 'Customer'"
                class="form-group row"
              >
                <div class="col-12 col-sm-8 col-lg-6">
                  <button
                    style="width: 190px"
                    class="btn btn-space btn-primary"
                    @click.prevent="showUpdatePIN"
                  >
                    <span v-if="!user.hasPin">Create a new PIN</span>
                    <span v-else>Update PIN</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-update-password"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><strong>Manage password</strong></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-4 col-form-label text-sm-right"
                >Old password</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="[
                    'form-control',
                    { 'is-invalid': passwordValidation.old },
                  ]"
                  type="password"
                  v-model="password.old"
                />
                <ul
                  v-if="passwordValidation.old"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-4 col-form-label text-sm-right"
                >New Password</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="[
                    'form-control',
                    { 'is-invalid': passwordValidation.new },
                    { 'is-invalid': passwordValidation.newValid },
                  ]"
                  type="password"
                  v-model="password.new"
                />
                <ul
                  v-if="passwordValidation.new"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Required.</li>
                </ul>
                <ul
                  v-if="passwordValidation.newValid"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">
                    Enter greater than or equal to 6 characters.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              class="btn btn-space btn-primary"
              @click.prevent="updatePassword"
            >
              <span v-if="isBusyPassword">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-update-pin"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h3 class="modal-title">Manage PIN</h3>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >PIN</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="[
                    'form-control',
                    { 'is-invalid': pinValidation.new },
                    { 'is-invalid': pinValidation.newValid },
                  ]"
                  type="password"
                  v-model="pin.new"
                />
                <ul v-if="pinValidation.new" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
                <ul
                  v-if="pinValidation.newValid"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Enter a 4 digit number.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              class="btn btn-space btn-primary"
              @click.prevent="updatePIN"
            >
              <span v-if="isBusyPIN">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import clone from "lodash/clone";
import { helper } from "@/helper";

export default {
  name: "account",
  data() {
    return {
      password: {
        old: null,
        new: null,
      },

      pin: {
        new: null,
      },

      userValidation: {
        displayName: false,
        emailAddress: false,
        emailAddressValid: false,
        phoneNumber: false,
        phoneNumberValid: false,
        deliveryAddress: false,
      },

      passwordValidation: {
        old: false,
        new: false,
        newValid: false,
      },

      pinValidation: {
        newValid: false,
        new: false,
      },

      isBusy: false,
      isBusyProfile: false,
      isBusyPassword: false,
      isBusyPIN: false,
    };
  },

  computed: {
    user: function () {
      return clone(this.$store.state.currentUser);
    },
  },

  methods: {
    showUpdatePassword() {
      this.password = {
        old: null,
        new: null,
      };
      this.passwordValidation = {
        old: false,
        new: false,
        newValid: false,
      };
      $("#mod-update-password").modal("show");
    },

    showUpdatePIN() {
      this.pin = { new: null };
      this.pinValidation = {
        new: false,
        newValid: false,
      };
      $("#mod-update-pin").modal("show");
    },

    async updateProfile() {
      this.isBusyProfile = true;

      if (this.validateUser()) {
        let data = {
          id: this.user.id,
          displayName: this.user.displayName,
          emailAddress: this.user.emailAddress,
          phoneNumber: this.user.phoneNumber,
          deliveryAddress: this.user.deliveryAddress,
        };

        await this.$http
          .put("/Users/update/" + data.id, data)
          .then(function (res) {
            this.$store.commit("setCurrentUser", this.user);

            this.$toast.success("Your profile details have been updated.", {
              icon: false,
              rtl: false,
            });

            this.isBusyProfile = false;
          })
          .catch(function () {
            var currentUser = clone(this.$store.state.currentUser);
            this.user.phoneNumber = currentUser.phoneNumber;
            this.user.emailAddress = currentUser.emailAddress;
            this.user.displayName = currentUser.displayName;
            this.isBusyProfile = false;
          });
      } else {
        this.isBusyProfile = false;
      }
    },

    updatePassword() {
      this.isBusyPassword = true;

      if (this.validatePassword()) {
        let data = {
          id: this.user.id,
          oldPassword: this.password.old,
          newPassword: this.password.new,
        };

        $("#mod-update-password").modal("hide");

        this.$http
          .put("/Users/updatePassword/" + data.id, data)
          .then(function (res) {
            this.$toast.success("Your password has been updated.", {
              icon: false,
              rtl: false,
            });

            this.isBusyPassword = false;
          })
          .catch(function () {
            this.isBusyPassword = false;
          });

        this.password = {
          old: null,
          new: null,
        };
      } else {
        this.isBusyPassword = false;
      }
    },

    updatePIN() {
      this.isBusyPIN = true;

      if (this.validatePIN()) {
        let data = {
          id: this.user.id,
          pin: this.pin.new,
        };

        $("#mod-update-pin").modal("hide");

        this.$http
          .put("/Users/updatePIN/" + data.id, data)
          .then(function (res) {
            this.$toast.success("Your PIN has been updated.", {
              icon: false,
              rtl: false,
            });

            this.user.hasPin = true;
            this.isBusyPIN = false;
          })
          .catch(function () {
            this.isBusyPIN = false;
          });

        this.pin = {
          new: null,
        };
      } else {
        this.isBusyPIN = false;
      }
    },

    validateUser() {
      this.userValidation = {
        displayName: false,
        emailAddress: false,
        emailAddressValid: false,
        phoneNumber: false,
        phoneNumberValid: false,
        deliveryAddress: false,
      };
      let isValid = true;

      if (!this.user.displayName) {
        this.userValidation.displayName = true;
        isValid = false;
      }

      if (this.user.role == "Customer" && !this.user.deliveryAddress) {
        this.userValidation.deliveryAddress = true;
        isValid = false;
      }

      if (!this.user.emailAddress) {
        this.userValidation.emailAddress = true;
        isValid = false;
      }

      if (
        this.user.emailAddress &&
        !helper.validateEmail(this.user.emailAddress)
      ) {
        this.userValidation.emailAddressValid = true;
        isValid = false;
      }

      if (!this.user.phoneNumber) {
        this.userValidation.phoneNumber = true;
        isValid = false;
      }

      if (
        this.user.phoneNumber &&
        !helper.validatePhone(this.user.phoneNumber)
      ) {
        this.userValidation.phoneNumberValid = true;
        isValid = false;
      }

      return isValid;
    },

    validatePassword() {
      this.passwordValidation = {
        old: false,
        new: false,
        newValid: false,
      };
      let isValid = true;

      if (!this.password.old) {
        this.passwordValidation.old = true;
        isValid = false;
      }

      if (!this.password.new) {
        this.passwordValidation.new = true;
        isValid = false;
      }

      if (this.password.new && !helper.validateLength(this.password.new, 6)) {
        this.passwordValidation.newValid = true;
        isValid = false;
      }

      return isValid;
    },

    validatePIN() {
      this.pinValidation = {
        new: false,
        newValid: false,
      };
      let isValid = true;

      if (!this.pin.new) {
        this.pinValidation.new = true;
        isValid = false;
      }

      if (this.pin.new && !helper.validatePIN(this.pin.new)) {
        this.pinValidation.newValid = true;
        isValid = false;
      }

      return isValid;
    },

    async remove(id) {
      this.isBusy = true;

      await this.$http
        .delete("/Users/deleteParent/" + id)
        .then(function (res) {
          this.getParents();
        })
        .catch(function () {});

      this.isBusy = false;
    },
  },
};
</script>