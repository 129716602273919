var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-menu menu-fixed menu-light menu-accordion menu-shadow",attrs:{"id":"sidebar","data-scroll-to-active":"true"}},[_c('div',{staticClass:"navbar-header"},[_c('ul',{staticClass:"nav navbar-nav flex-row"},[_c('li',{staticClass:"nav-item mr-auto"},[_c('router-link',{staticClass:"navbar-brand",attrs:{"to":"/dashboard"}},[_c('img',{attrs:{"src":"/assets/img/logo-xx.png","alt":"logo","width":"175"}})])],1),_vm._m(0)])]),_c('div',{staticClass:"shadow-bottom"}),_c('div',{staticClass:"main-menu-content"},[_c('ul',{staticClass:"navigation navigation-main",attrs:{"id":"main-menu-navigation","data-menu":"menu-navigation"}},[_c('li',{class:[
          { parent: _vm.user.role == 'Customer' },
          { active: _vm.isDashboardActive },
          { open: _vm.isDashboardActive } ]},[_c('router-link',{staticClass:"menu-link-item",attrs:{"to":"/dashboard"}},[_c('i',{staticClass:"feather icon-grid"}),_c('span',[_vm._v("Dashboard")])]),(_vm.user.role == 'Customer')?_c('ul',{staticClass:"sub-menu"},[_c('li',{class:{ active: _vm.isOrdersSentActive }},[_c('router-link',{attrs:{"to":"/dashboard/orders"}},[_vm._v("My Orders")])],1),_c('li',{class:{ active: _vm.isOrdersCreateActive }},[_c('router-link',{attrs:{"to":"/dashboard/orders/create"}},[_vm._v("New Order")])],1)]):_vm._e()],1),(
          !(_vm.user.type != 'Distributor' || _vm.user.role == 'Customer') &&
          (_vm.hasPermission('Orders_View') || _vm.hasPermission('Orders_Create'))
        )?_c('li',{class:[
          {
            parent:
              _vm.user.role == 'Administrator' ||
              _vm.user.role == 'Support Specialist' ||
              _vm.user.role == 'Sales',
          },
          { active: _vm.isOrdersParentActive },
          { open: _vm.isOrdersActive || _vm.isOrdersCreateAltActive } ]},[_c('router-link',{attrs:{"to":"#"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.handleParentMenuClick('Orders')}}},[_c('i',{staticClass:"feather icon-shopping-cart"}),_c('span',[_vm._v("Orders")])]),(
            _vm.hasPermission('Orders_View') || _vm.hasPermission('Orders_Create')
          )?_c('ul',{staticClass:"menu-content"},[(_vm.hasPermission('Orders_View'))?_c('li',{class:{ active: _vm.isOrdersActive }},[_c('router-link',{attrs:{"to":"/orders"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("  View Orders ")])],1):_vm._e(),(_vm.hasPermission('Orders_Create'))?_c('li',{class:{ active: _vm.isOrdersCreateAltActive }},[_c('router-link',{attrs:{"to":"/orders/create"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("  New Order ")])],1):_vm._e()]):_vm._e()],1):_vm._e(),(
          _vm.user.type == 'Distributor' &&
          _vm.user.role != 'Customer' &&
          _vm.user.role != 'Sales' &&
          _vm.hasPermission('Payment_View')
        )?_c('li',{class:[
          {
            parent:
              _vm.user.role == 'Administrator' ||
              _vm.user.role == 'Support Specialist',
          },
          { active: _vm.isManagePaymentsActive },
          { open: _vm.isManagePaymentsActive } ]},[_c('router-link',{attrs:{"to":"/managepayments"}},[_c('i',{staticClass:"feather icon-credit-card"}),_c('span',[_vm._v("Manage Payments")])])],1):_vm._e(),(
          _vm.user.type == 'Distributor' &&
          _vm.user.role != 'Customer' &&
          _vm.user.role != 'Sales' &&
          _vm.hasPermission('Expense_View')
        )?_c('li',{class:[
          {
            parent:
              _vm.user.role == 'Administrator' ||
              _vm.user.role == 'Support Specialist',
          },
          { active: _vm.isManageExpensesActive },
          { open: _vm.isManageExpensesActive } ]},[_c('router-link',{attrs:{"to":"/manage-expenses"}},[_c('i',{staticClass:"feather icon-credit-card"}),_c('span',[_vm._v("Manage Expenses")])])],1):_vm._e(),(
          !(_vm.user.type != 'Distributor' || _vm.user.role == 'Customer') &&
          _vm.hasPermission('Customer_View')
        )?_c('li',{class:{ active: _vm.isCustomerActive }},[_c('router-link',{attrs:{"to":"/customers"}},[_c('i',{staticClass:"feather icon-users"}),_c('span',[_vm._v("Customers")])])],1):_vm._e(),(_vm.user.type == 'System')?_c('li',{class:[
          'parent',
          { active: _vm.isManufacturersActive },
          { open: _vm.isManufacturersActive } ]},[_c('router-link',{attrs:{"to":"/manufacturers"}},[_c('i',{staticClass:"feather icon-user"}),_c('span',[_vm._v("Manufacturers")])]),_c('ul',{staticClass:"sub-menu"},[_c('li',{class:{ active: _vm.isManufacturersCreateActive }},[_c('router-link',{attrs:{"to":"/manufacturers/create"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("   New Manufacturer")])],1)])],1):_vm._e(),(_vm.user.type == 'System')?_c('li',{class:['parent', { active: _vm.isStatisticsActive }]},[_c('router-link',{attrs:{"to":"/statistics"}},[_c('i',{staticClass:"feather icon-bar-chart"}),_c('span',[_vm._v("Statistics")])])],1):_vm._e(),(_vm.user.type == 'System')?_c('li',{class:[
          'parent',
          { active: _vm.isDistributorsActive },
          { open: _vm.isDistributorsActive } ]},[_c('router-link',{attrs:{"to":"/distributors"}},[_c('i',{staticClass:"icon mdi mdi-truck-fast"}),_c('span',[_vm._v("Distributors")])]),_c('ul',{staticClass:"sub-menu"},[_c('li',{class:{ active: _vm.isDistributorsCreateActive }},[_c('router-link',{attrs:{"to":"/distributors/create"}},[_vm._v("New Distributor")])],1)])],1):_vm._e(),(_vm.user.type == 'Manufacturer')?_c('li',{class:[
          'parent',
          { active: _vm.isManufacturerDistributorsActive },
          { open: _vm.isManufacturerDistributorsCreateActive } ]},[_c('router-link',{attrs:{"to":"/manufacturer-distributors"}},[_c('i',{staticClass:"icon mdi mdi-truck-fast"}),_c('span',[_vm._v("Distributors")])]),_c('ul',{staticClass:"sub-menu"},[_c('li',{class:{ active: _vm.isManufacturerDistributorsCreateActive }},[_c('router-link',{attrs:{"to":"/manufacturer-distributors/create"}},[_vm._v("New Distributor")])],1)])],1):_vm._e(),(_vm.user.type == 'Manufacturer')?_c('li',{class:[
          'parent',
          { active: _vm.isManufacturerGroupActive },
          { open: _vm.isManufacturerGroupActive } ]},[_c('router-link',{attrs:{"to":"/groups"}},[_c('i',{staticClass:"icon mdi mdi-account-group"}),_c('span',[_vm._v("Groups")])])],1):_vm._e(),(
          _vm.user.type == 'Manufacturer' &&
          _vm.user.role != 'Customer' &&
          _vm.user.role != 'Sales'
        )?_c('li',{class:[
          { parent: _vm.user.role == 'Administrator' },
          { active: _vm.isManufacturerProductsActive },
          { open: _vm.isProductCatActive || _vm.isProductUnitActive } ]},[_c('router-link',{attrs:{"to":"/manufacturer-products"}},[_c('i',{staticClass:"feather icon-tag"}),_c('span',[_vm._v("Products")])]),(_vm.user.role == 'Administrator')?_c('ul',{staticClass:"sub-menu"},[_c('li',{class:{ active: _vm.isProductCatActive }},[_c('router-link',{attrs:{"to":"/manufacturer-products/cat/view"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("   Product Categories")])],1),_c('li',{class:{ active: _vm.isProductUnitActive }},[_c('router-link',{attrs:{"to":"/manufacturer-products/units/view"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("   Product Units")])],1)]):_vm._e()],1):_vm._e(),(
          _vm.user.type == 'Distributor' &&
          _vm.user.role != 'Customer' &&
          _vm.user.role != 'Sales' &&
          _vm.hasPermission('Product_View')
        )?_c('li',{class:[
          {
            parent:
              _vm.user.role == 'Administrator' ||
              _vm.user.role == 'Support Specialist',
          },
          { active: _vm.isProductsActive },
          { open: _vm.isProductsActive } ]},[_c('router-link',{attrs:{"to":"/products"}},[_c('i',{staticClass:"feather icon-tag"}),_c('span',[_vm._v("Products")])])],1):_vm._e(),(
          _vm.user.type == 'Distributor' &&
          _vm.user.role != 'Customer' &&
          (_vm.hasPermission('Inventory_View') ||
            _vm.hasPermission('Inventory_Location_View') ||
            _vm.hasPermission('Inventory_SubLocation_View') ||
            _vm.hasPermission('Inventory_Reconcile_Stock_View'))
        )?_c('li',{class:[
          'parent',
          { active: _vm.isManageStockParentActive },
          {
            open:
              _vm.isManageStockActive ||
              _vm.isLocationsActive ||
              _vm.isSubLocationsActive ||
              _vm.isReconcileOrdersToStockActive ||
              _vm.isPurchaseOrderActive,
          } ]},[_c('a',{attrs:{"href":"javascript:void"},on:{"click":function($event){$event.preventDefault();return _vm.handleParentMenuClick('Inventory')}}},[_c('i',{staticClass:"feather icon-book-open"}),_c('span',[_vm._v("Inventory Management")])]),_c('ul',{staticClass:"sub-menu"},[(_vm.hasPermission('Inventory_View'))?_c('li',{class:{ active: _vm.isManageStockActive }},[_c('router-link',{attrs:{"to":"/managestock"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("   Manage Stock")])],1):_vm._e(),(_vm.hasPermission('Inventory_Location_View'))?_c('li',{class:{ active: _vm.isLocationsActive }},[_c('router-link',{attrs:{"to":"/warehouses"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("   Warehouses")])],1):_vm._e(),(_vm.hasPermission('Inventory_SubLocation_View'))?_c('li',{class:{ active: _vm.isSubLocationsActive }},[_c('router-link',{attrs:{"to":"/storage-areas"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("  Storage Areas")])],1):_vm._e(),(_vm.hasPermission('Inventory_Reconcile_Stock_View'))?_c('li',{class:{ active: _vm.isReconcileOrdersToStockActive }},[_c('router-link',{attrs:{"to":"/reconcileorderstostock"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("  Reconcile Orders")])],1):_vm._e(),(_vm.hasPermission('Inventory_Purchase_Order'))?_c('li',{class:{ active: _vm.isPurchaseOrderActive }},[_c('router-link',{attrs:{"to":"/salesorders"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("   Sales Order")])],1):_vm._e()])]):_vm._e(),(
          _vm.user.type == 'Distributor' &&
          _vm.user.role != 'Customer' &&
          (_vm.hasPermission('Campaign_View') || _vm.hasPermission('Campaign_Create'))
        )?_c('li',{class:[
          'parent',
          { active: _vm.isCampaignsParentActive },
          { open: _vm.isCampaignsActive || _vm.isCampaignsCreateActive } ]},[_c('router-link',{attrs:{"to":"#"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.handleParentMenuClick('Campaigns')}}},[_c('i',{staticClass:"feather icon-speaker"}),_c('span',[_vm._v("Campaigns")])]),_c('ul',{staticClass:"sub-menu"},[(_vm.hasPermission('Campaign_View'))?_c('li',{class:{ active: _vm.isCampaignsActive }},[_c('router-link',{attrs:{"to":"/campaigns"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("  View Campaigns")])],1):_vm._e(),(_vm.hasPermission('Campaign_Create'))?_c('li',{class:{ active: _vm.isCampaignsCreateActive }},[_c('router-link',{attrs:{"to":"/campaigns/create"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("  New Campaign")])],1):_vm._e()])],1):_vm._e(),_c('li',{class:[
          'parent',
          { active: _vm.isSettingsActive },
          {
            open:
              _vm.isTeamActive ||
              _vm.isBizProfileActive ||
              _vm.isGroupsActive ||
              _vm.isDiscountsActive ||
              _vm.isBranchesActive ||
              _vm.isSystemSettingsActive ||
              _vm.isExpenseCategoryActive ||
              _vm.isPaymentChannelActive ||
              _vm.isRolesActive ||
              _vm.isManageGeoFenceActive
          } ]},[_c('router-link',{attrs:{"to":"#"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.handleParentMenuClick('Settings')}}},[_c('i',{staticClass:"feather icon-settings"}),_c('span',[_vm._v("Settings")])]),_c('ul',{staticClass:"sub-menu"},[(_vm.hasPermission('User_View'))?_c('li',{class:{ active: _vm.isTeamActive }},[_c('router-link',{attrs:{"to":"/settings/team"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("  Users")])],1):_vm._e(),(
              _vm.user.type != 'System' && _vm.hasPermission('BusinessProfile_View')
            )?_c('li',{class:{ active: _vm.isBizProfileActive }},[_c('router-link',{attrs:{"to":"/settings/business"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("  Business Profile ")])],1):_vm._e(),(_vm.user.type == 'Distributor' && _vm.hasPermission('Group_View'))?_c('li',{class:{ active: _vm.isGroupsActive }},[_c('router-link',{attrs:{"to":"/settings/groups"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("  Groups")])],1):_vm._e(),(
              _vm.user.type == 'Distributor' && _vm.hasPermission('Discount_View')
            )?_c('li',{class:{ active: _vm.isDiscountsActive }},[_c('router-link',{attrs:{"to":"/settings/discounts"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("  Discounts ")])],1):_vm._e(),(_vm.user.type == 'Distributor' && _vm.hasPermission('Branch_View'))?_c('li',{class:{ active: _vm.isBranchesActive }},[_c('router-link',{attrs:{"to":"/settings/branch"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("  Branches")])],1):_vm._e(),(
              _vm.user.type == 'Distributor' &&
              _vm.hasPermission('System_Settings_View')
            )?_c('li',{class:{ active: _vm.isSystemSettingsActive }},[_c('router-link',{attrs:{"to":"/settings/systemsettings"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("  System Settings ")])],1):_vm._e(),(_vm.user.type == 'Manufacturer' && _vm.user.role == 'Administrator')?_c('li',{class:{ active: _vm.isExpenseCategoryActive }},[_c('router-link',{attrs:{"to":"/settings/expenseCategory"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("  Expense Categories ")])],1):_vm._e(),(
              _vm.user.type == 'Distributor' &&
              _vm.hasPermission('Payment_Channels_View')
            )?_c('li',{class:{ active: _vm.isPaymentChannelActive }},[_c('router-link',{attrs:{"to":"/settings/paymentChannel"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("  Payment Channels ")])],1):_vm._e(),(_vm.user.type == 'Distributor' && _vm.hasPermission('Role_View'))?_c('li',{class:{ active: _vm.isRolesActive }},[_c('router-link',{attrs:{"to":"/settings/roles"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("  Role Management ")])],1):_vm._e(),(_vm.user.type == 'Distributor' && _vm.hasPermission('GeoFencing_View'))?_c('li',{class:{ active: _vm.isManageGeoFenceActive }},[_c('router-link',{attrs:{"to":"/settings/geofence"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("  Manage Geo-Fence ")])],1):_vm._e()])],1),(_vm.user.type == 'Manufacturer' && _vm.user.role == 'Administrator')?_c('li',{class:[
          'parent',

          { active: _vm.isPurchaseOrderProcessActive },
          {
            open:
              _vm.isPurchaseOrderItemSetupActive ||
              _vm.isPurchaseOrderStageSetupActive,
          } ]},[_c('router-link',{attrs:{"to":"/sales-order"}},[_c('i',{staticClass:"feather icon-list"}),_c('span',[_vm._v("Sales Order")])]),(_vm.user.role == 'Administrator')?_c('ul',{staticClass:"sub-menu"},[(_vm.user.type == 'Manufacturer' && _vm.user.role == 'Administrator')?_c('li',{class:{ active: _vm.isPurchaseOrderStageSetupActive }},[_c('router-link',{attrs:{"to":"/sales-order/stage/setup"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("  Sales Order Stages ")])],1):_vm._e()]):_vm._e()],1):_vm._e(),(_vm.user.role != 'Customer' && _vm.hasPermission('Report_View'))?_c('li',{class:[
          'parent',
          { active: _vm.isReportsActive },
          { open: _vm.isDistributorReportsActive || _vm.isManufacturerReportsActive } ]},[_c('router-link',{attrs:{"to":"#"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.handleParentMenuClick('Reports')}}},[_c('i',{staticClass:"feather icon-bar-chart-2"}),_c('span',[_vm._v("Reports")])]),_c('ul',{staticClass:"sub-menu"},[(
              _vm.user.type != 'Manufacturer' &&
              _vm.user.role != 'Customer' &&
              _vm.hasPermission('Report_View')
            )?_c('li',{class:[{ active: _vm.isDistributorReportsActive }]},[_c('router-link',{attrs:{"to":"/reports/distributor"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("   Distributor")])],1):_vm._e(),(_vm.user.type == 'Manufacturer' && _vm.user.role == 'Administrator')?_c('li',{class:[{ active: _vm.isManufacturerReportsActive }]},[_c('router-link',{attrs:{"to":"/reports/manufacturer"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("   Manufacturer")])],1):_vm._e()])],1):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"nav-item nav-toggle"},[_c('a',{staticClass:"nav-link modern-nav-toggle pr-0",attrs:{"data-toggle":"collapse"}},[_c('i',{staticClass:"\n              feather\n              icon-x\n              d-block d-xl-none\n              font-medium-4\n              primary\n              toggle-icon\n            "}),_c('i',{staticClass:"\n              toggle-icon\n              feather\n              icon-disc\n              font-medium-4\n              d-none d-xl-block\n              collapse-toggle-icon\n              primary\n            ",attrs:{"data-ticon":"icon-disc"}})])])}]

export { render, staticRenderFns }