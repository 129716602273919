<template>
    <button
        class="btn btn-warning btn-sm btn-rounded btn-space mr-1"
        :disabled="withdrawLoading"
        @click.prevent="onWithDrawClick"
    >
        <span
            v-if="withdrawLoading"
            class="spinner-border spinner-border-sm"
            role="status"
        >
            <i class="sr-only">Loading...</i>
        </span>
        <span v-else> Withdraw </span>
    </button>
</template>

<script>
export default {
    name: "SwitchPurchaseOrder",
    props: ["purchaseOrder"],

    data() {
        return {
            withdrawLoading: false,
        };
    },

    methods: {
        onWithDrawClick() {
            this.$emit("showAction", {
                purchaseNumber: this.purchaseOrder.orderNumber,
                action: "withdraw",
                owner: this.purchaseOrder.id,
            });
        },

        async withdrawPurchase() {
            this.withdrawLoading = true;
            this.isBusy = true;

            const status = "withdrawn";
            try {
                const response = await this.$http.put(
                    `/PurchaseOrders/updateStatus/${this.purchaseOrder.id}/${status}`
                );

                if (response.ok && response.status === 200)
                    this.$emit("onComplete");
                this.withdrawLoading = false;
                this.isBusy = true;
            } catch (error) {
                this.withdrawLoading = false;
                this.isBusy = true;
            }
        },
    },
};
</script>

<style>
</style>