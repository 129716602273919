<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <span>&nbsp;</span>
          <button
                  v-if="hasPermission('Product_Unit_Create')"
            class="btn btn-primary btn-rounded btn-space float-right"
            @click="viewAdd"
          >
            <span class="icon icon-left mdi mdi-plus text-white"></span> New
            item
          </button>
        </div>
        <div class="card-body">
          <div
            class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
          >
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table
                  class="
                    table table-fw-widget
                    dataTable
                    no-footer
                    table-striped table-hover-animation
                  "
                >
                  <thead>
                    <tr>
                      <th style="width: 25%">Name</th>
                      <th style="width: 15%">Status</th>
                      <th style="width: 25%">Item Type</th>
                      <th style="width: 25%">Created</th>
                      <th style="width: 10%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="cat in Categories" :key="cat.id">
                      <td>{{ cat.name }}</td>
                      <td>
                        <span
                          :class="[
                            'badge',
                            ' badge-pill',
                            { 'badge-success': cat.active },
                            { 'badge-danger': !cat.active },
                          ]"
                          >{{ cat.active ? "Active" : "Inactive" }}</span
                        >
                      </td>
                      <td>{{ cat.type }}</td>
                      <td>{{ formatDate(cat.created) }}</td>
                      <td>
                        <div class="text-right">
                          <button
                            v-if="cat.parentId == user.parentId && hasPermission('Product_Unit_Update')"
                            class="btn btn-success btn-sm btn-rounded btn-space"
                            @click.prevent="viewEdit(cat)"
                          >
                            <i class="feather icon-edit"></i> &nbsp;Edit
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="!Categories.length">
                      <td colspan="6">
                        <div class="text-center">No matching records</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">
                  {{ count }} item{{ count != 1 ? "s" : "" }}
                </div>
              </div>
              <div class="col-sm-7">
                <div class="dataTables_paginate paging_simple_numbers">
                  <ul class="pagination">
                    <li
                      :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasPrev },
                      ]"
                    >
                      <a href="#" class="page-link" @click.prevent="prev"
                        >Previous</a
                      >
                    </li>
                    <li
                      :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasNext },
                      ]"
                    >
                      <a href="#" class="page-link" @click.prevent="next"
                        >Next</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-add"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Create new item</b></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Name</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v1.name }]"
                  type="text"
                  v-model="createCategory.name"
                />
                <ul v-if="v1.name" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Item Type</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="createCategory.type"
                >
                  <option v-for="type in types" :key="type" :value="type">
                    {{ type }}
                  </option>
                </select>
                <ul v-if="v1.type" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Active</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <label
                  class="custom-control custom-checkbox custom-control-inline"
                >
                  <input
                    class="custom-control-input"
                    type="checkbox"
                    v-model="createCategory.active"
                  />
                  <span class="custom-control-label">This item is active</span>
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 130px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="create"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Add item</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-edit"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Edit item</b></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
              @click="cancel"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Name</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v2.name }]"
                  type="text"
                  v-model="updateCategory.name"
                />
                <ul v-if="v2.name" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Item Type</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="updateCategory.type"
                >
                  <!-- <select :class="['form-control', { 'is-invalid':v2.type }]" v-model="updateCategory.type"> -->
                  <option v-for="type in types" :key="type" :value="type">
                    {{ type }}
                  </option>
                </select>
                <ul v-if="v2.type" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Active</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <label
                  class="custom-control custom-checkbox custom-control-inline"
                >
                  <input
                    class="custom-control-input"
                    type="checkbox"
                    v-model="updateCategory.active"
                  />
                  <span class="custom-control-label">This item is active</span>
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
              @click="cancel"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="update"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-filter"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h3 class="modal-title">Filter</h3>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body"></div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
            >
              Reset
            </button>
            <button
              style="width: 100px"
              class="btn btn-primary md-close"
              type="button"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import { helper } from "@/helper";
    import Select2 from "@/components/Select2";
    import SystemPermissions from "@/permissions";

    export default {
        mixins: [SystemPermissions.Mixin],
  name: "settingsLists",
  components: {
    Select2,
  },
        created() {
            if (!this.hasPermission("Product_Unit_View")) {
                this.$router.push("/restricted");
            }
    this.getCategories();
    this.getTypes();
  },
  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      createCategory: {
        name: null,
        type: null,
        active: false,
        parentId: null,
      },
      updateCategory: {
        name: null,
        type: null,
        active: false,
        id: null,
      },
      editCategory: {
        name: null,
        type: null,
        active: false,
        id: null,
      },
      v1: {
        name: false,
        type: false,
      },
      v2: {
        name: false,
        type: false,
      },
      Categories: [],
      types: [],
      count: 0,
      page: 0,
      pageSize: 10,
      hasNext: false,
      hasPrev: false,
    };
  },
  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
  },
  methods: {
    cancel() {
      const currentUnit = this.Categories.find(
        (c) => c.id === this.editCategory.id
      );
      currentUnit.name = this.editCategory.name;
      currentUnit.id = this.editCategory.id;
      currentUnit.type = this.editCategory.type;
      currentUnit.active = this.editCategory.active;
      $("#mod-edit").modal("hide");
    },

    viewAdd() {
      this.createCategory = {
        name: null,
        type: null,
        active: false,
        parentId: null,
      };
      this.v1 = {
        name: false,
        type: false,
      };

      $("#mod-add").modal("show");
    },

    viewEdit(c) {
      var editValues = {
        name: c.name,
        type: c.type,
        active: c.active,
        id: c.id,
      };
      this.editCategory = editValues;
      this.updateCategory = { ...c };
      $("#mod-edit").modal("show");
    },

    viewFilter() {
      $("#mod-filter").modal("show");
    },

    create() {
      this.isBusyModal = true;
      this.isBusy = true;

      var data = {
        name: this.createCategory.name,
        type: this.createCategory.type,
        active: this.createCategory.active,
        parentId: this.user.parentId,
      };

      if (this.validateCreate()) {
        $("#mod-add").modal("hide");

        this.$http
          .post("/Categories/create", data)
          .then(function (res) {
            this.isBusyModal = false;
            this.getCategories();
          })
          .catch(function () {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      } else {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    validateCreate() {
      this.v1 = {
        name: false,
        type: false,
      };
      var isValid = true;

      if (!this.createCategory.name) {
        this.v1.name = true;
        isValid = false;
      }

      if (!this.createCategory.type) {
        this.v1.type = true;
        isValid = false;
      }
      return isValid;
    },

    update() {
      this.isBusyModal = true;
      this.isBusy = true;

      var data = {
        name: this.updateCategory.name,
        type: this.updateCategory.type,
        active: this.updateCategory.active,
        id: this.updateCategory.id,
      };

      if (this.validateUpdate()) {
        $("#mod-edit").modal("hide");

        this.$http
          .put("/Categories/update/" + data.id, data)
          .then(function (res) {
            this.isBusyModal = false;
            this.getCategories();
          })
          .catch(function () {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      } else {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    validateUpdate() {
      this.v2 = {
        name: false,
        type: false,
      };
      var isValid = true;

      if (!this.updateCategory.name) {
        this.v2.name = true;
        isValid = false;
      }

      if (!this.updateCategory.type) {
        this.v2.type = true;
        isValid = false;
      }

      return isValid;
    },

    getCategories() {
      this.isBusy = true;
      let type = "Product Unit";

      this.$http
        .get(
          "/Categories/getList?page=" +
            this.page +
            "&pageSize=" +
            this.pageSize +
            "&type=" +
            type +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "")
        )
        .then(function (res) {
          this.Categories = res.body.data;
          this.page = res.body.page;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
        })
        .catch(function () {
          this.isBusy = false;
        });
    },

    getTypes() {
      this.types = ["Product Unit"];
    },

    formatDate(date) {
      return moment(date).format("lll");
    },

    prev() {
      this.page -= 1;
      this.getCategories();
    },

    next() {
      this.page += 1;
      this.getCategories();
    },
  },
};
</script>