<template>
    <div class="row">
        <div class="col-lg-12">
            <div :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]">
                <div class="card-header">
                    <button class="btn btn-success btn-rounded btn-space"
                            @click="viewFilter">
                        <i class="feather icon-filter text-white"></i> Filters
                    </button>
                    <button v-if="hasPermission('Global_View_Payments')" class="btn btn-info btn-rounded float-right "
                            @click.prevent="viewGlobal()">
                        <span>
                            <i class="mdi mdi-earth text-white"></i>
                            Switch to {{isGlobal ? "Default" : "Global" }}
                        </span>
                    </button>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                        <div class="row be-datatable-body">
                            <div class="col-sm-12 table-responsive">
                                <table class="table table-fw-widget dataTable no-footer">
                                    <thead>
                                        <tr>


                                            <th style="width: 10%">Order Number</th>
                                            <th style="width: 10%">Order Amount (₦)</th>
                                            <th style="width: 10%">Paid Amount (₦)</th>
                                            <th style="width: 10%">Order Status</th>
                                            <th style="width: 10%">Payment Channel</th>
                                            <th style="width: 10%" v-if="isGlobal"> Account Manager</th>

                                            <th style="width: 10%">Entry Date</th>
                                            <th style="width: 10%">Payment Date</th>
                                            <th style="width: 10%"> Status</th>
                                            <th style="width: 15%">Payment Update</th>
                                            <th style="width: 15%"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="o in orders" :key="o.id">


                                            <td>
                                                <a href="#" @click.prevent="details(o.id)">
                                                    {{
                          o.orderNumber
                                                    }}
                                                </a>
                                            </td>
                                            <td>{{ formatMoney(o.payment.orderAmount) }}</td>
                                            <td>{{ formatMoney(o.payment.paidAmount) }}</td>
                                            <td>{{ o.status }}</td>
                                            <td>{{ o.source }}</td>
                                            <td v-if="isGlobal">
                                                {{
                          o.introducer
                            ? o.introducer.displayName && o.introducer.staffId
                              ? o.introducer.displayName +
                                "(" +
                                o.introducer.staffId +
                                ")"
                              : !o.introducer.displayName &&
                                o.introducer.staffId
                              ? o.introducer.staffId
                              : o.introducer.displayName &&
                                !o.introducer.staffId
                              ? o.introducer.displayName
                              : o.introducer.staffId
                            : "N/A"
                                                }}
                                            </td>

                                            <td>{{ formatDate(o.created) }}</td>
                                            <td>
                                                {{
                          o.payment.paidAmount > 0
                            ? o.payment.paidOn
                              ? formatDate(o.payment.paidOn)
                              : formatDate(o.created)
                            : "NA"
                                                }}
                                            </td>
                                            <td>{{ o.payment.paymentStatus }}</td>
                                            <td>{{ o.payment.paymentUpdate }}</td>
                                            <td>
                                                <div class="text-right">
                                                    <button v-if="
                              o.payment.paymentUpdate != 'Closed' &&
                              hasPermission('Payment_Post')
                            "
                                                            class="btn btn-success btn-sm btn-rounded btn-space"
                                                            @click.prevent="viewAddFund(o)">
                                                        Post Payment
                                                    </button>&nbsp;
                                                    <button v-if="
                              o.payment.paymentUpdate != 'Closed' &&
                              o.payment.paidAmount &&
                              hasPermission('Payment_Post')
                            "
                                                            class="btn btn-danger btn-sm btn-rounded btn-space"
                                                            @click.prevent="viewRemoveFund(o)">
                                                        Refund
                                                    </button>
                                                </div>
                                            </td>
                                            <td>
                                                <button class="btn btn-outline-primary btn-sm dropdown-toggle"
                                                        type="button"
                                                        id="dropdownMenu2"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false">
                                                    Actions
                                                </button>
                                                <div class="dropdown-menu px-1"
                                                     aria-labelledby="dropdownMenu2">
                                                    <a class="dropdown-item" href="#" @click.prevent="details(o.id)">
                                                        View
                                                    </a>
                                                    <a class="dropdown-item" href="#" @click.prevent="details(o.id)">
                                                        Delete
                                                    </a>
                                                    <a class="dropdown-item" href="#" @click.prevent="details(o.id)">
                                                        Update
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-if="!orders.length">
                                            <td colspan="8">
                                                <div class="text-center" style="padding-top: 50px">
                                                    <span style="font-size: 4.615rem"
                                                          class="mdi mdi-basket"></span>
                                                </div>
                                                <h3 class="text-center" style="padding-bottom: 100px">
                                                    You have no payments.
                                                </h3>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row be-datatable-footer">
                            <div class="col-sm-5">
                                <div class="dataTables_info">
                                    {{ count }} order{{ count != 1 ? "s" : "" }}
                                </div>
                            </div>
                            <div class="col-sm-7">
                                <div class="dataTables_paginate paging_simple_numbers">
                                    <ul class="pagination">
                                        <li :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasPrev },
                      ]">
                                            <a href="#" class="page-link" @click.prevent="prev">Previous</a>
                                        </li>
                                        <li :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasNext },
                      ]">
                                            <a href="#" class="page-link" @click.prevent="next">Next</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="be-spinner">
                    <svg width="40px" height="40px" viewBox="0 0 66 66">
                        <circle class="circle"
                                fill="none"
                                stroke-width="4"
                                stroke-linecap="round"
                                cx="33"
                                cy="33"
                                r="30" />
                    </svg>
                </div>
            </div>
        </div>

        <div class="modal fade colored-header colored-header-primary"
             id="mod-manage-payment-add"
             role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header modal-header-colored">
                        <h4 class="modal-title p-1">
                            <strong>Manage payment for order # {{ fOrder.orderNumber }}</strong>
                        </h4>
                        <button class="close md-close"
                                type="button"
                                data-dismiss="modal"
                                aria-hidden="true">
                            <span class="mdi mdi-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group row" v-if="!v1.is_valid">
                            <div class="col-12 col-sm-12 col-lg-12">
                                <label class="col-12 col-sm-12 text-sm-center alert alert-danger">{{ v1.text }}</label>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-12 col-sm-4 col-form-label text-sm-right">Payment Channel</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <select class="form-control"
                                        placeholder="Select an option"
                                        v-model="fOrder.paymentChannelId">
                                    <option v-for="br in paymentChannels"
                                            :key="br.id"
                                            :value="br.id">
                                        {{ prepareDetail(br) }}
                                    </option>
                                </select>
                                <ul v-if="v1.paymentChannelId"
                                    class="parsley-errors-list filled">
                                    <li class="parsley-required">Required.</li>
                                </ul>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-12 col-sm-4 col-form-label text-sm-right">Amount Paid</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <input :class="['form-control', { 'is-invalid': v1.amountPaid }]"
                                       type="number"
                                       placeholder="Enter amount paid"
                                       v-model="fOrder.amountPaid" />
                                <ul v-if="v1.amountPaid" class="parsley-errors-list filled">
                                    <li class="parsley-required">Required.</li>
                                </ul>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-12 col-sm-4 col-form-label text-sm-right">Notes</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <textarea :class="['form-control', { 'is-invalid': v1.notes }]"
                                          v-model="fOrder.notes"></textarea>
                                <ul v-if="v1.notes" class="parsley-errors-list filled">
                                    <li class="parsley-required">Required.</li>
                                </ul>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-12 col-sm-4 col-form-label text-sm-right">Payment Date</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <input :class="['form-control', { 'is-invalid': v1.paymentDate }]"
                                       type="date"
                                       :max="max"
                                       placeholder="Select a date"
                                       v-model="fOrder.paymentDate" />
                                <ul v-if="v1.paymentDate" class="parsley-errors-list filled">
                                    <li class="parsley-required">Required.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button style="width: 100px"
                                class="btn btn-secondary md-close"
                                type="button"
                                data-dismiss="modal">
                            Cancel
                        </button>
                        <button style="width: 100px"
                                class="btn btn-primary"
                                type="button"
                                @click.prevent="managePayment_add">
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade colored-header colored-header-primary"
             id="mod-global"
             role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="text-center">
                            <!-- <div class="text-warning"><span class="modal-main-icon mdi mdi-alert"></span></div> -->
                            <h3 class="p-1">Confirmation</h3>
                            <p>
                                Do you want to switch to {{isGlobal ? "Default" : "Global" }} View?

                            </p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button style="width: 100px"
                                class="btn btn-secondary"
                                type="button"
                                data-dismiss="modal">
                            Cancel
                        </button>
                        <button style="width: 140px"
                                :disabled="isBusyModal"
                                class="btn btn-warning"
                                type="button"
                                @click.prevent="toggleGlobal">
                            <span v-if="isBusyModal">Busy...</span>
                            <span v-else>Yes, proceed</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade colored-header colored-header-primary"
             id="mod-manage-payment-remove"
             role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header modal-header-colored">
                        <h4 class="modal-title p-1">
                            <strong>Manage payment for order # {{ fOrder.orderNumber }}</strong>
                        </h4>
                        <button class="close md-close"
                                type="button"
                                data-dismiss="modal"
                                aria-hidden="true">
                            <span class="mdi mdi-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group row" v-if="!v1.is_valid">
                            <div class="col-12 col-sm-12 col-lg-12 alert">
                                <label class="col-12 col-sm-12 text-sm-center alert alert-danger">{{ v1.text }}</label>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-12 col-sm-4 col-form-label text-sm-right">Refund Amount</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <input :class="['form-control', { 'is-invalid': v1.amountPaid }]"
                                       type="number"
                                       placeholder="Enter refund amount"
                                       v-model="fOrder.amountPaid" />
                                <ul v-if="v1.amountPaid" class="parsley-errors-list filled">
                                    <li class="parsley-required">Required.</li>
                                </ul>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-12 col-sm-4 col-form-label text-sm-right">Notes</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <textarea :class="['form-control', { 'is-invalid': v1.notes }]"
                                          v-model="fOrder.notes"></textarea>
                                <ul v-if="v1.notes" class="parsley-errors-list filled">
                                    <li class="parsley-required">Required.</li>
                                </ul>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-12 col-sm-4 col-form-label text-sm-right">Refund Date</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <input :class="['form-control', { 'is-invalid': v1.paymentDate }]"
                                       type="date"
                                       placeholder="Select a date"
                                       v-model="fOrder.paymentDate" />
                                <ul v-if="v1.paymentDate" class="parsley-errors-list filled">
                                    <li class="parsley-required">Required.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button style="width: 100px"
                                class="btn btn-secondary md-close"
                                type="button"
                                data-dismiss="modal">
                            Cancel
                        </button>
                        <button style="width: 100px"
                                class="btn btn-primary"
                                type="button"
                                @click.prevent="managePayment_remove">
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade colored-header colored-header-primary"
             id="mod-filter"
             role="dialog"
             tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header modal-header-colored">
                        <h4 class="modal-title p-1"><strong>Apply Filter</strong></h4>
                        <button class="close md-close"
                                type="button"
                                data-dismiss="modal"
                                aria-hidden="true">
                            <span class="mdi mdi-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group row">
                            <label class="col-12 col-sm-4 col-form-label text-sm-right">Payment Status</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <select class="form-control"
                                        placeholder="Select an option"
                                        v-model="filter.paymentStatus">
                                    <option value="0">All Orders</option>
                                    <option value="PAID">Paid</option>
                                    <option value="PARTLY PAID">Partly Paid</option>
                                    <option value="NOT PAID">Not Paid</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-12 col-sm-4 col-form-label text-sm-right">Payment Update</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <select class="form-control"
                                        placeholder="Select an option"
                                        v-model="filter.paymentUpdate">
                                    <option value="0">All Orders</option>
                                    <option value="Open">Open</option>
                                    <option value="Excess">Excess</option>
                                    <option value="Shortfall">Shortfall</option>
                                    <option value="Closed">Closed</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-12 col-sm-4 col-form-label text-sm-right">Date range</label>
                            <div class="col-12 col-sm-4 col-lg-4">
                                <input type="date"
                                       class="form-control"
                                       placeholder="Select a date"
                                       v-model="filter.dateStart" />
                            </div>
                            <div class="col-12 col-sm-4 col-lg-4">
                                <input type="date"
                                       class="form-control"
                                       placeholder="Select a date"
                                       v-model="filter.dateEnd" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-12 col-sm-4 col-form-label text-sm-right">Customer</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <select class="form-control"
                                        placeholder="Select a customer"
                                        v-model="filter.buyerId">
                                    <option v-for="c in customers" :key="c.id" :value="c.id">
                                        {{ c.text }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-12 col-sm-4 col-form-label text-sm-right">Other filters</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <input class="form-control"
                                       type="text"
                                       placeholder="Filter by order number..."
                                       v-model="filter.orderNumber" />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button style="width: 100px"
                                class="btn btn-secondary md-close"
                                type="button"
                                data-dismiss="modal">
                            Cancel
                        </button>
                        <button style="width: 100px"
                                class="btn btn-secondary md-close"
                                type="button"
                                @click="reset">
                            Reset
                        </button>
                        <button style="width: 100px"
                                class="btn btn-primary md-close"
                                type="button"
                                @click="getOrders">
                            Search
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    /* eslint-disable */

    import moment from "moment";
    import { helper } from "@/helper";
    import Select2 from "@/components/Select2";
    import SystemPermissions from "@/permissions";
    // import DatePicker from "vue-bootstrap-datetimepicker";

    export default {
        mixins: [SystemPermissions.Mixin],
        name: "managePayment",
        components: {
            Select2,
            // DatePicker
        },
        created() {
            if (!this.hasPermission("Payment_View")) {
                this.$router.push("/restricted");
            }

            var user = this.$store.state.currentUser;
            var preferredView = this.$auth.getPreferredViewForPayment(user.id);
            if (preferredView) {
                if (preferredView == 'global')
                    this.isGlobal = true;
                else if (preferredView == 'default')
                    this.isGlobal = false;
            }

            this.getCustomers();
            this.getOrders();
            this.getPaymentChannels();
        },

        data() {
            return {
                max: new Date().toISOString().split("T")[0],
                filter: {
                    paymentStatus: "0",
                    paymentUpdate: "0",
                    orderNumber: "",
                    dateStart: moment().subtract(13, "days").format("YYYY-MM-DD"),
                    dateEnd: moment().format("YYYY-MM-DD"),
                    buyerId: "",
                },

                customers: [],
                isBusy: false,
                isGlobal: false,
                isBusyModal: false,
                fOrder: {},
                v1: {
                    amountPaid: false,
                    notes: false,
                    paymentChannelId: false,
                    paymentDate: false,
                    is_valid: true,
                    text: "",
                },

                orders: [],
                paymentChannels: [],
                count: 0,
                page: 0,
                pageSize: 10,
                hasNext: false,
                hasPrev: false,
                options: {
                    format: "YYYY-MM-DD",
                    icons: {
                        time: "mdi mdi-time",
                        date: "mdi mdi-calendar",
                        up: "mdi mdi-chevron-up",
                        down: "mdi mdi-chevron-down",
                        previous: "mdi mdi-chevron-left",
                        next: "mdi mdi-chevron-right",
                        today: "mdi mdi-screenshot",
                        clear: "mdi mdi-trash",
                        close: "mdi mdi-remove",
                    },
                },

                local: {
                    dow: 0, // Sunday is the first day of the week
                    hourTip: "Select Hour", // tip of select hour
                    minuteTip: "Select Minute", // tip of select minute
                    secondTip: "Select Second", // tip of select second
                    yearSuffix: "", // suffix of head year
                    monthsHead:
                        "January_February_March_April_May_June_July_August_September_October_November_December".split(
                            "_"
                        ), // months of head
                    months: "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"), // months of panel
                    weeks: "Su_Mo_Tu_We_Th_Fr_Sa".split("_"), // weeks
                    cancelTip: "cancel",
                    submitTip: "confirm",
                },
            };
        },

        computed: {
            user: function () {
                return this.$store.state.currentUser;
            },
        },

        methods: {
            async getCustomers() {
                await this.$http
                    .get("/Users/getItemListOfCustomers/" + this.user.parentId)
                    .then(function (res) {
                        this.customers = res.body;
                    })
                    .catch(function () { });
            },

            prepareDetail(value) {
                const name = value.paymentChannelTypeName;
                let result = "";
                if (name) {
                    result += name;

                    if (name.toLowerCase() === "bank") {
                        result += "/ " + value.bankName + "/ " + value.accountNumber;
                    } else if (name.toLowerCase() === "pos") {
                        result += "/ " + value.bankName + "/ " + value.terminalId;
                    } else if (name.toLowerCase() === "cryptocurrency") {
                        result += "/ " + value.bankName + "/ " + value.walletAddress;
                    }
                }
                return result;
            },

            reset() {
                this.filter = {
                    paymentStatus: "0",
                    paymentUpdate: "0",
                    orderNumber: "",
                    dateStart: moment().subtract(13, "days").format("YYYY-MM-DD"),
                    dateEnd: moment().format("YYYY-MM-DD"),
                    buyerId: "",
                };
            },

            viewAddFund(o) {
                this.v1 = {
                    notes: false,
                    amountPaid: false,
                    is_valid: true,
                    text: "",
                };

                this.fOrder = {
                    orderId: o.id,
                    orderNumber: o.orderNumber,
                    amountPaid: null,
                    notes: null,
                    paymentChannelId: null,
                    paymentDate: moment().format("YYYY-MM-DD"),
                    orderAmount: o.payment.orderAmount,
                    paidAmount: o.payment.paidAmount,
                };

                $("#mod-manage-payment-add").modal("show");
            },

            viewRemoveFund(o) {
                this.v1 = {
                    notes: false,
                    amountPaid: false,
                    is_valid: true,
                    text: "",
                };

                this.fOrder = {
                    orderId: o.id,
                    orderNumber: o.orderNumber,
                    amountPaid: null,
                    notes: null,
                    orderAmount: o.payment.orderAmount,
                    paidAmount: o.payment.paidAmount,
                };

                $("#mod-manage-payment-remove").modal("show");
            },

            viewExport() {
                // $("#mod-export").modal("show");
            },

            viewFilter() {
                $("#mod-filter").modal("show");
            },

            details(id) {
                this.$router.push("/orders/" + id + "/mp");
            },

            async getOrders() {
                this.isBusy = true;

                await this.$http
                    .get(
                        "/Orders/getPaymentList?page=" +
                        this.page +
                        "&pageSize=" +
                        this.pageSize +
                        (this.filter.orderNumber != ""
                            ? "&orderNumber=" + this.filter.orderNumber
                            : "") +
                        (this.filter.paymentStatus != "0"
                            ? "&paymentStatus=" + this.filter.paymentStatus
                            : "") +
                        (this.filter.paymentUpdate != "0"
                            ? "&paymentUpdate=" + this.filter.paymentUpdate
                            : "") +
                        (this.filter.buyerId != "0"
                            ? "&makerId=" + this.filter.buyerId
                            : "") +
                        (this.filter.dateStart != ""
                            ? "&dateStart=" +
                            moment(this.filter.dateStart).format("YYYY-MM-DD 00:00:00")
                            : "") +
                        (this.filter.dateEnd != ""
                            ? "&dateEnd=" +
                            moment(this.filter.dateEnd).format("YYYY-MM-DD 23:59:59")
                            : "") +
                        "&sellerId=" + this.user.parentId +
                        "&showForMe=" + !this.isGlobal
                    )
                    .then(function (res) {
                        this.orders = res.body.data;
                        this.page = res.body.page;
                        this.pageSize = res.body.pageSize;
                        this.count = res.body.count;
                        this.hasPrev = res.body.hasPrev;
                        this.hasNext = res.body.hasNext;
                        this.isBusy = false;
                    })
                    .catch(function () { });

                $("#mod-filter").modal("hide");
                this.isBusy = false;
            },

            async managePayment_add() {
                this.isBusyModal = true;
                this.isBusy = true;

                if (this.validatePayment_Add()) {
                    $("#mod-manage-payment-add").modal("hide");

                    await this.$http
                        .post("/Orders/updatePayment", this.fOrder)
                        .then(function (res) {
                            this.isBusyModal = false;
                            this.getOrders();
                        })
                        .catch(function () {
                            this.isBusy = false;
                            this.isBusyModal = false;
                        });
                } else {
                    this.isBusy = false;
                    this.isBusyModal = false;
                }
            },

            async managePayment_remove() {
                this.isBusyModal = true;
                this.isBusy = true;

                if (this.validatePayment_Refund()) {
                    $("#mod-manage-payment-remove").modal("hide");

                    this.fOrder.amountPaid = this.fOrder.amountPaid * -1;

                    await this.$http
                        .post("/Orders/updatePayment", this.fOrder)
                        .then(function (res) {
                            this.isBusyModal = false;
                            this.getOrders();
                        })
                        .catch(function () {
                            this.isBusy = false;
                            this.isBusyModal = false;
                        });
                } else {
                    this.isBusy = false;
                    this.isBusyModal = false;
                }
            },

            validatePayment_Add() {
                this.v1 = {
                    notes: false,
                    amountPaid: false,
                    paymentDate: false,
                    paymentChannelId: false,
                    is_valid: true,
                    text: "",
                };
                var isValid = true;

                if (!this.fOrder.amountPaid) {
                    this.v1.amountPaid = true;
                    isValid = false;
                }

                if (!this.fOrder.notes) {
                    this.v1.notes = true;
                    isValid = false;
                }

                if (!this.fOrder.paymentDate) {
                    this.v1.paymentDate = true;
                    isValid = false;
                }

                if (!this.fOrder.paymentChannelId) {
                    this.v1.paymentChannelId = true;
                    isValid = false;
                }

                if (this.fOrder.amountPaid <= 0) {
                    this.v1.is_valid = false;
                    this.v1.text = "Payment amount must be greater than 0";
                    isValid = false;
                }

                return isValid;
            },

            validatePayment_Refund() {
                this.v1 = {
                    notes: false,
                    amountPaid: false,
                    paymentDate: false,
                    is_valid: true,
                    text: "",
                };
                var isValid = true;

                if (!this.fOrder.amountPaid) {
                    this.v1.amountPaid = true;
                    isValid = false;
                }

                if (!this.fOrder.notes) {
                    this.v1.notes = true;
                    isValid = false;
                }

                if (!this.fOrder.paymentDate) {
                    this.v1.paymentDate = true;
                    isValid = false;
                }

                if (this.fOrder.amountPaid <= 0) {
                    this.v1.is_valid = false;
                    this.v1.text = "Refund amount must be greater than 0";
                    isValid = false;
                }

                if (this.fOrder.amountPaid > this.fOrder.paidAmount) {
                    this.v1.is_valid = false;
                    this.v1.text = "Refund amount cannot be more than amount paid";
                    isValid = false;
                }

                if (this.fOrder.paidAmount > this.fOrder.orderAmount) {
                    // this is an excess trx. Refund only excess amount
                    var excessAmount = this.fOrder.paidAmount - this.fOrder.orderAmount;
                    if (this.fOrder.amountPaid > excessAmount) {
                        this.v1.is_valid = false;
                        this.v1.text =
                            "Refund amount cannot be more than amount paid. You can only refund a maximum of NGN" +
                            this.formatMoney(excessAmount);
                        isValid = false;
                    }
                }

                return isValid;
            },

            async getPaymentChannels() {
                this.isBusy = true;

                await this.$http
                    .get(
                        "/PaymentChannels/getListNoPaging?active=true" +
                        (this.user.parentId ? "&parentId=" + this.user.parentId : "")
                    )
                    .then(function (res) {
                        console.log(res.body);
                        this.paymentChannels = res.body;
                        this.isBusy = false;
                    })
                    .catch(function () { });

                this.isBusy = false;
            },

            formatDate(date) {
                return moment(date).format("ll");
            },

            prev() {
                this.page -= 1;
                this.getOrders();
            },
            viewGlobal() {
                $("#mod-global").modal("show");
            },
            toggleGlobal() {
                $("#mod-global").modal("hide");
                this.isGlobal = !this.isGlobal;

                this.$auth.setPreferredViewForPayment(this.isGlobal ? "global" : "default", this.user.id);

                this.getOrders();
            },
            next() {
                this.page += 1;
                this.getOrders();
            },

            formatMoney(val) {
                return helper.formatMoney(val);
            },

            goToPayment(id) {
                this.$router.push("/payments/" + id);
            },
        },
    };
</script>