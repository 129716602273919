<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-purchase-order-filter"
    role="dialog"
  >
    <div class="modal-dialog">
      <form @submit.prevent="submit" class="modal-content">
        <div class="modal-header modal-header-colored">
          <h4 class="modal-title p-1"><b>Filter</b></h4>
          <button
            class="close md-close"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-md-4 col-form-label text-sm-right"
              >Start Date</label
            >

            <div class="col-12 col-sm-8 col-lg-7">
              <date-picker
              @input="setStartDate"
                class="form-control"
                :value="filter.startDate"
                :config="options"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-md-4 col-form-label text-sm-right"
              >End Date</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <date-picker
              @input="setEndDate"
                class="form-control"
                :value="filter.endDate"
                :config="endOptions"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-md-4 col-form-label text-sm-right"
              >Customer Name</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <input
              @input="setCustomerName($event.target.value)"
                class="form-control"
                type="text"
                placeholder="Filter by name"
                :value="filter.customerName"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-md-4 col-form-label text-sm-right"
              >PO Number</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <input
              @input="setOrderNumber($event.target.value)"
                class="form-control"
                type="text"
                placeholder="Filter by PO Number"
                :value="filter.poNumber"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-md-4 col-form-label text-sm-right"
              >Stage</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <select
              @input="setStage($event.target.value)"
                class="custom-select custom-select-md"
                :value="filter.selectedStage"
              >
                <option value="">All Stage</option>
                <option v-for="p in orderStages" :key="p.id" :value="p.id">
                  {{ p.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-md-4 col-form-label text-sm-right"
              >Read Status</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <select
              @input="setReadStatus($event.target.value)"
                class="form-control"
                :value="filter.readStatus"
                placeholder="Select an option"
              >
                <option value=""></option>
                <option
                  v-for="option in readItems"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Group</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <select
              @input="setGroup($event.target.value)"
                class="form-control"
                placeholder="Select an option"
                :value="filter.group"
              >
                <option v-for="g in groups" :key="g.id" :value="g.id">
                  {{ g.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-md-4 col-form-label text-sm-right"
              >Download Status</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <select
                class="form-control"
                v-model="filter.downloadStatus"
                placeholder="Select an option"
              >
                <option value=""></option>
                <option
                  v-for="option in downloadItems"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" @click="clearFilter" type="button">
            Clear
          </button>
          <button class="btn btn-danger" type="button" data-dismiss="modal">
            Cancel
          </button>
          <button class="btn btn-primary" type="submit">Submit</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import datePicker from "vue-bootstrap-datetimepicker";
import {mapState} from "vuex";
export default {
  name: "ManufacturerFilterPurchaseOrder",
  components: {
    datePicker,
  },

  data() {
    return {
      groups: [],
      orderStages: [],
      showDeactivated: "",

      options: {
        format: "YYYY-MM-DD",
        useCurrent: true,
        max: this.maxDate,
      },

      endOptions: {
        format: "YYYY-MM-DD",
        useCurrent: true,
      },

      downloadItems: [
        { name: "YES", value: true },
        { name: "NO", value: false },
      ],

      readItems: [
        { name: "READ", value: true },
        { name: "NOT READ", value: false },
      ],
    };
  },

  created() {
    this. setDateTime();
    this.getOrderStages();
  },

  mounted() {    
    this.getGroups();
  },

  computed: {
    ...mapState(["filter"]),
    maxDate() {
      return this.filter.startDate;
    },
   
  },

  methods: {
    setStartDate(date) {
      this.filter.startDate = date;
    },
    setEndDate(date) {
      this.filter.endDate = date;
      this.updateFilter(this.filter);
    },
    setCustomerName(date) {
      this.filter.customerName = date;
      this.updateFilter(this.filter);
    },
    setOrderNumber(number) {
      this.filter.poNumber = number;
      this.updateFilter(this.filter);
    },
    setReadStatus(status) {
      this.filter.readStatus = status;
      this.updateFilter(this.filter);
    },
    setStage(stage) {
      this.filter.selectedStage = stage;
      this.updateFilter(this.filter);
    },
    setDownloadStatus(download) {
      this.filter.downloadStatus = download;
      this.updateFilter(this.filter);
    },
    setGroup(group) {
      this.filter.group = group;
      this.updateFilter(this.filter);
    },
    updateFilter(filter) {
      this.$store.commit("updateFilter", filter);
    },
    clearFilter() {
      this.$store.commit("clearFilter");
      this.$emit("onClear");
      this.setDateTime();
    },
    async getOrderStages() {
      try {
        const response = await this.$http.get(
          "/PurchaseOrders/orderStage/getList?active=true"
        );

        if (response.ok && response.status === 200) {
          this.orderStages = response.body.sort(
            (firstItem, secondItem) => firstItem.stage - secondItem.stage
          );
        }
      } catch (error) {}
    },

    dateTimeValue() {
      const now = new Date();
      const utcString = now.toISOString().substring(0, 19);
      const year = now.getFullYear();
      const month = now.getMonth() + 1;
      const day = now.getDate();
      const hour = now.getHours();
      const minute = now.getMinutes();
      const localDatetime =
        year +
        "-" +
        (month < 10 ? "0" + month.toString() : month) +
        "-" +
        (day < 10 ? "0" + day.toString() : day) +
        "T" +
        (hour < 10 ? "0" + hour.toString() : hour) +
        ":" +
        (minute < 10 ? "0" + minute.toString() : minute) +
        utcString.substring(16, 19);

      return localDatetime;
    },

    setDateTime() {
      this.filter.startDate = this.filter.endDate = this.dateTimeValue();
    },

    submit() {
      this.$emit("onFilter", {startDate: this.filter.startDate, endDate: this.filter.endDate});
    },

    async getGroups() {
      try {
        const response = await this.$http.get(
          `/Groups/getListNoPaging?active=${this.showDeactivated}`
        );

        if (response.ok && response.status === 200) {
          this.groups = response.body;
        }
      } catch (error) {}
    },
  },
};
</script>

<style>
</style>