import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		currentUser: {},
		prevRoute: null,
		userId: {},
		roles: [],
		salesRoles: [],
		purchaseOrders: {
			purchaseOrders: [],
			count: 0,
			pageNumber: 0,
			pageSize: 10,
			hasNext: false,
			hasPrev: false,
		},

		dates: {
			startDate: "",
			endDate: "",
		},
		filter: {
			startDate: "",
			endDate: "",
			customerName: "",
			poNumber: "",
			readStatus: "",
			selectedStage: "",
			downloadStatus: "",
			group: "",
		},
	},

	getters: {
		getCurrentUser: (state) => state.currentUser,
		getPrevRoute: (state) => state.prevRoute,
		getUserId: (state) => state.userId,
		purchaseOrders: (state) => {
			return state.purchaseOrders;
		},
		roles: (state) => state.roles,
		salesRoles: (state) => state.salesRoles,
	},

	actions: {
		setCurrentUser: function(context, user) {
			context.commit("setCurrentUser", user);
		},

		clearCurrentUser: function(context) {
			context.commit("clearCurrentUser");
		},

		setPrevRoute: function(context, rout) {
			context.commit("setPrevRoute", rout);
		},

		setUserId: function(context, userId) {
			context.commit("setUserId", userId);
		},

		getPurchaseOrder({ commit, state }, type) {
			Vue.http
				.get(
					`/PurchaseOrders/${type}/getList?CustomerName=${state.filter.customerName}&StartDate=${state.dates.startDate}&EndDate=${state.dates.endDate}&DownloadStatus=${state.filter.downloadStatus}&PurchaseOrderNumber=${state.filter.poNumber}&Stage=${state.filter.selectedStage}&Read=${state.filter.readStatus}&PageNumber=${state.purchaseOrders.pageNumber}&PageSize=${state.purchaseOrders.pageSize}&Group=${state.filter.group}`
				)
				.then((response) => commit("setPurchaseOrders", response.data))
				.catch((error) => commit("setPurchaseOrdersError", error));
		},

		getRoles({ commit, state }) {
			Vue.http
				.get(
					`/roles/getRoleListNoPaging?parentId=${state.currentUser.parentId}&roleType=${state.currentUser.type}`
				)
				.then((response) => commit("setRoles", response.body));
		},

		getSalesRoles({ commit, state }) {
			Vue.http
				.get(`/salesroles/getRoleListNoPaging?parentId=${state.currentUser.parentId}`)
				.then((response) => commit("setSalesRole", response.body));
		},
	},

	mutations: {
		setCurrentUser: function(state, user) {
			state.currentUser = user;
		},

		clearCurrentUser: function(state) {
			state.currentUser = {};
		},

		setPrevRoute: function(state, rout) {
			state.prevRoute = rout;
		},

		setUserId: function(state, userId) {
			state.userId = userId;
		},

		setRoles(state, roles) {
			state.roles = roles;
		},

		setSalesRole(state, salesRoles) {
			state.salesRoles = salesRoles;
		},

		setPurchaseOrders(state, purchaseOrders) {
			state.purchaseOrders.purchaseOrders = purchaseOrders.data;
			state.purchaseOrders.pageNumber = purchaseOrders.pageNumber;
			state.purchaseOrders.pageSize = purchaseOrders.pageSize;
			state.purchaseOrders.count = purchaseOrders.count;
			state.purchaseOrders.hasPrev = purchaseOrders.hasPrev;
			state.purchaseOrders.hasNext = purchaseOrders.hasNext;
		},

		setPurchaseOrdersError(state, error) {
			state.purchaseOrders.error = error;
		},

		updateFilter(state, filter) {
			state.filter = filter;
		},

		clearFilter(state) {
			state.filter = {
				startDate: "",
				endDate: "",
				customerName: "",
				poNumber: "",
				readStatus: "",
				selectedStage: "",
				downloadStatus: "",
				group: "",
			};
			state.dates = {
				startDate: "",
				endDate: "",
			};
		},
		setDate(state, date) {
			state.dates = date;
		},
	},
});
