<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <button
            class="btn btn-success btn-rounded btn-space"
            @click="viewFilter"
          >
            <span class="feather icon-filter text-white"></span> Filters
          </button>

          <!-- <button class="btn btn-secondary btn-rounded btn-space float-right" @click="viewExport">
                      <span class="icon icon-left mdi mdi-arrow-top-right text-primary"></span> Export
                    </button>-->
        </div>
        <div class="card-body">
          <div
            class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
          >
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table
                  class="
                    table table-fw-widget
                    dataTable
                    no-footer
                    table-striped table-hover-animation table-bordered
                  "
                >
                  <thead>
                    <tr>
                      <th style="width: 35%">Name</th>
                      <th style="width: 10%">Status</th>
                      <th style="width: 20%">Parent</th>
                      <th style="width: 15%">Created</th>
                      <th style="width: 20%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="c in campaigns" :key="c.id">
                      <td>
                        <a href="#" @click.prevent="details(c.id)">{{
                          c.name
                        }}</a>
                      </td>
                      <td>
                        <span
                          :class="[
                            'badge',
                            ' badge-pill',
                            { 'badge-success': c.active },
                            { 'badge-danger': !c.active },
                          ]"
                        >
                          {{ c.active ? "Active" : "Inactive" }}
                        </span>
                      </td>
                      <td>{{ c.parent ? c.parent.name : "N/A" }}</td>
                      <td>{{ formatDate(c.created) }}</td>
                      <td>
                        <div
                          class="text-right"
                          v-if="hasPermission('Campaign_Delete')"
                        >
                          <button
                            class="btn btn-success btn-sm btn-rounded btn-space"
                            @click.prevent="viewRemove(c)"
                          >
                            {{ c.active ? "Disable" : "Enable" }}</button
                          >&nbsp;
                          <button
                            v-if="hasPermission('Campaign_View_Members')"
                            class="btn btn-primary btn-sm btn-rounded btn-space"
                            @click.prevent="viewMembers(c)"
                          >
                            {{ c.memberCount }} member{{
                              c.memberCount != 1 ? "s" : ""
                            }}
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="!campaigns.length">
                      <td colspan="5">
                        <div class="text-center" style="padding-top: 25px">
                          <span
                            style="font-size: 4.615rem"
                            class="mdi mdi-bullhorn"
                          ></span>
                        </div>
                        <h3 class="text-center" style="padding-bottom: 50px">
                          You don't have any campaigns.
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">
                  {{ count }} campaign{{ count != 1 ? "s" : "" }}
                </div>
              </div>
              <div class="col-sm-7">
                <div class="dataTables_paginate paging_simple_numbers">
                  <ul class="pagination">
                    <li
                      :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasPrev },
                      ]"
                    >
                      <a href="#" class="page-link" @click.prevent="prev"
                        >Previous</a
                      >
                    </li>
                    <li
                      :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasNext },
                      ]"
                    >
                      <a href="#" class="page-link" @click.prevent="next"
                        >Next</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-delete"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-center">
              <!-- <div class="text-warning"><span class="modal-main-icon mdi mdi-alert"></span></div> -->
              <h3 class="p-1">Confirmation</h3>
              <p>
                Are you sure you want to
                {{ deletingCampaign.active ? "disable" : "enable" }} this
                campaign:<br />
                <strong>{{ deletingCampaign.name }}</strong
                >?
              </p>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 140px"
              :disabled="isBusyModal"
              class="btn btn-warning"
              type="button"
              @click.prevent="updateStatus"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Yes, proceed</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-filter"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h3 class="modal-title">Filter</h3>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Status</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="filter.status"
                >
                  <option value="0">All Campaigns</option>
                  <option value="True">Active</option>
                  <option value="False">Disabled</option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              @click="reset"
            >
              Reset
            </button>
            <button
              style="width: 100px"
              class="btn btn-primary md-close"
              type="button"
              @click="getCampaigns"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import Select2 from "@/components/Select2";
import SystemPermissions from "@/permissions";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "campaigns",
  components: {
    Select2,
  },
  created() {
    if (!this.hasPermission("Campaign_View")) {
      this.$router.push("/restricted");
    }
    this.getCampaigns();
  },
  data() {
    return {
      filter: {
        status: "0",
      },
      deletingCampaign: {},
      isBusy: false,
      isBusyModal: false,
      campaigns: [],
      count: 0,
      page: 0,
      pageSize: 10,
      hasNext: false,
      hasPrev: false,
    };
  },
  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
  },
  methods: {
    reset() {
      this.filter = {
        status: "0",
      };
    },
    viewExport() {
      //   $("#mod-export").modal("show");
    },
    viewFilter() {
      $("#mod-filter").modal("show");
    },
    viewRemove(c) {
      this.deletingCampaign = c;
      $("#mod-delete").modal("show");
    },
    viewMembers(c) {
      this.$router.push("/campaigns/" + c.id + "/members");
    },
    details(id) {
      this.$router.push("/campaigns/" + id);
    },
    updateStatus() {
      this.isBusyModal = true;
      let data = {
        id: this.deletingCampaign.id,
        active: !this.deletingCampaign.active,
      };

      $("#mod-delete").modal("hide");

      this.$http
        .put("/Campaigns/updateStatus/" + data.id, data)
        .then(function (res) {
          this.isBusyModal = false;
          this.getCampaigns();
        })
        .catch(function () {
          this.isBusyModal = false;
        });
    },
    async getCampaigns() {
      this.isBusy = true;

      await this.$http
        .get(
          "/Campaigns/getList?page=" +
            this.page +
            "&pageSize=" +
            this.pageSize +
            (this.filter.status != "0" ? "&active=" + this.filter.status : "") +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "")
        )
        .then(function (res) {
          this.campaigns = res.body.data;
          this.page = res.body.page;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
        })
        .catch(function () {});

      $("#mod-filter").modal("hide");

      this.isBusy = false;
    },
    formatDate(date) {
      return moment(date).format("lll");
    },
    prev() {
      this.page -= 1;
      this.getCampaigns();
    },
    next() {
      this.page += 1;
      this.getCampaigns();
    },
  },
};
</script>