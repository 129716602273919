<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <div :class="[
            'card',
            'card-border-color',
            'card-border-color-primary',
            'be-loading',
            { 'be-loading-active': isBusy },
          ]">
                    <div class="card-header">Order # {{ order.orderNumber }}</div>

                    <div class="card-body">
                        <form>
                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Order For</label>
                                <div class="col-12 col-sm-8 col-lg-6 col-form-label">
                                    {{ order.buyer ? order.buyer.displayName : "N/A" }}
                                </div>
                                <div class="pull-right right">
                                    Outstanding Balance:
                                    <span style="font-size: 15px; color: red; font-weight: bold"># {{ formatMoney(customerOutstandingBalance) }}</span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Delivery Address</label>
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <textarea class="form-control"
                                              placeholder="Delivery address for this order"
                                              v-model="deliveryAddress"></textarea>
                                    <ul v-if="v1.deliveryAddress"
                                        class="parsley-errors-list filled">
                                        <li class="parsley-required">Required.</li>
                                    </ul>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Order Date</label>
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <input :class="['form-control', { 'is-invalid': v1.orderDate }]"
                                           type="date"
                                           placeholder="Select a date"
                                           v-model="orderDate" />
                                    <ul v-if="v1.orderDate" class="parsley-errors-list filled">
                                        <li class="parsley-required">Required.</li>
                                    </ul>
                                </div>
                            </div>
                            <div v-if="user.role !== 'Sales'" class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Account Manager</label>
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <select class="form-control"
                                            placeholder="Select an Account Manager"
                                            v-model="salesRepId">
                                        <option v-for="c in salesReps" :key="c.id" :value="c.id">
                                            {{ c.text }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Custom Discount</label>
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <input :class="['form-control']"
                                           :disabled="orderDetails.length == 0"
                                           type="number"
                                           :max="total"
                                           step="0.01"
                                           v-model="discountOnOrder" />
                                    <span class="text-danger" v-if="discount > subtotal">Discount cannot be more than total order amount</span>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="be-spinner">
                        <svg width="40px" height="40px" viewBox="0 0 66 66">
                            <circle class="circle"
                                    fill="none"
                                    stroke-width="4"
                                    stroke-linecap="round"
                                    cx="33"
                                    cy="33"
                                    r="30"></circle>
                        </svg>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="showDetails">
            <div class="col-lg-12">
                <div :class="[
            'card',
            'card-border-color',
            'card-border-color-primary',
            'be-loading',
            { 'be-loading-active': isBusy },
          ]">
                    <div class="card-heade p-2">
                        <button class="btn btn-success btn-rounded btn-space float-right"
                                @click="viewAdd">
                            <span class="icon icon-left mdi mdi-plus"></span>
                            Add item
                        </button>
                        <h4><strong>Order Details</strong></h4>
                    </div>
                    <div class="col-sm-12 table-responsive">
                        <table class="table table-fw-widget dataTable no-footer">
                            <thead>
                                <tr>
                                    <th style="width: 25%">Description</th>
                                    <th style="width: 15%">Quantity</th>
                                    <th style="width: 15%">Unit Price</th>
                                    <th style="width: 10%">Sub Total</th>
                                    <th style="width: 10%">Discount</th>
                                    <th style="width: 25%">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in orderDetails" :key="index">
                                    <td>
                                        {{ item.name }} ({{ item.code }}) - N{{ item.unitPrice }}/{{
                      item.unit
                                        }}
                                    </td>
                                    <td>{{ item.quantity }}</td>
                                    <td>
                                        &#8358;
                                        {{ formatMoney(item.unitPrice) }}
                                    </td>
                                    <td>
                                        &#8358;
                                        {{ formatMoney(item.quantity * item.unitPrice) }}
                                    </td>
                                    <td>
                                        <input type="number" step="0.01" v-model="item.discount" />
                                    </td>
                                    <td>
                                        <button class="
                        btn btn-secondary btn-rounded btn-space
                        float-right
                      "
                                                @click.prevent="remove(index)">
                                            Remove
                                        </button>
                                        &#8358;
                                        {{
                      formatMoney(
                        item.quantity * item.unitPrice - item.discount
                      )
                                        }}
                                    </td>
                                </tr>
                                <tr v-if="!orderDetails.length">
                                    <td colspan="6">
                                        <div class="text-center">No items in order</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="4"></td>
                                    <td class="text-right">
                                        <b>SUBTOTAL</b>
                                    </td>
                                    <td>&#8358; {{ formatMoney(subtotal) }}</td>
                                </tr>
                                <tr>
                                    <td colspan="4"></td>
                                    <td class="text-right">
                                        <b>DISCOUNT</b>
                                    </td>
                                    <td>&#8358; {{ formatMoney(discount) }}</td>
                                </tr>

                                <tr>
                                    <td colspan="4"></td>
                                    <td class="text-right">
                                        <b>BALANCE</b>
                                    </td>
                                    <td>&#8358; {{ formatMoney(total) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-body" v-if="orderDetails.length">
                        <div class="form-group row" v-if="order.creditLimit > 0">
                            <div class="col-12 col-sm-12 col-lg-12">
                                <label class="custom-control custom-checkbox custom-control-inline">
                                    <input class="custom-control-input"
                                           type="checkbox"
                                           v-model="useCredit" />
                                    <span class="custom-control-label">
                                        Use credit facilities. Your current balance is
                                        <b>&#8358; {{ formatMoney(order.creditLimit) }}</b>
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-12 col-sm-12 col-lg-12">
                                <button :disabled="isBusy"
                                        class="btn btn-primary btn-xl btn-block"
                                        @click.prevent="checkOrder">
                                    <span v-if="isBusy">Busy...</span>
                                    <span v-else>Place Order</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="be-spinner">
                        <svg width="40px" height="40px" viewBox="0 0 66 66">
                            <circle class="circle"
                                    fill="none"
                                    stroke-width="4"
                                    stroke-linecap="round"
                                    cx="33"
                                    cy="33"
                                    r="30"></circle>
                        </svg>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade colored-header colored-header-primary"
             id="mod-add"
             role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header modal-header-colored">
                        <h4 class="modal-title p-1">
                            <strong>Add item to list</strong>
                        </h4>
                        <button class="close md-close"
                                type="button"
                                data-dismiss="modal"
                                aria-hidden="true"
                                @click.prevent="onAddCancel">
                            <span class="mdi mdi-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Product</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <select placeholder="Select an option"
                                        class="form-control"
                                        v-model="newItem.productIndex"
                                        @input="onProductSelect($event)">
                                    <option v-for="(p, index) in filteredProducts"
                                            :key="p.id"
                                            :value="index">
                                        {{ p.name }} ({{ p.code }}) - N{{
                      formatMoney(p.retailPrice)
                                        }}/{{ p.manufacturerProduct.unit }}
                                    </option>
                                </select>
                                <ul v-if="v2.productId" class="parsley-errors-list filled">
                                    <li class="parsley-required">Required.</li>
                                </ul>
                                <span v-if="canShowCaution"
                                      class="bg-warning text-white px-1 rounded"
                                      style="padding: 0.2rem">
                                    <i class="fa fa-warning mt-1"></i>
                                    <small>Product is <b>Low</b> on stock.</small>
                                </span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Quantity</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <input :class="['form-control', { 'is-invalid': v2.quantity }]"
                                       type="number"
                                       v-model.number="newItem.quantity"
                                       :disabled="isQuantityLoading"
                                       @input="onQuantityChange($event)" />
                                <ul v-if="v2.quantity" class="parsley-errors-list filled">
                                    <li class="parsley-required">Enter a number > 0.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button style="width: 100px"
                                class="btn btn-secondary"
                                type="button"
                                data-dismiss="modal"
                                @click.prevent="onAddCancel">
                            Cancel
                        </button>
                        <button style="width: 150px"
                                :disabled="isBusyModal || isQuantityLoading"
                                class="btn btn-primary"
                                type="button"
                                @click.prevent="add">
                            <span v-if="isBusyModal">Busy...</span>
                            <span v-else>Add item</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade colored-header colored-header-primary"
             id="mod-customer"
             role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header modal-header-colored">
                        <h4 class="modal-title p-1">
                            <strong>Select a customer</strong>
                        </h4>
                    </div>
                    <div class="modal-body">
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Customer</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <multiselect v-model="value"
                                             label="text"
                                             track-by="id"
                                             :options="customers"
                                             @select="onSelect"></multiselect>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button style="width: 100px"
                                class="btn btn-secondary"
                                type="button"
                                @click.prevent="cancel">
                            Cancel
                        </button>
                        <button style="width: 100px"
                                :disabled="isBusyModal"
                                class="btn btn-primary"
                                type="button"
                                @click.prevent="initialize">
                            <span v-if="isBusyModal">Busy...</span>
                            <span v-else>Start</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <CautionModal :products="bridgedProducts" @makeOrder="makeOrder" />
    </div>
</template>

<script>
    /* eslint-disable */

    import moment from "moment";
    import { helper } from "@/helper";
    import Select2 from "@/components/Select2";
    import CautionModal from "../components/CautionModal";
    import SystemPermissions from "@/permissions";
    import Multiselect from "vue-multiselect";

    Array.prototype.sum = function (prop1, prop2) {
        var total = 0;
        for (var i = 0, _len = this.length; i < _len; i++) {
            total += this[i][prop1] * this[i][prop2];
        }
        return total;
    };

    export default {
        mixins: [SystemPermissions.Mixin],
        name: "ordersCreateAlt",

        components: {
            Select2,
            CautionModal,
            Multiselect,
        },

        data() {
            return {
                value: "",
                canShowCaution: false,
                productInventoryTotal: 0,
                isQuantityLoading: false,
                customers: [],
                buyerId: null,
                showDetails: false,
                isBusy: false,
                isBusyModal: false,
                order: {},
                sellerId: null,
                deliveryAddress: null,
                products: [],
                filteredProducts: [],
                bridgedProducts: [],
                orderDetails: [],
                discountOnOrder: 0.0,
                discount: 0.0,
                tax: 0.0,
                subtotal: 0.0,
                total: 0.0,
                useCredit: false,
                newItem: {},
                salesReps: [],
                salesRepId: null,
                orderDate: moment().format("YYYY-MM-DD"),
                customerOutstandingBalance: 0,
                v1: {
                    sellerId: false,
                    deliveryAddress: false,
                    orderDate: false,
                },
                v2: {
                    productId: false,
                    quantity: false,
                },
                discountObj: {},
            };
        },

        created() {
            if (!this.hasPermission("Orders_Create")) {
                this.$router.push("/restricted");
            }
            this.getCustomers();
            this.getSalesReps();

            var id = this.$route.params.id;

            if (id) {
                this.buyerId = id;
            }
        },

        mounted() {
            let options = { backdrop: "static", keyboard: false };

            $("#mod-customer").modal(options, "show");
        },

        computed: {
            user: function () {
                return this.$store.state.currentUser;
            },
        },

        watch: {
            orderDetails: {
                handler(newVal, oldVal) {
                    this.subtotal = this.orderDetails.sum("quantity", "unitPrice");
                    this.discount = parseFloat(this.discountOnOrder);

                    if (this.discountObj) {
                        this.discount +=
                            this.discountObj.type == "Percent"
                                ? this.subtotal * (this.discountObj.value / 100)
                                : this.discountObj.value;
                    }

                    for (var i = 0, _len = this.orderDetails.length; i < _len; i++) {
                        if (
                            this.orderDetails[i].discount !== 0 &&
                            this.orderDetails[i].discount !== ""
                        )
                            this.discount += parseFloat(this.orderDetails[i].discount);
                    }

                    this.tax = 0;
                    this.total = this.subtotal - this.tax - this.discount;
                },
                deep: true,
            },

            discountOnOrder: function (newVal, oldVal) {
                if (oldVal) {
                    this.discount -= parseFloat(oldVal);
                }
                if (newVal) {
                    this.discount += parseFloat(newVal);
                }
                this.total = this.subtotal - this.tax - this.discount;
            },
        },

        methods: {
            async getCustomers() {
                await this.$http
                    .get("/Users/getItemListOfCustomers/" + this.user.parentId)
                    .then(function (res) {
                        this.customers = res.body;
                    })
                    .catch(function () { });
            },

            async getSalesReps() {
                await this.$http
                    .get("/Users/getItemListOfIntroducers/" + this.user.parentId)
                    .then(function (res) {
                        this.salesReps = res.body;
                    })
                    .catch(function () { });
            },

            async getCustomerOutstandingBalance() {
                await this.$http
                    .get("/Orders/getCustomerOutstandingBalance/" + this.buyerId)
                    .then(function (res) {
                        this.customerOutstandingBalance = res.body;
                    })
                    .catch(function () { });
            },

            cancel() {
                $("#mod-customer").modal("hide");
                this.$router.push("/orders");
            },

            onSelect(option) {
                this.buyerId = option.id;
            },

            async initialize() {
                if (this.buyerId) {
                    this.isBusy = true;
                    this.isBusyModal = true;

                    this.getCustomerOutstandingBalance();

                    await this.$http
                        .get("/Orders/initialize/" + this.buyerId)
                        .then(function (res) {
                            this.order = res.body;
                            this.deliveryAddress = res.body.buyer.deliveryAddress;
                        })
                        .catch(function () {
                            $("#mod-customer").modal("hide");
                            this.$router.push("/orders");
                        });

                    this.onSellerChange(this.user.parentId);

                    this.isBusyModal = false;
                    this.isBusy = false;

                    $("#mod-customer").modal("hide");
                }
            },

            viewAdd() {
                this.newItem = {
                    productIndex: null,
                    quantity: null,
                };

                this.v2 = {
                    productId: false,
                    quantity: false,
                };

                var fProducts = [];

                var details = this.orderDetails;

                this.products.forEach(function (e) {
                    if (!details.some((s) => s.code == e.code)) {
                        fProducts.push(e);
                    }
                });

                this.filteredProducts = fProducts;

                $("#mod-add").modal("show");
            },

            add() {
                if (this.validateAdd()) {
                    const p = {
                        name: this.filteredProducts[this.newItem.productIndex].name,
                        unit: this.filteredProducts[this.newItem.productIndex]
                            .manufacturerProduct.unit,
                        code: this.filteredProducts[this.newItem.productIndex].code,
                        description: `${this.filteredProducts[this.newItem.productIndex].name
                            } (${this.filteredProducts[this.newItem.productIndex].manufacturerProduct
                                .unit
                            })`,
                        unitPrice:
                            this.filteredProducts[this.newItem.productIndex].retailPrice,
                        quantity: this.newItem.quantity,
                        discount: 0,
                    };

                    this.orderDetails.push(p);
                    $("#mod-add").modal("hide");
                    this.canShowCaution = false;
                }
            },
            validateAdd() {
                this.v2 = {
                    productId: false,
                    quantity: false,
                };

                this.orderDetails.push(p);

                $("#mod-add").modal("hide");
                this.canShowCaution = false;
            },

            validateAdd() {
                this.v2 = {
                    productId: false,
                    quantity: false,
                };
                var isValid = true;

                if (this.newItem.productIndex < 0) {
                    this.v2.productId = true;
                    isValid = false;
                }

                if (Number(this.newItem.quantity) <= 0) {
                    this.v2.quantity = true;
                    isValid = false;
                }

                return isValid;
            },

            onSellerChange(id) {
                this.sellerId = id;
                this.showDetails = true;
                this.orderDetails = [];
                this.getProducts(id);
                this.getDiscount();
            },

            async getProducts(parentId) {
                this.products = [];

                await this.$http
                    .get(
                        "/Products/getListNoPagingForDistributor?parentId=" +
                        parentId +
                        "&active=true"
                    )
                    .then(function (res) {
                        this.products = res.body;
                    })
                    .catch(function () { });
            },

            remove(index) {
                this.orderDetails.splice(index, 1);
            },

            updateStatus() {
                $("#mod-change").modal("hide");
            },

            checkOrder() {
                if (this.validateOrder()) {
                    if (this.checkProductsStatus()) $("#mod-caution").modal("show");
                    else this.makeOrder();
                }
            },

            async makeOrder() {
                $("#mod-caution").modal("hide");
                this.isBusy = true;
                this.bridgedProducts = [];

                if (this.validateOrder()) {
                    this.orderDetails.forEach((element) => {
                        if (element.discount === "") element.discount = 0;
                    });

                    const data = {
                        orderNumber: this.order.orderNumber,
                        subtotal: this.subtotal,
                        discount: this.discount !== "" ? this.discount : 0,
                        tax: this.tax,
                        total: this.total,
                        parentId: this.user.parentId,
                        sellerId: this.sellerId,
                        makerId: this.buyerId,
                        details: this.orderDetails,
                        useCredit: this.useCredit,
                        deliveryAddress: this.deliveryAddress,
                        introducerId: this.salesRepId,
                        orderDate: this.orderDate,
                    };

                    if (!data.introducerId) data.introducerId = this.user.id;

                    await this.$http
                        .post("/Orders/create", data)
                        .then(function (res) {
                            this.isBusy = false;
                            this.$router.push("/orders");
                        })
                        .catch(function () { });

                    this.isBusy = false;
                } else {
                    this.isBusy = false;
                }
            },
            validateOrder() {
                this.v1 = {
                    sellerId: false,
                    deliveryAddress: false,
                    orderDate: false,
                };
                var isValid = true;

                if (!this.sellerId) {
                    this.v1.sellerIs = true;
                    isValid = false;
                }

                if (!this.deliveryAddress) {
                    this.v1.deliveryAddress = true;
                    isValid = false;
                }

                if (!this.orderDate) {
                    this.v1.orderDate = true;
                    isValid = false;
                }

                return isValid;
            },

            formatMoney(val) {
                return helper.formatMoney(val);
            },

            async getDiscount() {
                await this.$http
                    .get(
                        "/Discounts/getForOrder?buyerId=" +
                        this.buyerId +
                        "&sellerId=" +
                        this.sellerId
                    )
                    .then(function (res) {
                        this.discountObj = res.body;
                    })
                    .catch(function () { });
            },

            async onProductSelect(event) {
                this.newItem.productIndex = event.target.value;
                this.isQuantityLoading = true;
                try {
                    const productId = this.filteredProducts[event.target.value].id;
                    const response = await this.$http.get(
                        "/Inventories/getProductInventoriesTotal/" + productId
                    );

                    if (response.ok && response.status === 200)
                        this.productInventoryTotal = response.body;

                    this.isQuantityLoading = false;
                } catch (error) {
                    this.isQuantityLoading = false;
                }
            },

            onQuantityChange(event) {
                const product = this.filteredProducts[this.newItem.productIndex];

                this.canShowCaution =
                    this.productInventoryTotal - event.target.value <=
                    product.minimumStockLevel;
            },

            onAddCancel() {
                this.canShowCaution = false;
            },

            checkProductsStatus() {
                this.orderDetails.forEach((detail) => {
                    for (let index = 0; index < this.products.length; index++) {
                        const product = this.products[index];

                        if (
                            detail.code === product.code &&
                            product.quantity - detail.quantity < 0
                        ) {
                            this.bridgedProducts.push({
                                name: detail.name,
                                neededQuantity: detail.quantity - product.quantity,
                                unit: detail.unit,
                            });
                            break;
                        }
                    }
                });

                return this.bridgedProducts.length > 0;
            },
        },
    };
</script>