<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <button
            class="btn btn-success btn-rounded btn-space"
            @click="viewFilter"
          >
            <span class="feather icon-filter text-white"></span> Filters
          </button>
        </div>
        <div class="card-body">
          <div
            class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
          >
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table class="table table-fw-widget dataTable no-footer">
                  <thead>
                    <tr>
                      <th style="width: 20%">Name</th>
                      <th style="width: 10%">Code</th>
                      <th style="width: 10%">Email Address</th>
                      <th style="width: 10%">Phone Number</th>
                      <th style="width: 15%">Address 1</th>
                      <th style="width: 15%">Address 2</th>
                      <th style="width: 20%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="m in distributors" :key="m.id">
                      <td>
                        {{ m.name }}
                        <br />
                        <span>{{ m.description }}</span>
                      </td>
                      <td>{{ m.code }}</td>
                      <td>{{ m.emailAddress }}</td>
                      <td>{{ m.phoneNumber }}</td>
                      <td>{{ m.address1 }}</td>
                      <td>{{ m.address2 }}</td>
                      <td class="text-right">
                        <button
                          class="btn btn-primary btn-sm btn-rounded btn-space"
                          @click.prevent="viewProducts(m)"
                        >
                          View Products
                        </button>
                      </td>
                      <td class="text-right">
                        <button
                          class="btn btn-success btn-sm btn-rounded btn-space"
                          @click.prevent="join(m)"
                        >
                          Join
                        </button>
                      </td>
                    </tr>
                    <tr v-if="!distributors.length">
                      <td colspan="7">
                        <div class="text-center" style="padding-top: 25px">
                          <span
                            style="font-size: 4.615rem"
                            class="mdi mdi-truck-fast"
                          ></span>
                        </div>
                        <h3 class="text-center" style="padding-bottom: 50px">
                          There are no distributors.
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">
                  {{ count }} distributor{{ count != 1 ? "s" : "" }}
                </div>
              </div>
              <div class="col-sm-7">
                <div class="dataTables_paginate paging_simple_numbers">
                  <ul class="pagination">
                    <li
                      :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasPrev },
                      ]"
                    >
                      <a href="#" class="page-link" @click.prevent="prev"
                        >Previous</a
                      >
                    </li>
                    <li
                      :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasNext },
                      ]"
                    >
                      <a href="#" class="page-link" @click.prevent="next"
                        >Next</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-filter"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h3 class="modal-title">Filter</h3>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Other filters</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Filter by name..."
                  v-model="filter.qry"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              @click="reset"
            >
              Reset
            </button>
            <button
              style="width: 120px"
              class="btn btn-primary md-close"
              type="button"
              @click="getDistributors"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-products"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h3 class="modal-title">Products - {{ distributor.name }}</h3>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div
              class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
            >
              <div class="row be-datatable-body">
                <div class="col-sm-12">
                  <table class="table table-fw-widget dataTable no-footer">
                    <thead>
                      <tr>
                        <th style="width: 50%">Name</th>
                        <th style="width: 50%">Category</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="p in products" :key="p.id">
                        <td>
                          {{ p.name }} ({{ p.code }})
                          <br />
                          {{ `&#8358;${formatMoney(p.price)}` }} / {{ p.unit }}
                        </td>
                        <td>{{ p.category.name }}</td>
                      </tr>
                      <tr v-if="!products.length">
                        <td colspan="2">
                          <div class="text-center" style="padding-top: 50px">
                            <span
                              style="font-size: 4.615rem"
                              class="mdi mdi-tag-multiple"
                            ></span>
                          </div>
                          <h3 class="text-center" style="padding-bottom: 100px">
                            There are no products for this distributor
                          </h3>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row be-datatable-footer">
                <div class="col-sm-5">
                  <div class="dataTables_info">
                    {{ countProduct }} product{{ countProduct != 1 ? "s" : "" }}
                  </div>
                </div>
                <div class="col-sm-7">
                  <div class="dataTables_paginate paging_simple_numbers">
                    <ul class="pagination">
                      <li
                        :class="[
                          'paginate_button',
                          'page-item',
                          'previous',
                          { disabled: !hasPrevProduct },
                        ]"
                      >
                        <a
                          href="#"
                          class="page-link"
                          @click.prevent="prevProduct"
                          >Previous</a
                        >
                      </li>
                      <li
                        :class="[
                          'paginate_button',
                          'page-item',
                          'previous',
                          { disabled: !hasNextProduct },
                        ]"
                      >
                        <a
                          href="#"
                          class="page-link"
                          @click.prevent="nextProduct"
                          >Next</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import { helper } from "@/helper";
import Select2 from "@/components/Select2";

export default {
  name: "distributorList",
  components: {
    Select2,
  },

  created() {
    this.getDistributors();
  },

  watch: {},

  data() {
    return {
      filter: {
        status: "0",
        parentId: "0",
        qry: "",
      },
      distributor: {},
      products: [],
      isBusy: false,
      isBusyModal: false,
      distributors: [],
      count: 0,
      pageNumber: 0,
      pageSize: 10,
      hasNext: false,
      hasPrev: false,
      countProduct: 0,
      pageProduct: 0,
      pageSizeProduct: 5,
      hasNextProduct: false,
      hasPrevProduct: false,
    };
  },

  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
  },

  methods: {
    reset() {
      this.filter = {
        status: "0",
        parentId: "0",
        qry: "",
      };
    },

    viewProducts(m) {
      debugger;
      this.distributor = m;
      this.getProducts(m.id);
      $("#mod-products").modal("show");
    },

    viewFilter() {
      $("#mod-filter").modal("show");
    },

    join(m) {
      if (confirm("Are you sure?")) {
        this.isBusyModal = true;
        var data = {
          userId: this.user.id,
          parentId: m.id,
          isCustomer: true,
        };

        $("#mod-products").modal("hide");

        this.$http
          .post("/Users/addParent", data)
          .then(function (res) {
            this.isBusyModal = false;

            $.gritter.add({
              title: "Success",
              text: "You have joined " + m.name + " retail community.",
              class_name: "color success",
            });
          })
          .catch(function () {
            this.isBusyModal = false;
          });
      }
    },

    async getDistributors() {
      this.isBusy = true;

      await this.$http
        .get(
          "/Accounts/getList?pageNumber=" +
            this.pageNumber +
            "&pageSize=" +
            this.pageSize +
            (this.filter.status != "0" ? "&active=" + this.filter.status : "") +
            (this.filter.qry != "" ? "&query=" + this.filter.qry : "") +
            "&accountType=Distributor"
        )
        .then(function (res) {
          this.distributors = res.body.data;
          this.pageNumber = res.body.pageNumber;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
        })
        .catch(function () {});

      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },

    async getProducts(parentId) {
      this.isBusy = true;

      await this.$http
        .get(
          "/Products/getList?page=" +
            this.pageProduct +
            "&pageSize=" +
            this.pageSizeProduct +
            (this.filter.status != "0" ? "&active=" + this.filter.status : "") +
            (parentId ? "&parentId=" + parentId : "")
        )
        .then(function (res) {
          this.products = res.body.data;
          this.pageProduct = res.body.page;
          this.pageSizeProduct = res.body.pageSize;
          this.countProduct = res.body.count;
          this.hasPrevProduct = res.body.hasPrev;
          this.hasNextProduct = res.body.hasNext;
          this.isBusy = false;
        })
        .catch(function () {});

      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },

    formatDate(date) {
      return moment(date).format("lll");
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    prev() {
      this.pageNumber -= 1;
      this.getDistributors();
    },

    next() {
      this.pageNumber += 1;
      this.getDistributors();
    },

    prevProduct() {
      this.pageProduct -= 1;
      this.getProducts(this.distributor.id);
    },

    nextProduct() {
      this.pageProduct += 1;
      this.getProducts(this.distributor.id);
    },
  },
};
</script>