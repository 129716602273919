<template>
    <div class="row">
        <div class="col-lg-12">
            <div :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]">
                <div class="card-header">
                    <button class="btn btn-success btn-rounded btn-space pull-left"
                            @click="viewFilter">
                        <i class="feather icon-filter text-white"></i> Filters
                    </button>

                    <button v-if="hasPermission('Global_View_Orders')" class="btn btn-info btn-rounded  "
                            @click.prevent="viewGlobal()"
                            :disabled="loadingNewOrders">
                        <span v-if="loadingNewOrders"
                              class="spinner-border spinner-border-sm"
                              role="status">
                            <i class="sr-only">Loading...</i>
                        </span>
                        <span v-else>
                            <i class="mdi mdi-earth text-white"></i>
                            Switch to {{isGlobal ? "Default" : "Global" }}
                        </span>
                    </button>
                    <button class="btn btn-primary btn-rounded btn-space float-right"
                            @click="refresh"
                            :disabled="loadingNewOrders">
                        <span v-if="loadingNewOrders"
                              class="spinner-border spinner-border-sm"
                              role="status">
                            <i class="sr-only">Loading...</i>
                        </span>
                        <span v-else>
                            <i class="mdi mdi-refresh text-white"></i>
                            Check For New Orders
                        </span>
                    </button>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                        <p>Click on "Order Number" to view full Order details</p>
                        <div class="row be-datatable-body">
                            <div class="col-sm-12 table-responsive">
                                <table class="table table-fw-widget dataTable no-footer">
                                    <thead>
                                        <tr>
                                            <th style="width: 10%">Created</th>
                                            <th style="width: 10%">Order Date</th>
                                            <th style="width: 10%">Order Number</th>
                                            <th style="width: 5%">Channel</th>
                                            <th style="width: 10%">Customer</th>
                                            <th style="width: 5%">Total</th>
                                            <th style="width: 5%" v-if="isGlobal">Branch</th>
                                            <th style="width: 10%" v-if="isGlobal">Account Manager</th>
                                            <th style="width: 10%">Created By</th>
                                            <th style="width: 5%">Delivery Status</th>
                                            <th style="width: 10%">Payment Status</th>
                                            <th style="width: 10%"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="o in orders" :key="o.id">
                                            <td>{{ formatDateWithTime(o.created) }}</td>
                                            <td>
                                                {{
                          o.orderDate
                            ? formatDate(o.orderDate)
                            : formatDateWithTime(o.created)
                                                }}
                                            </td>
                                            <td>
                                                <a v-if="hasPermission('Orders_View')"
                                                   href="#"
                                                   @click.prevent="details(o.id)">{{ o.orderNumber }}</a>
                                                <a v-else href="#">{{ o.orderNumber }}</a>
                                            </td>
                                            <td>{{ o.source }}</td>

                                            <td>{{ o.maker.displayName }}</td>
                                            <td>&#8358; {{ formatMoney(o.total) }}</td>
                                            <td v-if="isGlobal">{{ o.branch ? o.branch.name : "N/A" }}</td>
                                            <td v-if="isGlobal">
                                                {{
                          o.introducer
                            ? o.introducer.displayName && o.introducer.staffId
                              ? o.introducer.displayName +
                                "(" +
                                o.introducer.staffId +
                                ")"
                              : !o.introducer.displayName &&
                                o.introducer.staffId
                              ? o.introducer.staffId
                              : o.introducer.displayName &&
                                !o.introducer.staffId
                              ? o.introducer.displayName
                              : o.introducer.staffId
                            : "N/A"
                                                }}
                                            </td>
                                            <td>{{ o.introducer.displayName }}</td>

                                            <td>
                                                <!--<a
                                                  title="Fulfill Order"
                                                  v-if="
                                                    o.status == 'Pending' &&
                                                    hasPermission('Orders_Fulfill')
                                                  "
                                                  href="#"
                                                  @click.prevent="viewFulfillment(o)"
                                                >
                                                  <span
                                                    :class="[
                                                      'badge',
                                                      'badge-pill',
                                                      { 'badge-warning': o.status == 'Pending' },
                                                      { 'badge-success': o.status == 'Fulfilled' },
                                                      { 'badge-danger': o.status == 'Canceled' },
                                                    ]"
                                                    >{{ o.status }}</span
                                                  >
                                                </a>-->
                                                <span :class="[
                                                                        'badge',
                                                                        'badge-pill',
                                                                        { 'badge-warning': o.status == 'Pending' },
                                                                        { 'badge-success': o.status == 'Fulfilled' },
                                                                        { 'badge-danger': o.status == 'Canceled' },
                                                                      ]">{{ o.status }}</span>
                                            </td>
                                            <td v-if="o.payment">
                                                <span :class="[
                            'badge',
                            'badge-pill',
                            {
                              'badge-primary':
                                o.payment.paymentStatus != 'PAID',
                            },
                            {
                              'badge-success':
                                o.payment.paymentStatus == 'PAID',
                            },
                          ]">
                                                    <!--{{ o.payment.status==true? "YES":"NO" }}-->
                                                    {{ o.payment.paymentStatus }}
                                                </span>
                                            </td>
                                            <td>
                                                <button class="btn btn-outline-primary btn-sm dropdown-toggle"
                                                        type="button"
                                                        id="dropdownMenu2"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false">
                                                    Actions
                                                </button>
                                                <div class="dropdown-menu px-1"
                                                     aria-labelledby="dropdownMenu2">
                                                    <a class="dropdown-item" href="#" @click.prevent="details(o.id)">
                                                        View Details
                                                    </a>
                                                    <div v-if="o.status == 'Pending'" class="dropdown-divider"></div>
                                                    <a v-if="o.status == 'Pending' && hasPermission('Orders_Fulfill')" class="dropdown-item" href="#" @click.prevent="viewFulfillment(o)">
                                                        Fulfil Order
                                                    </a>
                                                    <div v-if="o.status == 'Pending'" class="dropdown-divider"></div>
                                                    <a v-if="o.status == 'Pending' && hasPermission('Orders_Fulfill')" class="dropdown-item" href="#" @click.prevent="viewCancel(o)" style="color:red">
                                                        Cancel Order
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-if="!orders.length">
                                            <td colspan="10">
                                                <div class="text-center" style="padding-top: 50px">
                                                    <span style="font-size: 4.615rem"
                                                          class="mdi mdi-basket"></span>
                                                </div>
                                                <h3 class="text-center" style="padding-bottom: 100px">
                                                    You have not recieved any orders.
                                                </h3>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row be-datatable-footer">
                            <div class="col-sm-5">
                                <div class="dataTables_info">
                                    {{ count }} order{{ count != 1 ? "s" : "" }}
                                </div>
                            </div>
                            <div class="col-sm-7">
                                <div class="dataTables_paginate paging_simple_numbers">
                                    <ul class="pagination">
                                        <li :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasPrev },
                      ]">
                                            <a href="#" class="page-link" @click.prevent="prev">Previous</a>
                                        </li>
                                        <li :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasNext },
                      ]">
                                            <a href="#" class="page-link" @click.prevent="next">Next</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="be-spinner">
                    <svg width="40px" height="40px" viewBox="0 0 66 66">
                        <circle class="circle"
                                fill="none"
                                stroke-width="4"
                                stroke-linecap="round"
                                cx="33"
                                cy="33"
                                r="30" />
                    </svg>
                </div>
            </div>
        </div>

        <div class="modal fade colored-header colored-header-primary"
             id="mod-fulfillment"
             role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header modal-header-colored">
                        <h3 class="modal-title">
                            Fulfillment for order # {{ fOrder.orderNumber }}
                        </h3>
                        <button class="close md-close"
                                type="button"
                                data-dismiss="modal"
                                aria-hidden="true">
                            <span class="mdi mdi-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <!--<div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Status</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <select class="form-control"
                                        placeholder="Select an option"
                                        v-model="fOrder.wasFulfilled">
                                    <option value="true">Fulfilled</option>
                                    <option value="false">Canceled</option>
                                </select>
                                <ul v-if="v1.wasFulfilled" class="parsley-errors-list filled">
                                    <li class="parsley-required">Required.</li>
                                </ul>
                            </div>
                        </div>-->
                        <div class="form-group row" v-if="fOrder.wasFulfilled == 'true'">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Fulfilled on</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <input :class="['form-control', { 'is-invalid': v1.dateFulfilled }]"
                                       type="date"
                                       placeholder="Select a date"
                                       v-model="fOrder.dateFulfilled" />

                                <ul v-if="v1.dateFulfilled" class="parsley-errors-list filled">
                                    <li class="parsley-required">Required.</li>
                                </ul>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Notes</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <textarea :class="['form-control', { 'is-invalid': v1.notes }]"
                                          v-model="fOrder.notes"></textarea>
                                <ul v-if="v1.notes" class="parsley-errors-list filled">
                                    <li class="parsley-required">Required.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button style="width: 100px"
                                class="btn btn-secondary md-close"
                                type="button"
                                data-dismiss="modal">
                            Cancel
                        </button>
                        <button style="width: 100px"
                                class="btn btn-primary"
                                type="button"
                                @click.prevent="fulfillment">
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade colored-header colored-header-primary"
             id="mod-cancel-order"
             role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header modal-header-colored">
                        <h3 class="modal-title">
                            Cancel order # {{ fOrder.orderNumber }}
                        </h3>
                        <button class="close md-close"
                                type="button"
                                data-dismiss="modal"
                                aria-hidden="true">
                            <span class="mdi mdi-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Notes</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <textarea :class="['form-control', { 'is-invalid': v2.notes }]"
                                          v-model="fOrder.notes"></textarea>
                                <ul v-if="v2.notes" class="parsley-errors-list filled">
                                    <li class="parsley-required">Required.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button style="width: 100px"
                                class="btn btn-secondary md-close"
                                type="button"
                                data-dismiss="modal">
                            Cancel
                        </button>
                        <button style="width: 100px"
                                class="btn btn-primary"
                                type="button"
                                @click.prevent="cancelOrder">
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade colored-header colored-header-primary"
             id="mod-order-fulfillment-status" data-backdrop="static" data-keyboard="false"
             role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header modal-header-colored">
                        <h4 class="modal-title">
                            Order Status Has Been Updated To FulFilled
                        </h4>
                        <button class="close md-close"
                                type="button"
                                data-dismiss="modal"
                                aria-hidden="true">
                            <span class="mdi mdi-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group row">
                            <div class="col-12">
                                <p style="font-size:small; color:gray; text-align:center">See inventory movement summary below</p>
                                <ul>
                                    <li v-for="o in inventoryMovementSummary">{{ o }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button style="width: 100px"
                                class="btn btn-primary"
                                type="button"
                                data-dismiss="modal">
                            Okay
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade colored-header colored-header-primary"
             id="mod-filter"
             role="dialog"
             tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header modal-header-colored">
                        <h3 class="modal-title">Filter</h3>
                        <button class="close md-close"
                                type="button"
                                data-dismiss="modal"
                                aria-hidden="true">
                            <span class="mdi mdi-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Status</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <select class="form-control"
                                        placeholder="Select an option"
                                        v-model="filter.status">
                                    <option value="0">All Orders</option>
                                    <option value="Pending">Pending</option>
                                    <option value="Fulfilled">Fulfilled</option>
                                    <option value="Canceled">Canceled</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Date range</label>
                            <div class="col-12 col-sm-4 col-lg-4">
                                <input type="date"
                                       class="form-control"
                                       placeholder="Select a date"
                                       v-model="filter.dateStart" />
                            </div>
                            <div class="col-12 col-sm-4 col-lg-4">
                                <input type="date"
                                       class="form-control"
                                       placeholder="Select a date"
                                       v-model="filter.dateEnd" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Buyer Name</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <input class="form-control"
                                       type="text"
                                       placeholder="Filter by buyer name..."
                                       v-model="filter.maker" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Order Number</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <input class="form-control"
                                       type="text"
                                       placeholder="Filter by order number..."
                                       v-model="filter.orderNumber" />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <label class="custom-control custom-checkbox custom-control-inline">
                                    <input class="custom-control-input"
                                           type="checkbox"
                                           v-model="filter.showForMe" />
                                    <span class="custom-control-label">My Orders Only</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button style="width: 100px"
                                class="btn btn-secondary md-close"
                                type="button"
                                data-dismiss="modal">
                            Cancel
                        </button>
                        <button style="width: 100px"
                                class="btn btn-secondary md-close"
                                type="button"
                                @click="reset">
                            Reset
                        </button>
                        <button style="width: 100px"
                                class="btn btn-primary md-close"
                                type="button"
                                @click="getOrders">
                            Search
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade colored-header colored-header-primary"
             id="mod-global"
             role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="text-center">
                            <!-- <div class="text-warning"><span class="modal-main-icon mdi mdi-alert"></span></div> -->
                            <h3 class="p-1">Confirmation</h3>
                            <p>
                                Do you want to switch to {{isGlobal ? "Default" : "Global" }} View?

                            </p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button style="width: 100px"
                                class="btn btn-secondary"
                                type="button"
                                data-dismiss="modal">
                            Cancel
                        </button>
                        <button style="width: 140px"
                                :disabled="isBusyModal"
                                class="btn btn-warning"
                                type="button"
                                @click.prevent="toggleGlobal">
                            <span v-if="isBusyModal">Busy...</span>
                            <span v-else>Yes, proceed</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>



        <vue-confirm-dialog></vue-confirm-dialog>
    </div>
</template>

<script>
    /* eslint-disable */

    import moment from "moment";
    import { helper } from "@/helper";
    import Select2 from "@/components/Select2";
    import SystemPermissions from "@/permissions";

    export default {
        mixins: [SystemPermissions.Mixin],
        name: "ordersRecieved",
        components: {
            Select2,
            // DatePicker
        },

        mounted: function () {
            window.setInterval(() => {
                this.getOrders();
            }, 300000);
        },

        created() {
            if (!this.hasPermission("Orders_View")) {
                this.$router.push("/restricted");
            }

            var user = this.$store.state.currentUser;
            var preferredView = this.$auth.getPreferredViewForOrder(user.id);
            if (preferredView) {
                if (preferredView == 'global')
                    this.isGlobal = true;
                else if (preferredView == 'default')
                    this.isGlobal = false;
            }

            var startDate = this.$route.query.sd
                ? this.$route.query.sd
                : moment().subtract(13, "days").format("YYYY-MM-DD");
            var endDate = this.$route.query.ed
                ? this.$route.query.ed
                : moment().format("YYYY-MM-DD");
            var status = this.$route.query.st ? this.$route.query.st : "0";
            var buyerName = this.$route.query.bn ? this.$route.query.bn : "";
            var orderNumber = this.$route.query.on ? this.$route.query.on : "";
            var myOrderOnly =
                this.$route.query.mo && this.$route.query.mo == "true" ? true : false;

            this.filter = {
                status: status,
                orderNumber: orderNumber,
                maker: buyerName,
                dateStart: moment(String(startDate)).format("YYYY-MM-DD"),
                dateEnd: moment(String(endDate)).format("YYYY-MM-DD"),
                showForMe: myOrderOnly,
            };

            this.getOrders();

            if (this.user.type === "Distributor")
                this.getSystemSettings();
        },

        data() {
            return {
                fetchDate: Date.now(),
                loadingNewOrders: false,
                filter: {
                    status: "0",
                    orderNumber: "",
                    maker: "",
                    dateStart: moment().subtract(13, "days").format("YYYY-MM-DD"),
                    dateEnd: moment().format("YYYY-MM-DD"),
                    showForMe: false,
                },
                isGlobal: false,
                isBusy: false,
                isBusyModal: false,
                fOrder: {},
                inventoryMovementSummary: {},
                v1: {
                    wasFulfilled: false,
                    dateFulfilled: false,
                    notes: false,
                },
                v2: {
                    notes: false,
                },
                orders: [],
                createdUser: {},
                count: 0,
                page: 0,
                pageSize: 10,
                hasNext: false,
                hasPrev: false,
                options: {
                    format: "YYYY-MM-DD",
                    icons: {
                        time: "mdi mdi-time",
                        date: "mdi mdi-calendar",
                        up: "mdi mdi-chevron-up",
                        down: "mdi mdi-chevron-down",
                        previous: "mdi mdi-chevron-left",
                        next: "mdi mdi-chevron-right",
                        today: "mdi mdi-screenshot",
                        clear: "mdi mdi-trash",
                        close: "mdi mdi-remove",
                    },
                },
                local: {
                    dow: 0, // Sunday is the first day of the week
                    hourTip: "Select Hour", // tip of select hour
                    minuteTip: "Select Minute", // tip of select minute
                    secondTip: "Select Second", // tip of select second
                    yearSuffix: "", // suffix of head year
                    monthsHead:
                        "January_February_March_April_May_June_July_August_September_October_November_December".split(
                            "_"
                        ), // months of head
                    months: "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"), // months of panel
                    weeks: "Su_Mo_Tu_We_Th_Fr_Sa".split("_"), // weeks
                    cancelTip: "cancel",
                    submitTip: "confirm",
                },
                systemSettings: {},
            };
        },

        computed: {
            user: function () {
                return this.$store.state.currentUser;
            },
        },

        methods: {
            reset() {
                this.filter = {
                    status: "0",
                    orderNumber: "",
                    maker: "",
                    dateStart: moment().subtract(13, "days").format("YYYY-MM-DD"),
                    dateEnd: moment().format("YYYY-MM-DD"),
                    showForMe: false,
                };
            },

            async refresh() {
                try {
                    this.isBusy = true;
                    this.loadingNewOrders = true;

                    const prevDate = this.fetchDate;
                    await this.$http
                        .get(
                            "/Orders/getList?page=" +
                            this.page +
                            "&pageSize=" +
                            this.pageSize +
                            (this.filter.orderNumber != ""
                                ? "&orderNumber=" + this.filter.orderNumber
                                : "") +
                            (this.filter.maker != "" ? "&buyer=" + this.filter.maker : "") +
                            (this.filter.status != "0"
                                ? "&status=" + this.filter.status
                                : "") +
                            (this.filter.dateStart != ""
                                ? "&dateStart=" +
                                moment(this.filter.dateStart).format("YYYY-MM-DD 00:00:00")
                                : "") +
                            (this.filter.dateEnd != ""
                                ? "&dateEnd=" +
                                moment(this.filter.dateEnd).format("YYYY-MM-DD 23:59:59")
                                : "") +
                            (!this.isGlobal ? "&sellerId=" + this.user.parentId : "") +
                            this.user.parentId +
                            (this.filter.showForMe != ""
                                ? "&showForMe=" + this.filter.showForMe
                                : "")
                        )
                        .then(function (res) {
                            this.orders = res.body.data;
                            this.page = res.body.page;
                            this.pageSize = res.body.pageSize;
                            this.count = res.body.count;
                            this.hasPrev = res.body.hasPrev;
                            this.hasNext = res.body.hasNext;
                            this.isBusy = false;
                            this.fetchDate = Date.now();

                            let newOrdersCount = 0;

                            this.orders.forEach((element) => {
                                if (
                                    new Date(element.created).getTime() >
                                    new Date(prevDate).getTime()
                                ) {
                                    newOrdersCount++;
                                }
                            });

                            this.loadingNewOrders = false;

                            if (newOrdersCount > 0) {
                                this.$toast.success(
                                    `${newOrdersCount} New Order${newOrdersCount > 1 ? "s" : ""}`,
                                    {
                                        icon: false,
                                        rtl: false,
                                    }
                                );
                            } else {
                                this.$toast("No New Orders", {
                                    icon: false,
                                    rtl: false,
                                });
                            }
                        });
                } catch (error) {
                    this.loadingNewOrders = false;
                    this.isBusy = false;
                    console.log(error);
                }
            },

            viewFulfillment(o) {
                this.v1 = {
                    notes: false,
                    wasFulfilled: false,
                    dateFulfilled: false,
                };
                this.fOrder = {
                    orderId: o.id,
                    orderNumber: o.orderNumber,
                    wasFulfilled: 'true',
                    notes: null,
                    dateFulfilled: moment().format("YYYY-MM-DD"),
                    updateStatusOnly: false
                };

                $("#mod-fulfillment").modal("show");
            },
            viewGlobal() {
                $("#mod-global").modal("show");
            },

            viewCancel(o) {
                this.v2 = {
                    notes: false
                };
                this.fOrder = {
                    orderId: o.id,
                    orderNumber: o.orderNumber,
                    wasFulfilled: 'false',
                    notes: null,
                    dateFulfilled: moment().format("YYYY-MM-DD"),
                };

                $("#mod-cancel-order").modal("show");
            },

            viewExport() {
                // $("#mod-export").modal("show");
            },

            viewFilter() {
                $("#mod-filter").modal("show");
            },

            details(id) {
                this.$router.push(
                    "/orders/" +
                    id +
                    "?sd=" +
                    this.filter.dateStart +
                    "&ed=" +
                    this.filter.dateEnd +
                    "&st=" +
                    this.filter.status +
                    "&bn=" +
                    this.filter.maker +
                    "&on=" +
                    this.filter.orderNumber +
                    "&mo=" +
                    this.filter.showForMe
                );
            },

            async getOrders() {
                this.isBusy = true;

                await this.$http
                    .get(
                        "/Orders/getList?page=" +
                        this.page +
                        "&pageSize=" +
                        this.pageSize +
                        (this.filter.orderNumber != ""
                            ? "&orderNumber=" + this.filter.orderNumber
                            : "") +
                        (this.filter.maker != "" ? "&buyer=" + this.filter.maker : "") +
                        (this.filter.status != "0" ? "&status=" + this.filter.status : "") +
                        (this.filter.dateStart != ""
                            ? "&dateStart=" +
                            moment(this.filter.dateStart).format("YYYY-MM-DD 00:00:00")
                            : "") +
                        (this.filter.dateEnd != ""
                            ? "&dateEnd=" +
                            moment(this.filter.dateEnd).format("YYYY-MM-DD 23:59:59")
                            : "") +
                        "&sellerId=" + this.user.parentId +
                        "&showForMe=" + !this.isGlobal
                    )
                    .then(function (res) {
                        this.orders = res.body.data;
                        this.page = res.body.page;
                        this.pageSize = res.body.pageSize;
                        this.count = res.body.count;
                        this.hasPrev = res.body.hasPrev;
                        this.hasNext = res.body.hasNext;
                        this.isBusy = false;
                        this.fetchDate = Date.now();
                    })
                    .catch(function () { });

                $("#mod-filter").modal("hide");
                this.isBusy = false;
            },

            async fulfillment() {
                this.isBusyModal = true;
                this.isBusy = true;

                if (this.validate()) {
                    var message = '';
                    if (this.systemSettings.autoOrderFulfillmentReconciliation) {
                        message = 'Order status will be updated to fulfilled and inventory will be depleted. Please confirm';
                    }
                    else {
                        message = 'Only Order status will be updated to fulfilled. Please confirm';
                    }

                    let self = this
                    debugger;
                    this.$confirm(
                        //Vue.$vueConfirm.confirm(
                        {
                            auth: false,
                            message: message,
                            button: {
                                no: 'Cancel',
                                yes: 'Proceed'
                            },
                            callback: confirm => {
                                if (confirm) {

                                    $("#mod-fulfillment").modal("hide");

                                    self.$http
                                        .post("/Orders/fulfillment", self.fOrder)
                                        .then(function (res) {

                                            if (res.body.responseCode == "00") {
                                                self.isBusyModal = false;
                                                self.getOrders();
                                            }
                                            else if (res.body.responseCode == "01") {
                                                self.isBusyModal = false;
                                                self.getOrders();
                                                self.inventoryMovementSummary = res.body.inventoryMovementSummary;
                                                $("#mod-order-fulfillment-status").modal("show");
                                            }
                                            else if (res.body.responseCode == "999X") {

                                                self.$confirm(
                                                    {
                                                        auth: false,
                                                        message: res.body.responseDescription,
                                                        button: {
                                                            no: 'Cancel',
                                                            yes: 'Proceed'
                                                        },
                                                        callback: confirm => {

                                                            if (confirm) {

                                                                self.fOrder.updateStatusOnly = true;
                                                                self.$http
                                                                    .post("/Orders/fulfillment", self.fOrder)
                                                                    .then(function (res) {
                                                                        self.isBusyModal = false;
                                                                        self.getOrders();
                                                                    })
                                                                    .catch(function () {
                                                                        self.isBusy = false;
                                                                        self.isBusyModal = false;
                                                                    });
                                                            }
                                                        }
                                                    });
                                            }
                                            else {
                                                self.getOrders();
                                            }

                                        })
                                        .catch(function () {
                                            self.isBusy = false;
                                            self.isBusyModal = false;
                                        });
                                }
                            },
                        }
                    )
                } else {
                    this.isBusy = false;
                    this.isBusyModal = false;
                }
            },

            async cancelOrder() {
                this.isBusyModal = true;
                this.isBusy = true;

                if (this.validateCancel()) {
                    $("#mod-cancel-order").modal("hide");

                    await this.$http
                        .post("/Orders/fulfillment", this.fOrder)
                        .then(function (res) {
                            this.isBusyModal = false;
                            this.getOrders();
                        })
                        .catch(function () {
                            this.isBusy = false;
                            this.isBusyModal = false;
                        });
                } else {
                    this.isBusy = false;
                    this.isBusyModal = false;
                }
            },

            validate() {
                this.v1 = {
                    notes: false,
                    wasFulfilled: false,
                    dateFulfilled: false,
                };
                var isValid = true;

                if (!this.fOrder.dateFulfilled && this.fOrder.wasFulfilled == "true") {
                    this.v1.dateFulfilled = true;
                    isValid = false;
                }

                if (!this.fOrder.wasFulfilled) {
                    this.v1.wasFulfilled = true;
                    isValid = false;
                }

                if (!this.fOrder.notes) {
                    this.v1.notes = true;
                    isValid = false;
                }

                return isValid;
            },

            validateCancel() {
                this.v2 = {
                    notes: false,
                };
                var isValid = true;

                if (!this.fOrder.notes) {
                    this.v2.notes = true;
                    isValid = false;
                }

                return isValid;
            },

            async getSystemSettings() {
                this.isBusy = true;

                await this.$http
                    .get(`/SystemSettings/getByAccount?parentId=${this.user.parentId}`)
                    .then(function (res) {
                        this.systemSettings = res.body;
                        this.isBusy = false;
                    })
                    .catch(function () { });

                this.isBusy = false;
            },

            formatDate(date) {
                return moment(date).format("ll");
            },

            formatDateWithTime(date) {
                return moment(date).format("lll");
            },

            prev() {
                this.page -= 1;
                this.getOrders();
            },

            next() {
                this.page += 1;
                this.getOrders();
            },

            formatMoney(val) {
                return helper.formatMoney(val);
            },
            async displayNameForUser(id) {
                var result = "";
                console.log('inside: ' + id);
                await this.$http
                    .get("/Users/getById/" + id)
                    .then((response) => response.json())
                    .then((data) => {
                        console.log('user: ' + data["displayName"]);
                        result = data["displayName"];
                        console.log("result: " + result);
                        return result;
                    }).catch(error => console.log(error))
                return result;
            },
            goToPayment(id) {
                this.$router.push("/payments/" + id);
            },
            toggleGlobal() {
                $("#mod-global").modal("hide");
                this.isGlobal = !this.isGlobal;

                this.$auth.setPreferredViewForOrder(this.isGlobal ? "global" : "default", this.user.id);

                this.getOrders();
            },

        },
    };
</script>