/* eslint-disable */
import Vue from "vue";
import VueResource from "vue-resource";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import auth from "./auth";
import Vuelidate from "vuelidate";
import VuePapaParse from "vue-papa-parse";
import Print from "vue-print-nb";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueConfirmDialog from "vue-confirm-dialog";
import "dc/dist/style/dc.css";


Vue.config.productionTip = false;
Vue.use(VueResource);
Vue.use(auth);
Vue.use(Vuelidate);
Vue.use(VuePapaParse);
Vue.use(Print);
Vue.use(Toast, {
	transition: "Vue-Toastification__bounce",
	maxToasts: 20,
	newestOnTop: true,
});
Vue.use(VueConfirmDialog);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

const url = process.env.VUE_APP_BASE_API_URL;

Vue.http.interceptors.push((request, next) => {
	if (request.url[0] === "/") {
		request.url = url + request.url;

		let token = Vue.auth.getToken();

		if (token) {
			request.headers.set("Authorization", "Bearer " + token);
		}
	}

	next((res) => {
		if (res.status == 401 || res.status == 403) {
			if (res.status === 401 && res.headers.has("Token-Expired")) {
				// get new token
			}

			router.push("/");
		} else if (res.status == 404) {
			router.push("/not-found");
		} else if (res.status > 299) {
			if (res.body.errors) {
				res.body.errors.forEach(function(e) {
					Vue.$toast.error(e, {
						icon: false,
						rtl: false,
					});
				});
			} else {
				Vue.$toast.error(res.body, {
					icon: false,
					rtl: false,
				});
			}
		} else if (!res.ok && res.status === 0) {
			Vue.$toast.error("Network Error", {
				icon: false,
				rtl: false,
			});
		}
	});
});

router.beforeEach((to, from, next) => {
	store.commit("setPrevRoute", from.path);
	let role = Vue.auth.getRole();
	let type = Vue.auth.getUserType();
	let requiresAuth = to.meta.requiresAuth;
	let requiresGuest = to.meta.requiresGuest;
	let loggedIn = Vue.auth.loggedIn();

	if (requiresGuest && loggedIn) {
		next({
			path: "/dashboard",
		});
	} else if (requiresAuth && !loggedIn) {
		next({
			path: "/login",
			query: { redirect: to.fullPath },
		});
	} else if (requiresAuth && loggedIn) {
		if (to.meta.userCategories.includes(type)) {
			let matchedObject = from.matched
				.slice()
				.reverse()
				.find((r) => r.meta && r.meta.hasOwnProperty("windowRedirectAfter"));

			if (matchedObject) {
				if (matchedObject.meta.windowRedirectAfter === true) {
					var url = to.fullPath;
					window.location.href = url;
					return;
				}
			}

			next();
		} else {
			next({ path: "/restricted" });
		}
	} else {
		next();
	}
});

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
