<template>
  <div>
    <div
      id="printInvoice"
      style="display: none"
      class="card-body bg-white text-dark"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="invoice-title">
              <div class="row">
                <div class="col-sm-6 col-md-3 text-center alert alert-primary">
                  <h4 class="">INVOICE</h4>
                </div>
                <div class="col-sm-12 col-md-9 text-right">
                  <address>
                    <strong>Date: {{ formatDate(order.created) }}</strong
                    ><br />
                    <strong>RN: {{ order.orderNumber }}</strong>
                  </address>
                </div>
              </div>
              <hr />
            </div>
            <div class="row">
              <div class="col-sm-9 col-md-6">
                <address>
                  <strong>From: {{ order.seller.name }}</strong
                  ><br />
                  {{ order.seller.phoneNumber }}<br />
                  {{ order.seller.emailAddress }}<br />
                  {{ order.seller.address }}
                </address>
              </div>
              <div class="col-sm-9 col-md-6 text-right">
                <address>
                  <strong>
                    To:
                    {{ order.maker ? order.maker.displayName : "" }} </strong
                  ><br />
                  {{
                    order.maker
                      ? order.maker.phoneNumber
                        ? order.maker.phoneNumber
                        : order.maker.emailAddress
                      : ""
                  }}<br />
                  {{ order.maker ? order.maker.deliveryAddress : "" }}
                </address>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col col-md-12">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h3 class="panel-title">
                  <strong>Order summary</strong>
                </h3>
              </div>
              <div class="panel-body">
                <div class="table-responsive">
                  <table class="table table-condensed">
                    <thead>
                      <tr>
                        <td style="width: 45%">
                          <strong>Item Description</strong>
                        </td>
                        <td
                          style="width: 20%; text-align: center"
                          class="text-center"
                        >
                          <strong>Price (NGN)</strong>
                        </td>
                        <td
                          style="width: 15%; text-align: center"
                          class="text-center"
                        >
                          <strong>Quantity</strong>
                        </td>
                        <td
                          style="width: 20%; text-align: right"
                          class="text-right"
                        >
                          <strong>Amount (NGN)</strong>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in order.details" :key="index">
                        <td>{{ item.code }} - {{ item.description }}</td>
                        <td class="text-center" style="text-align: center">
                          &#8358; {{ formatMoney(item.unitPrice) }}
                        </td>
                        <td class="text-center" style="text-align: center">
                          {{ item.quantity }}
                        </td>
                        <td class="text-right" style="text-align: right">
                          &#8358;
                          {{ formatMoney(item.quantity * item.unitPrice) }}
                        </td>
                      </tr>

                      <tr>
                        <td colspan="4">&nbsp;</td>
                      </tr>

                      <tr>
                        <td class="thick-line"></td>
                        <td class="thick-line"></td>
                        <td
                          class="thick-line text-center"
                          style="text-align: center"
                        >
                          <strong>Subtotal</strong>
                        </td>
                        <td
                          class="thick-line text-right"
                          style="text-align: right"
                        >
                          &#8358; {{ formatMoney(order.subtotal) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="no-line"></td>
                        <td class="no-line"></td>
                        <td
                          class="no-line text-center"
                          style="text-align: center"
                        >
                          <strong>Discount</strong>
                        </td>
                        <td
                          class="no-line text-right"
                          style="text-align: right"
                        >
                          &#8358; {{ formatMoney(order.discount) }}
                        </td>
                      </tr>

                      <tr>
                        <td class="no-line" colspan="2">
                          Please make all transfers to
                          {{ order.seller.name }} -
                          {{
                            order.seller.bank
                              ? order.seller.bank.accountNumber
                              : ""
                          }}
                          {{ order.seller.bank ? order.seller.bank.name : "" }}.
                          All cheques should be made out to
                          {{ order.seller.name }}
                        </td>
                        <td
                          class="no-line text-center"
                          style="text-align: center"
                        >
                          <strong>Payment Due</strong>
                        </td>
                        <td
                          class="no-line text-right"
                          style="text-align: right"
                        >
                          &#8358;
                          {{
                            formatMoney(
                              order.payment.paidAmount
                                ? order.payment.paidAmount >
                                  order.payment.orderAmount
                                  ? 0
                                  : order.payment.orderAmount -
                                    order.payment.paidAmount
                                : order.payment.orderAmount
                            )
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <hr style="border-top: dotted 1px" />
                  Notes or comments
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-6">
                    <img
                      src="https://app.wekurnect.ng/assets/img/logo.png"
                      alt="img"
                    />
                  </div>
                  <div class="col-md-6 text-right">
                    Invoice generated from weKurnect
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" id="hidden">
      <div class="col-lg-12">
        <div
          :class="[
            'card',
            'card-border-color',
            'card-border-color-primary',
            'be-loading',
            { 'be-loading-active': isBusy },
          ]"
        >
          <div class="card-header">
            <div class="btn-group">
              <router-link
                class="btn btn-space btn-danger btn-rounded float-right mb-2"
                :to="backUrl"
              >
                <span
                  class="icon icon-left mdi mdi-arrow-left text-white"
                ></span>
                Back
              </router-link>

              <button
                @click="printInvoice"
                v-if="
                  user.type == 'Distributor' &&
                  user.role != 'Customer' &&
                  order.payment.paymentStatus == 'NOT PAID'
                "
                class="btn btn-space btn-success btn-rounded float-right mb-2"
              >
                <span class="icon icon-left mdi mdi-printer text-white"></span>
                Print Invoice
              </button>
              <button
                v-if="
                  user.type == 'Distributor' &&
                  user.role != 'Customer' &&
                  order.payment.paymentStatus == 'NOT PAID' &&
                  systemSettings.sendInvoiceManually
                "
                class="btn btn-space btn-info btn-rounded float-right mb-2"
                @click="viewSendInvoice"
              >
                <span
                  class="icon icon-left mdi mdi-truck-delivery text-white"
                ></span>
                Send Invoice
              </button>

              <button
                @click="printReceipt"
                class="btn btn-space btn-primary btn-rounded float-right mb-2"
                v-if="
                  user.type == 'Distributor' &&
                  user.role != 'Customer' &&
                  (order.payment.paymentStatus == 'PAID' ||
                    order.payment.paymentStatus == 'PARTLY PAID')
                "
              >
                <span class="icon icon-left mdi mdi-printer text-white"></span>
                Print Receipt
              </button>
              <button
                class="btn btn-space btn-success btn-rounded float-right mb-2"
                @click="posReceipt"
                v-if="
                  user.type == 'Distributor' &&
                  user.role != 'Customer' &&
                  (order.payment.paymentStatus == 'PAID' ||
                    order.payment.paymentStatus == 'PARTLY PAID')
                "
              >
                <span class="icon icon-left mdi mdi-printer text-white"></span>
                Print To POS
              </button>
              <button
                class="btn btn-space btn-success btn-rounded float-right"
                @click.prevent="payWithMonnify()"
                v-if="
                  user.role == 'Customer' &&
                  (order.payment.paymentStatus == 'NOT PAID' ||
                    order.payment.paymentStatus == 'PARTLY PAID')
                "
              >
                <span class="icon icon-left mdi mdi-cash text-white"></span> Pay
              </button>
            </div>
            <h4>
              <b>Order # {{ order.orderNumber }}</b>
            </h4>
          </div>
        </div>
        <div id="printing">
          <div class="card-body">
            <div class="form-group row">
              <div class="col-lg-4">
                <label class="col-form-label font-weight-bold">Customer:</label>
                <span class="col-form-label text-sm-left">
                  {{ order.maker ? order.maker.displayName : "" }} &nbsp;

                  <a
                    v-if="
                      hasPermission('Global_View_Customers') &&
                      order.introducer.displayName == user.displayName
                    "
                    @click.prevent="details(order.maker.id)"
                  >
                    <span class="feather icon-info text-success"></span>
                  </a>
                </span>
              </div>
              <div class="col-lg-4">
                <label class="col-form-label text-sm-left font-weight-bold"
                  >Channel:</label
                >
                <span class="col-form-label text-sm-left">
                  {{ order.source }}
                </span>
              </div>
              <div class="col-lg-4">
                <label class="col-form-label text-sm-left font-weight-bold"
                  >Delivery Address:</label
                >
                <span class="col-form-label text-sm-left">
                  {{ order.deliveryAddress }}
                </span>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-lg-4">
                <label class="col-form-label text-sm-left font-weight-bold"
                  >Created:</label
                >
                <span class="col-form-label text-sm-left">
                  {{ formatDate(order.created) }}
                </span>
              </div>
              <div class="col-lg-4">
                <label class="col-form-label text-sm-left font-weight-bold"
                  >Order Date:</label
                >
                <span class="col-lg-3 col-form-label text-sm-left">
                  {{
                    order.orderDate
                      ? formatDate(order.orderDate)
                      : formatDate(order.created)
                  }}
                </span>
              </div>
              <div class="col-lg-4">
                <label class="col-form-label text-sm-left font-weight-bold"
                  >Created By:</label
                >
                <span class="col-lg-3 col-form-label text-sm-left">
                  {{ orderedBy }}
                </span>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-lg-4">
                <label class="col-form-label text-sm-left font-weight-bold"
                  >Sub Total:</label
                >
                <span class="col-form-label text-sm-left">
                  &#8358; {{ formatMoney(order.subtotal) }}
                </span>
              </div>
              <div class="col-lg-4">
                <label class="col-form-label text-sm-left font-weight-bold"
                  >Custom Discount:</label
                >
                <span class="col-lg-3 col-form-label text-sm-left">
                  &#8358; {{ formatMoney(customDiscount) }} &nbsp;
                  <a
                    v-if="
                      order.status == 'Pending' &&
                      user.role === 'Administrator' &&
                      order.payment.paymentStatus != 'PAID'
                    "
                    @click.prevent="viewEditOrderDiscount()"
                  >
                    <span class="feather icon-edit text-success"></span>
                  </a>
                </span>
              </div>
              <div class="col-lg-4">
                <label class="col-form-label text-sm-righ font-weight-bold"
                  >Total Discount:</label
                >
                <span
                  class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left"
                >
                  &#8358;
                  {{
                    formatMoney(
                      order.discount
                        ? order.discount.toFixed(2)
                        : order.discount
                    )
                  }}
                </span>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-lg-4">
                <label class="col-form-label text-sm-left font-weight-bold"
                  >Total:</label
                >
                <span class="col-lg-3 col-form-label text-sm-left">
                  &#8358; {{ formatMoney(order.total) }}
                </span>
              </div>
              <div class="col-lg-4">
                <label class="col-form-label text-sm-left font-weight-bold"
                  >Status:</label
                >
                <span class="col-lg-3 col-form-label text-sm-left">
                  <span
                    :class="[
                      'badge',
                      'badge-pill',
                      { 'badge-primary': order.status == 'Pending' },
                      { 'badge-success': order.status == 'Fulfilled' },
                      { 'badge-danger': order.status == 'Canceled' },
                    ]"
                    >{{ order.status }}</span
                  >
                </span>
              </div>
              <div class="col-lg-4">
                <label class="col-form-label text-sm-left font-weight-bold"
                  >Fulfillment Date:</label
                >
                <span class="col-lg-3 col-form-label text-sm-left">
                  {{ order.fulfilled ? formatDate(order.fulfilled) : "N/A" }}
                </span>
              </div>
            </div>

            <div class="form-group row">
              <label
                class="
                  col-12 col-sm-2 col-form-label
                  text-sm-righ
                  font-weight-bold
                "
                >Notes:</label
              >
              <div class="col-12 col-sm-9 col-lg-9 col-form-label text-sm-left">
                {{ order.notes }}
              </div>
            </div>
          </div>
          <div class="be-spinner">
            <svg width="40px" height="40px" viewBox="0 0 66 66">
              <circle
                class="circle"
                fill="none"
                stroke-width="4"
                stroke-linecap="round"
                cx="33"
                cy="33"
                r="30"
              ></circle>
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div class="row" id="hidden">
      <div class="col-lg-12">
        <div
          :class="[
            'card',
            'card-border-color',
            'card-border-color-primary',
            'be-loading',
            { 'be-loading-active': isBusy },
          ]"
        >
          <div class="card-header p-1" id="printing">
            <h4><b>Order Details</b></h4>
            <span>
              <button
                v-if="
                  order.status == 'Pending' &&
                  hasPermission('Orders_Update') &&
                  order.payment.paymentStatus != 'PAID'
                "
                class="btn btn-success btn-sm btn-rounded btn-space"
                @click.prevent="viewAddItem()"
              >
                <i class="feather icon-edit"></i> Add Product
              </button>
            </span>
          </div>
          <div class="col-sm-12 table-responsive" id="printing">
            <table class="table table-fw-widget dataTable no-footer">
              <thead>
                <tr>
                  <th style="width: 30%">Description</th>
                  <th style="width: 10%">Quantity</th>
                  <th style="width: 15%">Unit Price</th>
                  <th style="width: 10%">Sub Total</th>
                  <th style="width: 10%">Discount</th>
                  <th style="width: 10%">Total</th>
                  <th style="width: 15%"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in order.details" :key="index">
                  <td>{{ item.code }} - {{ item.description }}</td>
                  <td>{{ item.quantity }}</td>
                  <td>&#8358; {{ formatMoney(item.unitPrice) }}</td>
                  <td>
                    &#8358; {{ formatMoney(item.quantity * item.unitPrice) }}
                  </td>
                  <td>&#8358; {{ formatMoney(item.discount) }}</td>
                  <td>
                    &#8358;
                    {{
                      formatMoney(
                        item.quantity * item.unitPrice - item.discount
                      )
                    }}
                  </td>
                  <td>
                    <div
                      class="btn-group"
                      v-if="
                        order.status == 'Pending' &&
                        hasPermission('Orders_Update')
                      "
                    >
                      <button
                        v-if="order.payment.paymentStatus != 'PAID'"
                        class="btn btn-success btn-sm btn-rounded btn-space"
                        @click.prevent="viewEditItem(item)"
                      >
                        <i class="feather icon-edit"></i> Edit
                      </button>

                      <button
                        v-if="
                          order.status == 'Pending' &&
                          order.details.length > 1 &&
                          order.payment.paymentStatus != 'PAID'
                        "
                        class="btn btn-danger btn-sm btn-rounded btn-space"
                        @click.prevent="removeItem(item)"
                      >
                        <i class="feather icon-delete"></i> Remove
                      </button>
                    </div>
                  </td>
                </tr>
                <tr v-if="!order.details.length">
                  <td colspan="4">
                    <div class="text-center">No items in order</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="be-spinner">
            <svg width="40px" height="40px" viewBox="0 0 66 66">
              <circle
                class="circle"
                fill="none"
                stroke-width="4"
                stroke-linecap="round"
                cx="33"
                cy="33"
                r="30"
              ></circle>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row"
      v-if="order.payment && order.paymentHistory.length"
      id="hidden"
    >
      <div class="col-lg-12">
        <div
          :class="[
            'card',
            'card-border-color',
            'card-border-color-primary',
            'be-loading',
            { 'be-loading-active': isBusy },
          ]"
        >
          <div class="card-header p-1" id="printing">
            <h4><b>Most Recent Transaction</b></h4>
          </div>
          <div class="card-body" id="printing">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Transaction Type</label
              >
              <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
                {{
                  order.paymentHistory[0].paidAmount &lt; 0 ? "Refund" : "Payment"
                }}
              </div>
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Payment Date</label
              >
              <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
                {{
                  order.payment.paidOn
                    ? formatDate(order.payment.paidOn)
                    : "N/A"
                }}
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Payment Reference</label
              >
              <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
                {{ order.payment.desc ? order.payment.desc : "N/A" }}
              </div>
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Amount Paid</label
              >

              <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
                &#8358;
                {{
                  order.paymentHistory[0].paidAmount &lt; 0
                    ? formatMoney(order.paymentHistory[0].paidAmount * -1)
                    : formatMoney(order.paymentHistory[0].paidAmount)
                }}
              </div>
            </div>

            <div class="card-header"><b>Payment History</b></div>
            <div class="form-group row">
              <div class="col-sm-12"></div>
              <div class="col-sm-9 offset-md-2">
                <div class="col-sm-12 table-responsive">
                  <table class="table table-fw-widget dataTable no-footer">
                    <thead>
                      <tr>
                        <th style="width: 20%">Payment Amount</th>
                        <th style="width: 20%">Payment Date</th>
                        <th style="width: 15%">Transaction Type</th>
                        <th style="width: 25%">Payment Reference</th>
                        <th style="width: 20%"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="o in order.paymentHistory" :key="o.id">
                        <td>
                          {{
                            o.paidAmount &lt; 0
                              ? formatMoney(o.paidAmount * -1)
                              : formatMoney(o.paidAmount)
                          }}
                        </td>
                        <td>{{ formatDate(o.created) }}</td>
                        <td>
                          {{ o.paidAmount &lt; 0 ? "Refund" : "Payment" }}
                          {{ prepareDetail(o) }}
                        </td>
                        <td>{{ o.desc }}</td>
                        <td></td>
                      </tr>
                      <tr v-if="!order.paymentHistory.length">
                        <td colspan="5">
                          <div class="text-center" style="padding-top: 50px">
                            <span
                              style="font-size: 4.615rem"
                              class="mdi mdi-basket"
                            ></span>
                          </div>
                          <h3 class="text-center" style="padding-bottom: 100px">
                            You have no payment history.
                          </h3>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="be-spinner">
            <svg width="40px" height="40px" viewBox="0 0 66 66">
              <circle
                class="circle"
                fill="none"
                stroke-width="4"
                stroke-linecap="round"
                cx="33"
                cy="33"
                r="30"
              ></circle>
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div
      id="printReceipt"
      style="display: none"
      class="card-body bg-white text-dark"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="invoice-title">
              <div class="row">
                <div class="col-md-4 text-center alert alert-success">
                  <h4>RECEIPT</h4>
                  <h1>{{ order.seller ? order.seller.name : "NA" }}</h1>
                </div>
                <div class="col-md-8 text-right">
                  <strong>
                    {{ order.seller ? order.seller.name : "NA" }}
                  </strong>
                  <address>
                    <strong>Date: {{ formatDate(order.created) }}</strong
                    ><br />
                    <strong>RN: {{ order.orderNumber }}</strong>
                  </address>
                </div>
              </div>
              <hr />
            </div>
            <div class="row">
              <div class="col-md-6">
                <address>
                  <strong>Customer Details:</strong><br />
                  {{ order.maker ? order.maker.displayName : "" }}<br />
                  {{
                    order.maker
                      ? order.maker.phoneNumber
                        ? order.maker.phoneNumber
                        : order.maker.emailAddress
                      : ""
                  }}<br />
                  {{ order.maker ? order.maker.deliveryAddress : "" }}
                </address>
              </div>
              <div class="col-md-6 text-right">
                <address>
                  <strong>Order Details:</strong><br />
                  Order Id: {{ order.orderNumber }}<br />
                  Order Date: {{ formatDate(order.created) }}
                </address>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h3 class="panel-title">
                  <strong>Order summary</strong>
                </h3>
              </div>
              <div class="panel-body">
                <div class="table-responsive">
                  <table class="table table-condensed">
                    <thead>
                      <tr>
                        <td style="width: 45%">
                          <strong>Item Description</strong>
                        </td>
                        <td
                          style="width: 20%; text-align: center"
                          class="text-center"
                        >
                          <strong>Price (NGN)</strong>
                        </td>
                        <td
                          style="width: 15%; text-align: center"
                          class="text-center"
                        >
                          <strong>Quantity</strong>
                        </td>
                        <td
                          style="width: 20%; text-align: right"
                          class="text-right"
                        >
                          <strong>Amount (NGN)</strong>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in order.details" :key="index">
                        <td>{{ item.code }} - {{ item.description }}</td>
                        <td
                          class="text-center font-weight-light"
                          style="text-align: center"
                        >
                          &#8358; {{ formatMoney(item.unitPrice) }}
                        </td>
                        <td
                          class="text-center font-weight-light"
                          style="text-align: center"
                        >
                          {{ item.quantity }}
                        </td>
                        <td
                          class="text-right font-weight-light"
                          style="text-align: right"
                        >
                          &#8358;
                          {{ formatMoney(item.quantity * item.unitPrice) }}
                        </td>
                      </tr>

                      <tr>
                        <td colspan="3">&nbsp;</td>
                      </tr>

                      <tr>
                        <td class="thick-line"></td>
                        <td class="thick-line"></td>
                        <td
                          class="thick-line text-center"
                          style="text-align: center"
                        >
                          <strong>Subtotal</strong>
                        </td>
                        <td
                          class="thick-line text-right"
                          style="text-align: right"
                        >
                          &#8358; {{ formatMoney(order.subtotal) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="no-line"></td>
                        <td class="no-line"></td>
                        <td
                          class="no-line text-center"
                          style="text-align: center"
                        >
                          <strong>Discount</strong>
                        </td>
                        <td
                          class="no-line text-right"
                          style="text-align: right"
                        >
                          &#8358; {{ formatMoney(order.discount) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="no-line"></td>
                        <td class="no-line"></td>
                        <td
                          class="no-line text-center"
                          style="text-align: center"
                        >
                          <strong>Grand Total</strong>
                        </td>
                        <td
                          class="no-line text-right"
                          style="text-align: right"
                        >
                          &#8358; {{ formatMoney(order.total) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="no-line"></td>
                        <td class="no-line"></td>
                        <td
                          class="no-line text-center"
                          style="text-align: center"
                        >
                          <strong>Outstanding Balance</strong>
                        </td>
                        <td
                          class="no-line text-right"
                          style="text-align: right"
                        >
                          &#8358;
                          {{ formatMoney(order.total - totalPayment) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <h3><strong>Payment Details</strong></h3>
                </div>
                <div class="table-responsive">
                  <table class="table table-condensed" style="width: 100%">
                    <thead>
                      <tr>
                        <td style="width: 34%">
                          <strong>Amount Paid (NGN)</strong>
                        </td>
                        <td
                          style="width: 33%; text-align: center"
                          class="text-center"
                        >
                          <strong>Type</strong>
                        </td>
                        <td
                          style="width: 33%; text-align: right"
                          class="text-right"
                        >
                          <strong>Date Of Payment</strong>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in order.paymentHistory"
                        :key="index"
                      >
                        <td>
                          &#8358;
                          {{
                            item.paidAmount &lt; 0
                              ? formatMoney(item.paidAmount * -1)
                              : formatMoney(item.paidAmount)
                          }}
                        </td>
                        <td style="text-align: center" class="text-center">
                          {{ item.paidAmount &lt; 0 ? "Refund" : "Payment" }}
                        </td>
                        <td style="text-align: right" class="text-right">
                          {{ formatDate(item.created) }}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3">&nbsp;</td>
                      </tr>
                      <tr>
                        <td class="no-line"></td>
                        <td
                          style="text-align: center"
                          class="no-line text-center"
                        >
                          <strong>Total</strong>
                        </td>
                        <td
                          style="text-align: right"
                          class="no-line text-right"
                        >
                          &#8358; {{ formatMoney(totalPayment) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-6">
                    <img
                      src="https://app.wekurnect.ng/assets/img/logo.png"
                      alt="img"
                    />
                  </div>
                  <div class="col-md-6 text-right">
                    Receipt generated from weKurnect
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-send-invoice"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Send Invoice</b></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div
              class="form-group row"
              v-if="systemSettings.sendManualInvoiceViaSms"
            >
              <label
                class="col-12 col-sm-3 col-form-label text-sm-right"
              ></label>
              <div class="col-12 col-sm-8 col-lg-8">
                <label
                  class="custom-control custom-checkbox custom-control-inline"
                >
                  <input
                    class="custom-control-input"
                    type="checkbox"
                    v-model="sendInvoice.sendViaSms"
                    :disabled="receiverHasPhoneNumber"
                  />
                  <span class="custom-control-label">Send Invoice Via SMS</span>
                </label>
                <small
                  v-if="receiverHasPhoneNumber"
                  class="form-text text-muted"
                  >Reciever doesn't have a phone number</small
                >
              </div>
            </div>
            <div
              class="form-group row"
              v-if="systemSettings.sendManualInvoiceViaEmail"
            >
              <label
                class="col-12 col-sm-3 col-form-label text-sm-right"
              ></label>
              <div class="col-12 col-sm-8 col-lg-8">
                <label
                  class="custom-control custom-checkbox custom-control-inline"
                >
                  <input
                    class="custom-control-input"
                    type="checkbox"
                    v-model="sendInvoice.sendViaEmail"
                    :disabled="receiverHasEmail"
                  />
                  <span class="custom-control-label"
                    >Send Invoice Via Email</span
                  >
                </label>
                <small v-if="receiverHasEmail" class="form-text text-muted"
                  >Reciever doesn't have an email address</small
                >
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="sendInvoiceForOrder"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Send</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-add-item"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Add Item</b></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row" v-if="!v_new_item.is_valid">
              <div class="col-12 col-sm-12 col-lg-12">
                <label
                  class="col-12 col-sm-12 text-sm-center alert alert-danger"
                  >{{ v_new_item.text }}</label
                >
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Product</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  placeholder="Select an option"
                  class="form-control"
                  v-model="newItem.productId"
                >
                  <option
                    v-for="p in filteredProducts"
                    :key="p.id"
                    :value="p.id"
                  >
                    {{ p.name }} ({{ p.code }}) - N{{
                      formatMoney(p.retailPrice)
                    }}/{{ p.manufacturerProduct.unit }}
                  </option>
                </select>
                <ul
                  v-if="v_new_item.productId"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Quantity</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="[
                    'form-control',
                    { 'is-invalid': v_new_item.quantity },
                  ]"
                  type="number"
                  v-model="newItem.quantity"
                />
                <ul
                  v-if="v_new_item.quantity"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Enter a number > 0.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Discount</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="[
                    'form-control',
                    { 'is-invalid': v_new_item.discount },
                  ]"
                  type="number"
                  step="0.01"
                  v-model="newItem.discount"
                />
                <ul
                  v-if="v_new_item.discount"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Enter a number.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 130px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="postAddItem"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Add</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-edit-item"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Edit Item</b></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row" v-if="!v_edit_item.is_valid">
              <div class="col-12 col-sm-12 col-lg-12">
                <label
                  class="col-12 col-sm-12 text-sm-center alert alert-danger"
                  >{{ v_edit_item.text }}</label
                >
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Product</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  placeholder="Select an option"
                  class="form-control"
                  v-model="editItem.productId"
                  disabled
                >
                  <option v-for="p in products" :key="p.id" :value="p.id">
                    {{ p.name }} ({{ p.code }}) - N{{
                      formatMoney(p.retailPrice)
                    }}/{{ p.manufacturerProduct.unit }}
                  </option>
                </select>
                <ul
                  v-if="v_edit_item.productId"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Quantity</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="[
                    'form-control',
                    { 'is-invalid': v_edit_item.quantity },
                  ]"
                  type="number"
                  v-model="editItem.quantity"
                />
                <ul
                  v-if="v_edit_item.quantity"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Enter a number > 0.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Discount</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="[
                    'form-control',
                    { 'is-invalid': v_edit_item.discount },
                  ]"
                  type="number"
                  v-model="editItem.discount"
                />
                <ul
                  v-if="v_edit_item.discount"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Enter a number.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 130px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="postEditItem"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-edit-order-discount"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Edit Order Discount</b></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <div class="col-12 col-sm-12 col-lg-12">
                <label
                  class="col-12 col-sm-12 text-sm-center alert alert-primary"
                >
                  Please note that discounts applied on individual products can
                  be modified.
                </label>
              </div>
            </div>

            <div class="form-group row" v-if="!v_edit_discount.is_valid">
              <div class="col-12 col-sm-12 col-lg-12">
                <label
                  class="col-12 col-sm-12 text-sm-center alert alert-danger"
                  >{{ v_edit_discount.text }}</label
                >
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Discount</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="[
                    'form-control',
                    { 'is-invalid': v_edit_item.discount },
                  ]"
                  step="0.01"
                  type="number"
                  v-model="editDiscount.discount"
                />
                <ul
                  v-if="v_edit_discount.discount"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Enter a number.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 130px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="postEditOrderDiscount"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      id="printingPos"
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        width: 5.5in;
      "
      v-show="false"
    >
      <div class="card-body bg-white text-dark" style="width: 5.5in">
        <div class="container" id="pos">
          <div class="row">
            <div class="col">
              <h3 style="text-align: center; font-size: 13px">
                {{ order.seller ? order.seller.name : "NA" }}
              </h3>
              <hr />
              <div style="text-align: center">
                <div style="font-size: 13px">
                  <address>
                    <strong>Customer Details:</strong><br />
                    {{ order.maker ? order.maker.displayName : "" }}<br />
                    {{
                      order.maker
                        ? order.maker.phoneNumber
                          ? order.maker.phoneNumber
                          : order.maker.emailAddress
                        : ""
                    }}<br />
                    {{ order.maker ? order.maker.deliveryAddress : "" }}
                  </address>
                </div>
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  font-size: 12px;
                "
              >
                <div class="col-sm">
                  <address>
                    <small><b>Date: </b>{{ formatDate(order.created) }}</small
                    ><br />
                    <small><b>RN: </b>{{ order.orderNumber }}</small>
                  </address>
                </div>
                <div>
                  <address>
                    <small>
                      <b> Order Id: </b>{{ order.orderNumber }}<br />
                      <b>Order Date:</b>
                      {{ formatDate(order.created) }}
                    </small>
                  </address>
                </div>
              </div>
            </div>
          </div>
          <div style="width: 100%">
            <h4 style="text-align: center">
              <strong style="font-size: 13px">Cash Receipt</strong>
            </h4>
            <table style="width: 100%">
              <thead>
                <tr style="font-size: 12px">
                  <th style="border-bottom: 1px solid black">Item</th>
                  <th style="border-bottom: 1px solid black">U/Cost</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  style="font-size: 12px"
                  v-for="(item, index) in order.details"
                  :key="index"
                >
                  <td style="text-align: start">
                    {{ item.description }}
                  </td>
                  <td style="text-align: start">
                    &#8358;
                    {{ formatMoney(item.unitPrice * item.quantity) }}
                  </td>
                </tr>
                <tr style="font-size: 12px">
                  <td style="text-align: start">Discount</td>
                  <td style="text-align: start">
                    &#8358; {{ formatMoney(customDiscount) }}
                  </td>
                </tr>
                <tr style="font-size: 12px">
                  <td style="text-align: start">Total</td>
                  <td style="text-align: start">
                    &#8358; {{ formatMoney(order.total) }}
                  </td>
                </tr>
                <tr style="font-size: 12px">
                  <td style="text-align: start">Total Amount paid</td>
                  <td style="text-align: start">
                    &#8358; {{ formatMoney(order.payment.paidAmount) }}
                  </td>
                </tr>
                <tr style="font-size: 12px">
                  <td style="text-align: start">Outstanding</td>
                  <td style="text-align: start">
                    &#8358; {{ formatMoney(order.total - totalPayment) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <hr />
            <div class="row justify-content-end">
              <div class="col-7">
                <small> Receipt generated from weKurnect</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import { helper } from "@/helper";
import SystemPermissions from "@/permissions";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "ordersDetails",

  data() {
    return {
      isBusy: false,
      order: {
        parent: {},
        seller: {},
        payment: {},
        paymentHistory: [],
        details: [],
      },

      sendInvoice: {
        sendViaEmail: false,
        sendViaSms: false,
      },

      isBusyModal: false,
      monnifyScriptUrl: process.env.VUE_APP_MONNIFY_SCRIPT_URL,
      systemSettings: {},
      newItem: {
        productId: null,
        quantity: null,
        discount: null,
      },

      editItem: {
        detailId: null,
        productId: null,
        quantity: null,
        discount: 0,
      },

      editDiscount: {
        discount: null,
      },

      v_new_item: {
        productId: false,
        quantity: false,
        discount: 0,
        is_valid: true,
        text: "",
      },

      v_edit_item: {
        productId: false,
        quantity: false,
        discount: 0,
        is_valid: true,
        text: "",
      },
      v_edit_discount: {
        discount: 0,
        is_valid: true,
        text: "",
      },

      products: [],
      filteredProducts: [],
    };
  },

  mounted() {
    let monnifyScript = document.createElement("script");
    monnifyScript.setAttribute("src", this.monnifyScriptUrl);
    document.head.appendChild(monnifyScript);
  },

  created() {
    this.getOrder();

    if (this.user.type === "Distributor") this.getSystemSettings();

    this.getProducts();
  },

  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },

    backPath: function () {
      return this.$store.state.prevRoute;
    },

    totalPayment: function () {
      var myTotal = 0;

      if (this.order.paymentHistory) {
        for (var i = 0, len = this.order.paymentHistory.length; i < len; i++) {
          myTotal += this.order.paymentHistory[i].paidAmount;
        }
      }

      return myTotal;
    },

    customDiscount: function () {
      let customDiscount = 0;

      const totalDiscountOnProducts = this.order.details.reduce(
        (acc, item) => acc + item.discount,
        0
      );
      customDiscount = this.order.discount - totalDiscountOnProducts;

      return customDiscount.toFixed(2);
    },

    orderedBy: function () {
      var n = "";
      if (this.user.role == "Customer") {
        if (this.order.maker) n = this.order.maker.displayName;
        else n = "NA";
      } else {
        if (this.order.introducer) n = this.order.introducer.displayName;
        else if (this.order.maker) n = this.order.maker.displayName;
        else n = "NA";
      }

      return n;
    },

    backUrl: function () {
      var url = this.user.role == "Customer" ? "/dashboard/orders" : "/orders";
      var source = this.$route.params.s;
      if (source === "mp") url = "/managepayments";
      else if (source === "mc") url = "/managecashchequetransactions";
      else {
        var startDate = this.$route.query.sd ? this.$route.query.sd : "";
        var endDate = this.$route.query.ed ? this.$route.query.ed : "";
        var status = this.$route.query.st ? this.$route.query.st : "";
        var buyerName = this.$route.query.bn ? this.$route.query.bn : "";
        var orderNumber = this.$route.query.on ? this.$route.query.on : "";
        var myOrderOnly = this.$route.query.mo ? this.$route.query.mo : "";

        url =
          url +
          "?sd=" +
          startDate +
          "&ed=" +
          endDate +
          "&st=" +
          status +
          "&bn=" +
          buyerName +
          "&on=" +
          orderNumber +
          "&mo=" +
          myOrderOnly;
      }

      return url;
    },

    receiverHasEmail: function () {
      return this.order.maker
        ? this.order.maker.emailAddress
          ? false
          : true
        : true;
    },

    receiverHasPhoneNumber: function () {
      return this.order.maker
        ? this.order.maker.phoneNumber
          ? false
          : true
        : true;
    },
  },

  methods: {
    printReceipt() {
      const prtHtml = document.getElementById("printReceipt").innerHTML;
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      const WinPrint = window.open("", "_blank", "");

      WinPrint.document.write(`<!DOCTYPE html>
                <html>
                  <head>
                    ${stylesHtml}
                    <style>
                        @font-face{
                        font-family:feather;
                        src:url(../assets/fonts/feather/fonts/feather.eot?t=1525787366991);
                        src:url(../assets/fonts/feather/fonts/feather.eot?t=1525787366991#iefix) format('embedded-opentype'),
                        url(../assets/fonts/feather/fonts/feather.woff?t=1525787366991) format('woff'),
                        url(../assets/fonts/feather/fonts/feather.ttf?t=1525787366991) format('truetype'),
                        url(../assets/fonts/feather/fonts/feather.svg?t=1525787366991#feather) format('svg')
                        }
                        html *
                        {
                           font-family: Sans-Serif, Arial !important;
                        }
                    </style>
                  </head>
                  <body>
                    ${prtHtml}
                  </body>
                </html>`);

      WinPrint.print();
    },

    posReceipt() {
      const btnPrint = document.getElementById("printingPos").innerHTML;
      const WinPrint = window.open("", "_blank", "");

      WinPrint.document.write(`<!DOCTYPE html>
                <html>
                  <head>
                   <link type="text/css" rel="stylesheet" href="../assets/css/bootstrap.css" media='screen,print' />
                  </head>
                  <body>
                    ${btnPrint}
                  </body>
                </html>`);
      WinPrint.print();
    },

    printInvoice() {
      const prtHtml = document.getElementById("printInvoice").innerHTML;
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      const WinPrint = window.open("", "_blank", "");

      WinPrint.document.write(`<!DOCTYPE html>
                <html>
                  <head>
                    ${stylesHtml}
                    <style>
                        @font-face{
                        font-family:feather;
                        src:url(../assets/fonts/feather/fonts/feather.eot?t=1525787366991);
                        src:url(../assets/fonts/feather/fonts/feather.eot?t=1525787366991#iefix) format('embedded-opentype'),
                        url(../assets/fonts/feather/fonts/feather.woff?t=1525787366991) format('woff'),
                        url(../assets/fonts/feather/fonts/feather.ttf?t=1525787366991) format('truetype'),
                        url(../assets/fonts/feather/fonts/feather.svg?t=1525787366991#feather) format('svg')
                        }
                        html *
                        {
                           font-family: Sans-Serif, Arial !important;
                        }
                    </style>
                  </head>
                  <body>
                    ${prtHtml}
                  </body>
                </html>`);

      WinPrint.print();
    },

    viewAddItem() {
      this.v_new_item = {
        productId: false,
        quantity: false,
        discount: false,
        is_valid: true,
        text: "",
      };
      this.newItem = {
        productId: null,
        quantity: null,
        discount: 0,
      };

      var fProducts = [];

      var details = this.order.details;

      this.products.forEach(function (e) {
        if (!details.some((s) => s.code == e.code)) {
          fProducts.push(e);
        }
      });

      this.filteredProducts = fProducts;

      $("#mod-add-item").modal("show");
    },

    prepareDetail(value) {
      const name = value.paymentChannel
        ? value.paymentChannel.paymentChannelTypeName
        : null;
      let result = "";

      if (name) {
        result += name;

        if (name.toLowerCase() === "bank")
          result +=
            " / " +
            value.paymentChannel.bankName +
            " / " +
            value.paymentChannel.accountNumber;
        else if (name.toLowerCase() === "pos")
          result +=
            " / " + value.bankName + " / " + value.paymentChannel.terminalId;
        else if (name.toLowerCase() === "cryptocurrency")
          result +=
            " / " +
            value.paymentChannel.bankName +
            " / " +
            value.paymentChannel.walletAddress;
      }
      return `(${result})`;
    },

    viewEditItem(i) {
      this.v_edit_item = {
        productId: false,
        quantity: false,
        discount: false,
        is_valid: true,
        text: "",
      };

      var product = this.products.find(function (e) {
        return e.code == i.code;
      });

      this.editItem = {
        detailId: i.id,
        productId: product.id,
        quantity: i.quantity,
        discount: i.discount,
      };

      $("#mod-edit-item").modal("show");
    },

    viewEditOrderDiscount() {
      this.v_edit_discount = {
        discount: false,
        is_valid: true,
        text: "",
      };

      var totalDiscountOnProducts = this.order.details.reduce(
        (acc, item) => acc + item.discount,
        0
      );

      this.editDiscount = {
        discount: this.order.discount - totalDiscountOnProducts,
      };

      $("#mod-edit-order-discount").modal("show");
    },

    viewSendInvoice() {
      $("#mod-send-invoice").modal("show");
    },

    async postAddItem() {
      this.isBusyModal = true;
      this.isBusy = true;

      if (this.validateAddItem()) {
        $("#mod-add-item").modal("hide");
        var pId = this.newItem.productId;
        var product = this.products.find(function (e) {
          return e.id == pId;
        });

        var data = {
          orderId: this.order.id,
          quantity: this.newItem.quantity,
          unitPrice: product.retailPrice,
          code: product.code,
          description: `${product.name} (${product.manufacturerProduct.unit})`,
          discount: this.newItem.discount,
        };

        await this.$http
          .post("/Orders/addItem", data)
          .then(function (res) {
            this.isBusyModal = false;
            window.location.reload();
          })
          .catch(function () {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },

    async postEditItem() {
      this.isBusyModal = true;
      this.isBusy = true;

      if (this.validateEditItem()) {
        $("#mod-edit-item").modal("hide");

        var data = {
          orderDetailId: this.editItem.detailId,
          quantity: this.editItem.quantity,
          discount: this.editItem.discount ? this.editItem.discount : 0,
        };

        await this.$http
          .post("/Orders/editItem", data)
          .then(function (res) {
            this.isBusyModal = false;
            window.location.reload();
          })
          .catch(function () {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },

    async postEditOrderDiscount() {
      this.isBusyModal = true;
      this.isBusy = true;

      if (this.validateEditOrderDiscount()) {
        $("#mod-edit-order-discount").modal("hide");

        var data = {
          orderId: this.order.id,
          discount: this.editDiscount.discount,
        };

        await this.$http
          .post("/Orders/editOrderDiscount", data)
          .then(function (res) {
            this.isBusyModal = false;
            window.location.reload();
          })
          .catch(function () {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },

    async removeItem(l) {
      this.isBusyModal = true;
      this.isBusy = true;

      $("#mod-remove-item").modal("hide");

      await this.$http
        .post("/Orders/removeItem/" + l.id, null)
        .then(function (res) {
          this.isBusyModal = false;
          window.location.reload();
        })
        .catch(function () {
          this.isBusy = false;
          this.isBusyModal = false;
        });

      this.isBusy = false;
      this.isBusyModal = false;
    },

    validateAddItem() {
      this.v_new_item = {
        productId: false,
        quantity: false,
        discount: false,
        is_valid: true,
        text: "",
      };
      var isValid = true;

      if (!this.newItem.productId) {
        this.v_new_item.productId = true;
        isValid = false;
      }

      if (!this.newItem.quantity) {
        this.v_new_item.quantity = true;
        isValid = false;
      }

      if (this.newItem.quantity <= 0) {
        this.v_new_item.is_valid = false;
        this.v_new_item.text = "Quantity must be greater than 0";
        isValid = false;
      }

      return isValid;
    },

    validateEditItem() {
      this.v_edit_item = {
        productId: false,
        quantity: false,
        discount: false,
        is_valid: true,
        text: "",
      };
      var isValid = true;

      if (!this.editItem.productId) {
        this.v_edit_item.productId = true;
        isValid = false;
      }

      if (!this.editItem.quantity) {
        this.v_edit_item.quantity = true;
        isValid = false;
      }

      if (this.editItem.quantity <= 0) {
        this.v_edit_item.is_valid = false;
        this.v_edit_item.text = "Quantity must be greater than 0";
        isValid = false;
      }

      return isValid;
    },

    validateEditOrderDiscount() {
      this.v_edit_discount = {
        discount: false,
        is_valid: true,
        text: "",
      };
      var isValid = true;

      if (!this.editDiscount.discount) {
        this.v_edit_discount.discount = true;
        isValid = false;
      }

      if (this.editDiscount.discount < 0) {
        this.v_edit_discount.is_valid = false;
        this.v_edit_discount.text = "Discount must be greater or equal to 0";
        isValid = false;
      }

      var totalDiscountOnProducts = this.order.details.reduce(
        (acc, item) => acc + item.discount,
        0
      );
      var totalDiscount =
        parseFloat(totalDiscountOnProducts) +
        parseFloat(this.editDiscount.discount);
      if (totalDiscount > parseFloat(this.order.subtotal)) {
        this.v_edit_discount.is_valid = false;
        this.v_edit_discount.text =
          "Discount cannot be more than total order amount (" +
          this.formatMoney(this.order.subtotal) +
          ")";
        isValid = false;
      }

      return isValid;
    },

    async getProducts() {
      this.products = [];

      await this.$http
        .get(
          "/Products/getListNoPagingForDistributor?parentId=" +
            this.user.parentId +
            "&active=true"
        )
        .then(function (res) {
          this.products = res.body;
        })
        .catch(function () {});
    },

    async getSystemSettings() {
      this.isBusy = true;

      await this.$http
        .get(`/SystemSettings/getByAccount?parentId=${this.user.parentId}`)
        .then(function (res) {
          this.systemSettings = res.body;
          this.isBusy = false;
        })
        .catch(function () {});

      this.isBusy = false;
    },

    getOrder() {
      this.isBusy = true;
      var id = this.$route.params.id;

      this.$http
        .get("/Orders/getById/" + id)
        .then(function (res) {
          this.order = res.body;
          console.log(this.order);
          this.order.parent.name;
          this.isBusy = false;
        })
        .catch(function () {
          this.isBusy = false;
        });
    },

    async sendInvoiceForOrder() {
      if (!this.sendInvoice.sendViaEmail && !this.sendInvoice.sendViaSms) {
        this.$toast.error("Please select at least one means of notification.", {
          icon: false,
          rtl: false,
        });
      } else {
        this.isBusyModal = true;
        this.isBusy = true;

        var id = this.$route.params.id;

        var data = {
          sendViaEmail: this.sendInvoice.sendViaEmail,
          sendViaSms: this.sendInvoice.sendViaSms,
          orderId: id,
        };

        $("#mod-send-invoice").modal("hide");

        await this.$http
          .post("/orders/sendInvoice", data)
          .then(function (res) {
            this.isBusyModal = false;
            if (res.ok) {
              this.$toast.success("Invoice has been sent successfully.", {
                icon: false,
                rtl: false,
              });
            }
          })
          .catch(function () {});

        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    print() {
      $("#mod-filter").modal("show");
    },
    details(id) {
      this.$router.push("/customers/" + id);
    },
    payWithMonnify() {
      var vm = this;

      MonnifySDK.initialize({
        amount: this.order.payment.orderAmount - this.order.payment.paidAmount,
        currency: "NGN",
        reference: "" + Math.floor(Math.random() * 1000000000 + 1),
        customerFullName: this.order.maker.displayName,
        customerEmail: this.order.maker.emailAddress
          ? this.order.maker.emailAddress
          : this.order.maker.id + "@wekurnect.ng",
        customerMobileNumber: this.order.maker.phoneNumber,
        apiKey: this.order.monnifyKey,
        contractCode: this.order.monnifyContractCode,
        paymentDescription: "Payment for order on weKurnect",
        isTestMode: true,
        incomeSplitConfig: [
          {
            subAccountCode: this.order.seller.subAccountCode,
            feePercentage: this.order.seller.feePercentage,
            //"splitAmount": 1900,
            feeBearer: false,
          },
        ],

        onComplete: function (response) {
          //Implement what happens when transaction is completed.

          if (response.paymentStatus == "PAID") {
            var data = {
              orderId: vm.order.id,
              transactionReference: response.transactionReference,
              paymentReference: response.paymentReference,
              amountPaid: response.amountPaid,
              totalPayable: response.totalPayable,
              paidOn: response.paidOn,
              paymentStatus: response.paymentStatus,
              accountReference: response.accountReference,
              paymentDescription: response.paymentDescription,
              transactionHash: response.transactionHash,
            };

            vm.$http
              .post("/Payments/webPaymentCallback/", data)
              .then(function (res) {
                vm.$router.push("/orders");
              })
              .catch(function () {
                //this.isBusy = false;
              });
          }
        },

        onClose: function (data) {
          //Implement what should happen when the modal is closed here
          console.log(data);
        },
      });
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    formatDate(date) {
      return moment(date).format("ll");
    },

    goToPayment(id) {
      this.$router.push("/payments/" + id);
    },
  },
};
</script>
<style scoped>
@media print {
  #hidden {
    display: none !important;
  }

  button {
    display: none !important;
  }
}
</style>
