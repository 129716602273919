<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div
          :class="[
            'card',
            'card-border-color',
            'card-border-color-primary',
            'be-loading',
            { 'be-loading-active': isBusy },
          ]"
        >
          <div class="card-header">Order # {{ order.orderNumber }}</div>
          <div class="card-body">
            <form>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right"
                  >Order From</label
                >
                <div class="col-12 col-sm-8 col-lg-6">
                  <select placeholder="Select a seller" v-model="sellerId">
                    <option
                      v-for="s in order.sellers"
                      :key="s.id"
                      :value="s.id"
                    >
                      {{ s.name }}
                    </option>
                  </select>
                  <ul v-if="v1.sellerId" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right"
                  >Delivery Address</label
                >
                <div class="col-12 col-sm-8 col-lg-6">
                  <textarea
                    class="form-control"
                    placeholder="Delivery address for this order"
                    v-model="deliveryAddress"
                  ></textarea>
                  <ul
                    v-if="v1.deliveryAddress"
                    class="parsley-errors-list filled"
                  >
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>
            </form>
          </div>
          <div class="be-spinner">
            <svg width="40px" height="40px" viewBox="0 0 66 66">
              <circle
                class="circle"
                fill="none"
                stroke-width="4"
                stroke-linecap="round"
                cx="33"
                cy="33"
                r="30"
              ></circle>
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="showDetails">
      <div class="col-lg-12">
        <div
          :class="[
            'card',
            'card-border-color',
            'card-border-color-primary',
            'be-loading',
            { 'be-loading-active': isBusy },
          ]"
        >
          <div class="card-heade p-2">
            <button
              class="btn btn-success btn-rounded btn-space float-right"
              @click="viewAdd"
            >
              <span class="icon icon-left mdi mdi-plus"></span> Add item
            </button>
            <h4><strong>Order Details</strong></h4>
          </div>

          <div class="col-sm-12 table-responsive">
            <table class="table table-fw-widget dataTable no-footer">
              <thead>
                <tr>
                  <th style="width: 45%">Description</th>
                  <th style="width: 15%">Quantity</th>
                  <th style="width: 15%">Unit Price</th>
                  <th style="width: 25%">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in orderDetails" :key="index">
                  <td>
                    {{ item.name }} ({{ item.code }}) - N{{ item.unitPrice }}/{{
                      item.unit
                    }}
                  </td>
                  <td>{{ item.quantity }}</td>
                  <td>&#8358; {{ formatMoney(item.unitPrice) }}</td>
                  <td>
                    <button
                      class="
                        btn btn-secondary btn-rounded btn-space
                        float-right
                      "
                      @click.prevent="remove(index)"
                    >
                      Remove
                    </button>
                    &#8358; {{ formatMoney(item.quantity * item.unitPrice) }}
                  </td>
                </tr>
                <tr v-if="!orderDetails.length">
                  <td colspan="4">
                    <div class="text-center">No items in order</div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2"></td>
                  <td class="text-right">
                    <b>SUBTOTAL</b>
                  </td>
                  <td>&#8358; {{ formatMoney(subtotal) }}</td>
                </tr>
                <tr>
                  <td colspan="2"></td>
                  <td class="text-right">
                    <b>DISCOUNT</b>
                  </td>
                  <td>&#8358; {{ formatMoney(discount) }}</td>
                </tr>

                <tr>
                  <td colspan="2"></td>
                  <td class="text-right">
                    <b>BALANCE</b>
                  </td>
                  <td>&#8358; {{ formatMoney(total) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-body" v-if="orderDetails.length">
            <div class="form-group row" v-if="order.creditLimit > 0">
              <div class="col-12 col-sm-12 col-lg-12">
                <label
                  class="custom-control custom-checkbox custom-control-inline"
                >
                  <input
                    class="custom-control-input"
                    type="checkbox"
                    v-model="useCredit"
                  />
                  <span class="custom-control-label">
                    Use credit facilities. Your current balance is
                    <b>&#8358; {{ formatMoney(order.creditLimit) }}</b>
                  </span>
                </label>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-12 col-sm-12 col-lg-12">
                <button
                  :disabled="isBusy"
                  class="btn btn-primary btn-xl btn-block"
                  @click.prevent="makeOrder"
                >
                  <span v-if="isBusy">Busy...</span>
                  <span v-else>Place Order</span>
                </button>
              </div>
            </div>
          </div>
          <div class="be-spinner">
            <svg width="40px" height="40px" viewBox="0 0 66 66">
              <circle
                class="circle"
                fill="none"
                stroke-width="4"
                stroke-linecap="round"
                cx="33"
                cy="33"
                r="30"
              ></circle>
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-add"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h3 class="modal-title">Add item to list</h3>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Product</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  placeholder="Select an option"
                  class="form-control"
                  v-model="newItem.productIndex"
                >
                  <option
                    v-for="(p, index) in filteredProducts"
                    :key="p.id"
                    :value="index"
                  >
                    {{ p.name }} ({{ p.code }}) - N{{
                      formatMoney(p.retailPrice)
                    }}/{{ p.manufacturerProduct.unit }}
                  </option>
                </select>
                <ul v-if="v2.productId" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Quantity</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v2.quantity }]"
                  type="number"
                  v-model="newItem.quantity"
                />
                <ul v-if="v2.quantity" class="parsley-errors-list filled">
                  <li class="parsley-required">Enter a number > 0.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="add"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Add item</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import { helper } from "@/helper";
import Select2 from "@/components/Select2";

Array.prototype.sum = function (prop1, prop2) {
  var total = 0;
  for (var i = 0, _len = this.length; i < _len; i++) {
    total += this[i][prop1] * this[i][prop2];
  }
  return total;
};

export default {
  name: "ordersCreate",
  components: {
    Select2,
  },

  data() {
    return {
      showDetails: false,
      isBusy: false,
      isBusyModal: false,
      order: {},
      sellerId: null,
      deliveryAddress: null,
      products: [],
      filteredProducts: [],
      orderDetails: [],
      discount: 0.0,
      tax: 0.0,
      subtotal: 0.0,
      total: 0.0,
      useCredit: false,
      newItem: {},
      v1: {
        sellerId: false,
        deliveryAddress: false,
      },
      v2: {
        productId: false,
        quantity: false,
      },
      discountObj: {},
    };
  },

  created() {
    this.initialize();
  },

  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
  },

  watch: {
    sellerId: function (newVal, oldVal) {
      this.onSellerChange(newVal);
    },

    orderDetails: function (newVal, oldVal) {
      this.subtotal = this.orderDetails.sum("quantity", "unitPrice");
      this.discount = 0;

      if (this.discountObj) {
        this.discount =
          this.discountObj.type == "Percent"
            ? this.subtotal * (this.discountObj.value / 100)
            : this.discountObj.value;
      }

      this.tax = 0;
      this.total = this.subtotal - this.tax - this.discount;
    },
  },

  methods: {
    async initialize() {
      this.isBusy = true;

      await this.$http
        .get("/Orders/initialize")
        .then(function (res) {
          this.order = res.body;
          this.deliveryAddress = res.body.buyer.deliveryAddress;
          this.isBusy = false;
        })
        .catch(function () {
          this.$router.push("/dashboard/orders");
        });
    },

    viewAdd() {
      this.newItem = {
        productIndex: null,
        quantity: null,
      };

      this.v2 = {
        productId: false,
        quantity: false,
      };

      var fProducts = [];

      var details = this.orderDetails;

      this.products.forEach(function (e) {
        if (!details.some((s) => s.code == e.code)) {
          fProducts.push(e);
        }
      });

      this.filteredProducts = fProducts;

      $("#mod-add").modal("show");
    },

    add() {
      if (this.validateAdd()) {
        var p = {
          name: this.filteredProducts[this.newItem.productIndex].name,
          unit: this.filteredProducts[this.newItem.productIndex]
            .manufacturerProduct.unit,
          code: this.filteredProducts[this.newItem.productIndex].code,
          description: `${
            this.filteredProducts[this.newItem.productIndex].name
          } (${
            this.filteredProducts[this.newItem.productIndex].manufacturerProduct
              .unit
          })`,
          unitPrice:
            this.filteredProducts[this.newItem.productIndex].retailPrice,
          quantity: this.newItem.quantity,
        };

        this.orderDetails.push(p);

        $("#mod-add").modal("hide");
      }
    },

    validateAdd() {
      this.v2 = {
        productId: false,
        quantity: false,
      };
      var isValid = true;

      if (this.newItem.productIndex < 0) {
        this.v2.productId = true;
        isValid = false;
      }

      if (Number(this.newItem.quantity) <= 0) {
        this.v2.quantity = true;
        isValid = false;
      }

      return isValid;
    },

    onSellerChange(id) {
      this.showDetails = true;
      this.orderDetails = [];
      this.getProducts(id);
      this.getDiscount();
    },

    async getProducts(parentId) {
      this.products = [];

      await this.$http
        .get(
          "/Products/getListNoPagingForDistributor?parentId=" +
            parentId +
            "&active=true"
        )
        .then(function (res) {
          this.products = res.body;
        })
        .catch(function () {});
    },

    remove(index) {
      this.orderDetails.splice(index, 1);
    },

    updateStatus() {
      $("#mod-change").modal("hide");
    },

    async makeOrder() {
      this.isBusy = true;

      if (this.validateOrder()) {
        var data = {
          orderNumber: this.order.orderNumber,
          subtotal: this.subtotal,
          discount: this.discount,
          tax: this.tax,
          total: this.total,
          parentId: "N/A",
          sellerId: this.sellerId,
          makerId: this.user.id,
          details: this.orderDetails,
          useCredit: this.useCredit,
          deliveryAddress: this.deliveryAddress,
        };

        await this.$http
          .post("/Orders/create", data)
          .then(function (res) {
            this.isBusy = false;
            this.$router.push("/dashboard/orders");
          })
          .catch(function () {});

        this.isBusy = false;
      } else {
        this.isBusy = false;
      }
    },

    validateOrder() {
      this.v1 = {
        sellerId: false,
        deliveryAddress: false,
      };
      var isValid = true;

      if (!this.sellerId) {
        this.v1.sellerIs = true;
        isValid = false;
      }

      if (!this.deliveryAddress) {
        this.v1.deliveryAddress = true;
        isValid = false;
      }

      return isValid;
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    async getDiscount() {
      await this.$http
        .get(
          "/Discounts/getForOrder?buyerId=" +
            this.user.id +
            "&sellerId=" +
            this.sellerId
        )
        .then(function (res) {
          this.discountObj = res.body;
        })
        .catch(function () {});
    },
  },
};
</script>