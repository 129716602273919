<template>
  <nav
    class="
      header-navbar
      navbar-expand-lg navbar navbar-with-menu
      floating-nav
      navbar-light navbar-shadow
    "
  >
    <div class="navbar-wrapper">
      <div class="navbar-container content">
        <div class="navbar-collapse" id="navbar-mobile">
          <div
            class="
              mr-auto
              float-left
              bookmark-wrapper
              d-flex
              align-items-center
            "
          >
            <ul class="nav navbar-nav">
              <li class="nav-item mobile-menu d-xl-none mr-auto">
                <a class="nav-link nav-menu-main menu-toggle hidden-xs" href="#"
                  ><i class="fa fa-2x fa-bars"></i
                ></a>
              </li>
            </ul>
            <h4 class="ml-1 no-hidden-xs" style="margin-top: 7px">
              <span
                >{{ user.parent ? user.parent.name : ""
                }}{{
                  user.parent && user.parent.code && user.type == "Distributor"
                    ? "(" + user.parent.code + ")"
                    : ""
                }}
                - {{ title }}</span
              >
            </h4>
          </div>
          <ul class="nav navbar-nav float-right">
            <Notification :parentId="user.parentId" />
            <li class="dropdown dropdown-user nav-item">
              <a
                class="dropdown-toggle nav-link dropdown-user-link"
                href="#"
                data-toggle="dropdown"
              >
                  <div class="user-nav d-sm-flex d-none">
                      <span v-if="user.isSalesUser" class="user-name text-bold-600">
                          {{
                    user.lastName + " " + user.otherNames
                          }}
                      </span>
                      <span v-else class="user-name text-bold-600">
                          {{
                    user.displayName
                          }}
                      </span>
                      <span v-if="user.isSalesUser" class="user-status">{{ user.role.roleName }}</span>
                      <span v-else class="user-status">{{ user.role }}</span>
                  </div>
                <span
                  ><img
                    class="round"
                    src="/assets/img/avatar.png"
                    alt="avatar"
                    height="40"
                    width="40"
                /></span>
              </a>
              <div class="dropdown-menu dropdown-menu-right">
                <router-link class="dropdown-item" to="/account">
                  <i class="feather icon-user"></i> Manage Account
                </router-link>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#" @click.prevent="logout">
                  <i class="feather icon-log-out"></i> Logout
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import Notification from "../components/Notification";
export default {
  name: "navBar",

  components: {
    Notification,
  },

  data() {
    return {
      title: this.$route.meta.title,
    };
  },

  created() {
    this.checkRoute();
  },

  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
  },

  watch: {
    $route: "checkRoute",
  },

  methods: {
    checkRoute() {
      this.title = this.$route.meta.title;
    },

    logout: function () {
      this.$auth.destroyToken();
      this.$store.commit("clearCurrentUser");
      this.$router.push("/login");
    },
  },
};
</script>