<template>
  <div class="custom-control custom-checkbox">
    <input
      type="checkbox"
      class="custom-control-input"
      :id="purchaseOrder.id"
      :value="purchaseOrder.id"
      @change="onPurchaseSelected($event.target.value)"
    />
    <label class="custom-control-label" :for="purchaseOrder.id"></label>
  </div>
</template>

<script>
export default {
  name: "PurchaseOrderCheckBox",
  props: ["purchaseOrder"],

  data() {
    return {
      isSelected: false,
    };
  },

  methods: {
    onPurchaseSelected(value) {
      this.isSelected = !this.isSelected;
      if (this.isSelected) this.$emit("onPurchaseAdd", value);
      else this.$emit("onPurchaseRemove", value);
    },
  },
};
</script>

<style>
</style>